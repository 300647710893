import React, { useEffect, useState } from "react";
import ReactPlaceholder from "react-placeholder";
import { motion } from "framer-motion";
import { TbTargetArrow } from "react-icons/tb";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import {
  MdCarRental,
  MdFlightTakeoff,
  MdMoreTime,
  MdOutlineLocalLibrary,
} from "react-icons/md";
import { RiCustomerService2Line } from "react-icons/ri";

import CommonSection from "../components/UI/CommonSection";
// import driveImg from "../assets/all-images/drive.jpg";
// import blog3 from "../assets/all-images/blog-img/blog-3.jpg";
import luxury_rental1 from "../assets/all-images/luxury_rental1.jpg";
import luxury_rental2 from "../assets/all-images/luxury_rental2.jpg";
import "../styles/about.css";
import HelmetComponent from "../components/Helmet/HelmetComponent";
import FaqLuxury from "../components/UI/FaqLuxuryCarRental";
import { useDispatch, useSelector } from "react-redux";
import {
  allCarsFailure,
  allCarsStart,
  allCarsSuccess,
} from "../components/features/allCarsSlice";
import axiosInstance from "../utils/axiosUtil";
import CarItem from "../components/UI/CarItem";

const LuxuryCarRental = () => {
  const { allCars, loadingAllCars, allCarsErr } = useSelector(
    (state) => state.allCars
  );
  const [width, setWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();

  const fetchAllCars = async () => {
    dispatch(allCarsStart());
    try {
      const { data } = await axiosInstance.get(`/api/car/get-car?sort=low`);

      dispatch(allCarsSuccess(data.cars));
    } catch (error) {
      dispatch(allCarsFailure(error?.response?.data?.message));
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    fetchAllCars();
  }, []);

  return (
    <>
      <HelmetComponent
        title={
          "Luxury Car Hire Christchurch 🌟 | Best Rental Deals on Price"
        }
        metaDesc={
          "Looking to Hire Luxury Car in Christchurch. Contact us & Make your trip extraordinary with our luxury cars  | Christchurch Car Rental"
        }
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <CommonSection title="Luxury Car Rental Christchurch" />

        <section className="" style={{ backgroundColor: "transparent" }}>
          <Container>
            <Row className="justify-content-between">
              <Col lg="6" md="6">
                <div>
                  {/* <h4 className="section__subtitle">About Us</h4> */}
                  <h2 className="section__title">
                    Elevate Your Journey with Luxury Car Hire in Christchurch
                    <br />
                  </h2>
                  <p className="section__description">
                    Christchurch, a vibrant city in the heart of New
                    Zealand, offers a variety of attractions and experiences for
                    residents and visitors alike. Whether you're a local looking
                    to explore the city in style or a traveller seeking a
                    memorable ride, a{" "}
                    <strong>luxury car hire in Christchurch</strong> is an
                    excellent choice. One name that stands out in this category
                    is Christchurch Car Rental.
                  </p>
                </div>
              </Col>

              <Col className="" lg="4" md="6">
                <div className=" aiport_rental_image ">
                  <img src={luxury_rental1} alt="" className="" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section style={{ backgroundColor: "#fff" }}>
          <Container>
            <Row className="justify-content-between align-items-center">
              <h2
                className="section__title"
                style={{ color: "#000", fontWeight: 700 }}
              >
                Why Choose Christchurch Car Rental?
              </h2>

              <div className="" style={{ color: "#868686" }}>
                <p>
                  Christchurch Car Rental is a premier luxury car rental
                  service in Christchurch. It is known for its exceptional
                  service, an impressive fleet of vehicles, and a commitment to
                  providing customers with a premium experience. Here are some
                  compelling reasons why you should choose us for your next
                  adventure
                </p>
              </div>

              {width <= 576 && (
                <Col lg="4" md="6">
                  <div className="about__img aiport_rental_image">
                    <img src={luxury_rental1} alt="" className="w-100" />
                  </div>
                </Col>
              )}

              <h6
                className="section__subtitle"
                style={{
                  color: "#868686",
                  fontWeight: 800,
                  marginTop: "2rem",
                  // textAlign: "center",
                }}
              >
                <TbTargetArrow /> Diverse Fleet of Luxury Cars
              </h6>
              <p style={{ color: "#868686" }}>
                One of the most attractive aspects of Christchurch Car
                Rental is its diverse fleet of luxury vehicles. Whether you're
                looking for a sleek sports car, a spacious SUV, or a classic
                executive sedan, we have a vehicle to match your style and
                preferences. From brands like Audi, Mercedes-Benz, BMW, and
                more, our lineup is sure to satisfy even the most discerning car
                enthusiasts.
              </p>

              {width > 576 && (
                <Col lg="4" md="5">
                  <div className="about__img aiport_rental_image">
                    <img src={luxury_rental1} alt="" className="w-100" />
                  </div>
                </Col>
              )}

              <Col lg="6" md="5" className="">
                {/* <h6 className="section__subtitle">  </h6> */}

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 800,
                    marginTop: "2rem",
                    // textAlign: "center",
                  }}
                >
                  <TbTargetArrow /> Impeccable Service
                </h6>
                <p style={{ color: "#868686" }}>
                  When you choose{" "}
                  <strong>luxury car hire Christchurch</strong> Car Rental,
                  you're not just getting a vehicle; you're getting an
                  experience. Our customer service is second to none, with a
                  team dedicated to ensuring your rental process is smooth and
                  enjoyable. We wish to exceed your expectations by providing
                  personalised services, including convenient pick-up and
                  drop-off options and expert advice on car selection.
                </p>

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 800,
                    marginTop: "2rem",
                    // textAlign: "center",
                  }}
                >
                  <TbTargetArrow /> Competitive Pricing
                </h6>
                <p style={{ color: "#868686" }}>
                  Despite offering a premium service, Christchurch Car
                  Rental maintains competitive pricing. We understand that
                  luxury doesn't have to come with an exorbitant price tag. By
                  choosing us, you get the best of both worlds – a luxurious car
                  and affordability.
                </p>

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 800,
                    marginTop: "2rem",
                    // textAlign: "center",
                  }}
                >
                  <TbTargetArrow /> Customizable Packages
                </h6>
                <p style={{ color: "#868686" }}>
                  Your journey is unique, and Christchurch Car Rental
                  recognizes that. We offer customizable rental packages to suit
                  your requirements. Whether you need a car for a few hours, a
                  day, a week, or even longer, we have flexible options to
                  ensure your experience is tailored to your schedule.
                </p>
              </Col>

              <h6
                className="section__subtitle"
                style={{
                  color: "#868686",
                  fontWeight: 800,
                  // marginTop: "2rem",
                  // textAlign: "center",
                }}
              >
                <TbTargetArrow /> Convenient Booking
              </h6>
              <p style={{ color: "#868686" }}>
                Booking a <strong>luxury car hire with Christchurch</strong>{" "}
                Car Rental is easy and hassle-free. You can reserve your vehicle
                online or call our team for guidance in selecting the ideal car
                for your trip.
              </p>
            </Row>
          </Container>
        </section>

        <section style={{ padding: "25px 0px", backgroundColor: "#fff" }}>
          <Container>
            <Row className="justify-content-between align-items-center">
              <h2
                className="section__title"
                style={{ color: "#000", fontWeight: 700 }}
              >
                What are the Services Offered by Christchurch Car Rental?
              </h2>

              <div className="" style={{ color: "#868686" }}>
                <p>
                  At <strong>luxury car hire Christchurch</strong> Car
                  Rental, we provide various transportation services that will
                  meet your diverse needs
                </p>
              </div>

              {width <= 576 && (
                <Col lg="4" md="6">
                  <div className="about__img aiport_rental_image">
                    <img src={luxury_rental2} alt="" className="w-100" />
                  </div>
                </Col>
              )}

              <h6
                className="section__subtitle"
                style={{
                  color: "#868686",
                  fontWeight: 800,
                  marginTop: "2rem",
                }}
              >
                <MdOutlineLocalLibrary /> City Transfers
              </h6>
              <p style={{ color: "#868686" }}>
                Pick Up and Drop Off: Navigating the city was challenging with
                our reliable city transfer services. Whether you're rushing to a
                crucial business meeting or leisurely exploring the local
                attractions, our fleet of comfortable vehicles and knowledgeable
                drivers are at your service to get you to your destination
                swiftly and efficiently.
              </p>

              <h6
                className="section__subtitle"
                style={{
                  color: "#868686",
                  fontWeight: 800,
                  marginTop: "2rem",
                }}
              >
                <MdCarRental /> Daily Rentals
              </h6>
              <p style={{ color: "#868686" }}>
                Our daily rental service is tailor-made for those seeking
                transportation for a day or more. Whether you're touring the
                city's hidden gems or completing daily errands, our extensive
                vehicle options cater to your personal preferences and practical
                needs.
              </p>

              <Col lg="8" md="6" className="">
                {/* <h6 className="section__subtitle">  </h6> */}

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 800,
                    marginTop: "2rem",
                  }}
                >
                  <MdMoreTime /> Long-Term Rentals
                </h6>
                <p style={{ color: "#868686" }}>
                  For those in need of a vehicle for an extended period, our
                  long-term rental options provide the perfect blend of
                  flexibility and cost savings. Enjoy the convenience of having
                  a car at your disposal without the long-term commitment and
                  responsibilities of ownership.
                </p>

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 800,
                    marginTop: "2rem",
                  }}
                >
                  <MdFlightTakeoff /> Airport Pick-up and Drop-Off
                </h6>
                <p style={{ color: "#868686" }}>
                  Travelling to or from Christchurch Airport has never been
                  more convenient. Our airport transfer services provide a
                  seamless transition, with our professional drivers ensuring
                  punctuality and comfort for your flight journeys.
                </p>

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 800,
                    marginTop: "2rem",
                  }}
                >
                  <RiCustomerService2Line /> 24/7 Customer Support
                </h6>
                <p style={{ color: "#868686" }}>
                  Our commitment to excellent customer service means you can
                  reach us 24/7. We are here to assist you with any questions or
                  concerns you may have about your rental, ensuring your peace
                  of mind throughout your journey.
                </p>
              </Col>

              {width > 576 && (
                <Col lg="4" md="6">
                  <div className="about__img aiport_rental_image">
                    <img src={luxury_rental2} alt="" className="w-100" />
                  </div>
                </Col>
              )}

              <div className="mt-4 " style={{ color: "#868686" }}>
                <p>
                  At Christchurch Car Rental, we are committed to delivering
                  not just transportation but an exceptional experience,
                  ensuring that your time in Christchurch is as seamless and
                  enjoyable as possible.
                </p>
              </div>
            </Row>
          </Container>
        </section>

        <section style={{ padding: "25px 0px", backgroundColor: "#fff" }}>
          <Container>
            <Row className="justify-content-between align-items-center">
              <h2
                className="section__title"
                style={{ color: "#000", fontWeight: 700 }}
              >
                Do You Have Luxury Cars for Every Occasion?
              </h2>

              {width <= 576 && (
                <Col lg="4" md="6">
                  <div className="about__img aiport_rental_image">
                    <img src={luxury_rental2} alt="" className="w-100" />
                  </div>
                </Col>
              )}

              <h6
                className="section__subtitle"
                style={{
                  color: "#868686",
                  fontWeight: 800,
                  marginTop: "2rem",
                }}
              >
                <TbTargetArrow /> Sports Cars
              </h6>
              <div className="">
                <p className="mb-3" style={{ color: "#868686" }}>
                  Christchurch Car Rental offers a wide range of
                  high-performance sports cars that are guaranteed to get your
                  heart racing. These sleek and aerodynamic vehicles are not
                  just about style; they're engineered for speed, precision, and
                  an exhilarating driving experience. Whether you're a
                  thrill-seeker looking to push the limits of the road or want
                  to make a statement, these sports cars are sure to satisfy
                  your need for speed.
                </p>
                <p style={{ color: "#868686" }}>
                  From the throaty roars of the engine to the precise handling,
                  these cars are a manifestation of automotive excellence. From
                  iconic brands like Porsche, Ferrari, and Lamborghini, you can
                  choose a sports car that matches your dreams and desires. A
                  day out in one of these speed demons promises an adventure you
                  will remember.
                </p>
              </div>

              {width > 576 && (
                <Col lg="4" md="6">
                  <div className="about__img aiport_rental_image">
                    <img src={luxury_rental2} alt="" className="w-100" />
                  </div>
                </Col>
              )}

              <Col lg="7" md="6" className="">
                {/* <h6 className="section__subtitle">  </h6> */}

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 800,
                    marginTop: "2rem",
                  }}
                >
                  <TbTargetArrow /> Executive Sedans
                </h6>
                <div className="">
                  <p className="mb-3" style={{ color: "#868686" }}>
                    Sometimes, a luxurious and comfortable ride is all you need.
                    Christchurch Car Rental's executive sedans offer just
                    that. Perfect for business trips where you want to make a
                    lasting impression or special occasions where you want to
                    arrive in style and comfort, these cars provide a
                    sophisticated experience. The executive sedans combine plush
                    interiors with advanced technology and a smooth ride to
                    ensure your journey is nothing short of first-class.
                  </p>
                  <p style={{ color: "#868686" }}>
                    Whether you're cruising through the city or embarking on a
                    long-distance trip, our{" "}
                    <strong>luxury car hire in Christchurch</strong>
                    offers the perfect blend of luxury and practicality. Brands
                    like Mercedes-Benz, Audi, and BMW are represented in our
                    executive sedan lineup, promising a premium experience every
                    time you hit the road.
                  </p>
                </div>

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 800,
                    marginTop: "2rem",
                  }}
                >
                  <TbTargetArrow /> SUVs
                </h6>
                <div className="">
                  <p className="mb-3" style={{ color: "#868686" }}>
                    If you're seeking space, versatility, and the ability to
                    conquer both the urban jungle and the rugged outdoors,
                    Christchurch Car Rental's luxury SUVs are your ideal
                    choice. These vehicles combine the comfort and amenities of
                    a luxury car with the ruggedness and capability of an SUV.
                    Whether you're planning a family road trip, an outdoor
                    adventure, or need extra space for your luggage, these SUVs
                    have you covered.
                  </p>
                  <p style={{ color: "#868686" }}>
                    From the throaty roars of the engine to the precise
                    handling, these cars are a manifestation of automotive
                    excellence. From iconic brands like Porsche, Ferrari, and
                    Lamborghini, you can choose a sports car that matches your
                    dreams and desires. A day out in one of these speed demons
                    promises an adventure you will remember.
                  </p>
                </div>
              </Col>

              <h6
                className="section__subtitle"
                style={{
                  color: "#868686",
                  fontWeight: 800,
                  marginTop: "2rem",
                }}
              >
                <TbTargetArrow /> Airport Pick-up and Drop-Off
              </h6>
              <div className="">
                <p className="mb-3" style={{ color: "#868686" }}>
                  Christchurch Car Rental offers a wide range of
                  high-performance sports cars that are guaranteed to get your
                  heart racing. These sleek and aerodynamic vehicles are not
                  just about style; they're engineered for speed, precision, and
                  an exhilarating driving experience. Whether you're a
                  thrill-seeker looking to push the limits of the road or want
                  to make a statement, these sports cars are sure to satisfy
                  your need for speed.
                </p>
                <p style={{ color: "#868686" }}>
                  With all-wheel drive and robust engines, they handle a variety
                  of terrains with ease. Safety, style, and ample space are all
                  part of the package when you choose a luxury SUV from
                  Christchurch Car Rental. Brands like Range Rover,
                  Cadillac, and Volvo are just some of the options available to
                  meet your needs.
                </p>
              </div>

              <h6
                className="section__subtitle"
                style={{
                  color: "#868686",
                  fontWeight: 800,
                  marginTop: "2rem",
                }}
              >
                <TbTargetArrow /> Convertibles
              </h6>
              <div className="">
                <p className="mb-3" style={{ color: "#868686" }}>
                  Few things compare to the joy of open-air driving, and
                  Christchurch Car Rental's convertible models make it all
                  the more exciting. These cars are the embodiment of freedom
                  and style, allowing you to soak in the sun and breeze as you
                  cruise along scenic routes. Ideal for sunny days and
                  picturesque drives, convertibles add an element of adventure
                  and elegance to your journey.
                </p>
                <p style={{ color: "#868686" }}>
                  From iconic brands like Audi and Mercedes-Benz, our
                  convertible models are sure to turn heads and provide a
                  memorable experience. Whether you're exploring coastal roads
                  or vineyard routes or want to feel the wind in your hair, a
                  convertible from Christchurch Car Rental is your ticket to
                  a unique and exhilarating driving adventure.
                </p>
              </div>

              <div className="" style={{ color: "#868686" }}>
                <p>
                  We provide a comprehensive range of{" "}
                  <strong>luxury car hire in Christchurch</strong> for
                  various preferences and occasions. Whether you're looking for
                  speed, sophistication, space, or the thrill of open-air
                  driving, our diverse fleet has you covered, ensuring that your
                  journey through Christchurch and its surroundings is an
                  unforgettable experience.
                </p>
              </div>
            </Row>
          </Container>
        </section>

        <section style={{ padding: "25px 0px" }}>
          <Container>
            <Row className="justify-content-center">
              <Col lg="12" className="text-center mb-3">
                <h6
                  className="section__subtitle"
                  style={{ color: "#868686", fontWeight: 700 }}
                >
                  Come with
                </h6>
                <h2 className="section__title">Hot Offers</h2>
              </Col>

              {/* <Col  className="text-center mb-5"> */}
              {/* <div className="align-items-end justify-content-end"> */}
              <Link to="/cars" className="home-view-more mb-3">
                View more
              </Link>
              {/* </div> */}
              {/* </Col> */}

              <ReactPlaceholder
                type="text"
                color="#F0F0F0"
                showLoadingAnimation
                rows={10}
                delay={200}
                ready={!loadingAllCars}
              >
                <Row className="row-car-list">
                  {allCars?.slice(0, 6)?.map((item) => (
                    <CarItem item={item} key={item._id} />
                  ))}
                </Row>
              </ReactPlaceholder>
            </Row>
          </Container>
        </section>

        <section style={{ padding: "25px 0px" }}>
          <Container>
            <Row className="justify-content-center">
              <Col lg="12" className="text-center">
                <h2 className="section__title">
                  Frequently Asked Questions (FAQ)
                </h2>
              </Col>

              {/* <Col  className="text-center mb-5"> */}
              {/* <div className="align-items-end justify-content-end"> */}
              <Link to="/faq" className="home-view-more mb-3">
                View more
              </Link>
              {/* </div> */}
              {/* </Col> */}

              <FaqLuxury />
            </Row>
          </Container>
        </section>

        <section style={{ padding: "25px 0px" }}>
          <Container>
            <Row className="mt-3">
              <Col lg="12" className="mb-5 ">
                {/* <h6 className="section__subtitle">  </h6> */}

                <h2
                  className="section__title"
                  style={{ color: "#000", fontWeight: 700 }}
                >
                  Conclusion
                </h2>

                <p style={{ color: "#868686" }}>
                  Christchurch Car Rental is your gateway to luxury and an
                  unforgettable journey in Christchurch. We provide numerous
                  high-quality vehicles, exceptional customer service,
                  competitive pricing, and customizable packages to meet diverse
                  needs and preferences. Whether it's a special occasion, a
                  business trip, or a leisurely drive through the stunning New
                  Zealand landscape, Christchurch Car Rental is your trusted
                  partner for{" "}
                  <strong>luxury car hire in Christchurch</strong>. So
                  elevate your journey and experience Christchurch like
                  never before with us.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </motion.div>
    </>
  );
};

export default LuxuryCarRental;
