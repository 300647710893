import Layout from "./components/Layout/Layout";
import "react-toastify/dist/ReactToastify.css";
import "react-placeholder/lib/reactPlaceholder.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scroll(0, 0);
  }, [location]);

  return <Layout />;
}

export default App;
