import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  loading: false,
  err: "",
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    commonStart: (state, action) => {
      state.loading = true;
      state.err = "";
    },
    commonSuccess: (state, action) => {
      state.err = "";
      state.loading = false;
      state.data = action.payload;
    },
    commonFailure: (state, action) => {
      state.loading = false;
      state.err = action.payload;
    },
  },
});

export const { commonStart, commonSuccess, commonFailure } =
  commonSlice.actions;
export default commonSlice.reducer;
