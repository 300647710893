import React from "react";
import HelmetComponent from "../components/Helmet/HelmetComponent";
import { motion } from "framer-motion";
import CommonSection from "../components/UI/CommonSection";
import { Col, Container, Row } from "reactstrap";
import roadImg from "../assets/images/Road_Assistance-removebg-preview.png";

const Road_assistance = () => {
  return (
    <>
      <HelmetComponent title={"AA Roadside Assistance"} />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <CommonSection title="AA Roadside Assistance" />
        {/* <hi>Hiiii</hi> */}

        <Row className="align-items-center container">
          <Col lg="2" md="6">
            <div className="about__img">
              <img src={roadImg} alt="" className="w-100" />
            </div>
          </Col>
          <Col lg="10" md="6">
            <div className="m-5">
              <h2 className="section__title">
                AA Roadside Assistance Service for Car Rental in Christchurch, New Zealand
              </h2>
              <br />
              <span style={{ fontSize: "1.2rem" }}>Service Description:</span>

              <p className="section__description">
                When you find yourself in need of emergency roadside assistance
                while renting a car in Christchurch, New Zealand, the AA
                (New Zealand Automobile Association) Roadside Assistance Service
                is your trusted companion. Offering round-the-clock support,
                this service is designed to ensure you can continue your journey
                with peace of mind, knowing that help is just a phone call away.
                Whether you're a resident of New Zealand or a visitor exploring
                the beautiful landscapes, AA Roadside Assistance is there to
                provide you with reliable and timely support.
              </p>
            </div>
          </Col>
        </Row>

        <div className="content mb-5">
          <h2 className="background-theme text-light text-center w-100 p-2">
            Service Details
          </h2>
          <ul className="container">
            <li>
              <strong>Available 24/7:</strong>{" "}
              <p className="section__description">
                {" "}
                The AA Roadside Assistance Service is available around the
                clock, 365 days a year, ensuring that you're never left stranded
                on the road, no matter the time or place within New Zealand.
              </p>
            </li>
            <p>
              <strong>Diverse Coverage:</strong>{" "}
              <p className="section__description">
                This service caters to a wide range of common roadside issues,
                including flat tires, dead batteries, engine breakdowns, and
                more. The team of skilled technicians and service providers will
                strive to get you back on the road promptly.
              </p>
            </p>
            <li>
              <strong>Policy Reference Number:</strong>{" "}
              <p className="section__description">
                {" "}
                For a seamless and expedited experience, when you call AA
                customer service at 0800 367 266, please mention the policy
                reference number 4311992/100. This reference number helps the AA
                team access your information quickly, streamlining the process
                of dispatching assistance to your location.
              </p>
            </li>
            <li>
              <strong>Nationwide Network:</strong>{" "}
              <p className="section__description">
                {" "}
                The AA has a comprehensive network of service providers across
                New Zealand, including Christchurch. This means you can rely
                on their extensive reach to assist you wherever you may be in
                the country.
              </p>
            </li>
            <li>
              <strong>Towing and Recovery:</strong>{" "}
              <p className="section__description">
                {" "}
                In case your vehicle requires extensive repairs, the AA can
                arrange for towing to a nearby repair shop. This is particularly
                valuable when you're in an unfamiliar location and need a
                trusted partner to assist you.
              </p>
            </li>
            <p>
              <strong>Fuel Delivery:</strong>{" "}
              <p className="section__description">
                If you run out of fuel, AA can provide you with an emergency
                supply to get you back on the road without the need to search
                for a petrol station.
              </p>
            </p>
            <li>
              <strong>Lockout Service:</strong>{" "}
              <p className="section__description">
                {" "}
                Accidentally locked your keys in the car? The AA can help you
                regain access to your vehicle so you can carry on with your
                journey.
              </p>
            </li>
            <li>
              <strong>Peace of Mind:</strong>{" "}
              <p className="section__description">
                {" "}
                With AA Roadside Assistance, you can have peace of mind knowing
                that you're covered in case of unexpected vehicle troubles,
                reducing stress and ensuring your travel plans stay on track.
              </p>
            </li>
            <li>
              <strong>Membership Information:</strong>{" "}
              <p className="section__description">
                {" "}
                If you're already an AA member, your membership may include this
                service. If you're not a member, inquire about the option to use
                their services on a pay-per-use basis.
              </p>
            </li>
          </ul>{" "}
          <hr />
          <div>
            <h2 className="background-theme text-light text-center w-100 p-2">
              Benefits of AA Roadside Assistance for Car Rental:
            </h2>
            <ul className="container">
              <li>
                <strong>Prompt and Reliable Service:</strong>{" "}
                <p className="section__description">
                  {" "}
                  AA's reputation for timely and reliable assistance ensures
                  that you won't be left waiting long for help to arrive.
                </p>
              </li>
              <li>
                <strong>Reduced Stress:</strong>{" "}
                <p className="section__description">
                  {" "}
                  Traveling can be stressful, and unexpected car troubles can
                  make it even more challenging. AA Roadside Assistance is there
                  to reduce your stress and get you back on your way.
                </p>
              </li>
              <li>
                <strong>Safety and Security:</strong>{" "}
                <p className="section__description">
                  Being stranded on the side of the road can be unsafe,
                  especially at night. AA's service helps ensure your safety and
                  security.
                </p>
              </li>
              <li>
                <strong>Peace of Mind:</strong>{" "}
                <p className="section__description">
                  {" "}
                  Knowing that you have AA Roadside Assistance provides peace of
                  mind, allowing you to enjoy your journey without worrying
                  about vehicle-related setbacks.
                </p>
              </li>
              <li>
                <strong>Easy Access:</strong>{" "}
                <p className="section__description">
                  Simply call the AA customer service number with your policy
                  reference number, and they will quickly dispatch assistance to
                  your location.
                </p>
              </li>
              <li>
                <strong className="">
                  AA Roadside Assistance for car rentals in Christchurch,
                  New Zealand, is a valuable service that adds an extra layer of
                  security to your travel plans, making your journey as smooth
                  and stress-free as possible.
                </strong>
              </li>
            </ul>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Road_assistance;
