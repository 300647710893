import React, { useEffect } from "react";

import CommonSection from "../components/UI/CommonSection";
import AboutSection from "../components/UI/AboutSection";
import { Container, Row, Col } from "reactstrap";
import "../styles/about.css";
import { motion } from "framer-motion";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import { TbTargetArrow } from "react-icons/tb";
import { FiPhoneCall } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { setAvailableCars } from "../components/features/availableCarsSlice";
import HelmetComponent from "../components/Helmet/HelmetComponent";

const About = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAvailableCars());
  }, []);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <HelmetComponent
          title={"Christchurch Car Rental - About Us "}
          metaDesc={
            "About Us - Our car rental services are designed to meet your diverse needs and make your journey as smooth as possible. | Christchurch Car Rental"
          }
        />
        <CommonSection title="About Us" />
        <AboutSection aboutClass="aboutPage" />

        <section className="about__page-section">
          <Container>
            <Row>
              {/* <Col lg="6" md="6" sm="12">
                  <div className="about__page-img">
                    <img src={driveImg} alt="" className="w-100 rounded-3" />
                  </div>
                </Col> */}

              <Col>
                {/* <div className="about__page-img">
                    <img src={driveImg} alt="" className="w-100 rounded-3" />
                  </div> */}
                {/* <Col lg="6" md="6" sm="12"> */}
                <div className="about__page-content">
                  <div className="about-page-backgroundImg">
                    <h2 className="section__title">
                      Why Choose Christchurch Car Rental?
                    </h2>
                  </div>

                  <div className="about-us-points">
                    <div>
                      <p className="about-us-why-choose-point">
                        <AiOutlineCheckCircle
                          color="#000"
                          style={{ marginRight: "0.5rem" }}
                        />
                        Wide Selection of Vehicles:
                      </p>
                      <p style={{ color: "#868686", marginLeft: "0.5rem" }}>
                        We offer a diverse fleet of well-maintained vehicles,
                        ranging from compact cars for city adventures to
                        spacious SUVs for family getaways. No matter the
                        occasion, we have the perfect vehicle for you.
                      </p>
                    </div>

                    <div>
                      <p className="about-us-why-choose-point">
                        <AiOutlineCheckCircle
                          color="#000"
                          style={{ marginRight: "0.5rem" }}
                        />
                        Easy Booking:
                      </p>
                      <p style={{ color: "#868686", marginLeft: "0.5rem" }}>
                        Our user-friendly online booking system lets you reserve
                        your desired vehicle with just a few clicks. You can
                        also reach out to our friendly customer service team for
                        assistance in finding the ideal car for your
                        requirements.
                      </p>
                    </div>

                    <div>
                      <p className="about-us-why-choose-point">
                        <AiOutlineCheckCircle
                          color="#000"
                          style={{ marginRight: "0.5rem" }}
                        />
                        Competitive Rates:
                      </p>
                      <p style={{ color: "#868686", marginLeft: "0.5rem" }}>
                        We believe that quality car rental shouldn't break the
                        bank. Our competitive rates ensure that you get the best
                        value for your money, whether you need a vehicle for a
                        day, a week, or longer.
                      </p>
                    </div>

                    <div>
                      <p className="about-us-why-choose-point">
                        <AiOutlineCheckCircle
                          color="#000"
                          style={{ marginRight: "0.5rem" }}
                        />
                        Convenient Location:
                      </p>
                      <p style={{ color: "#868686", marginLeft: "0.5rem" }}>
                        Our car rental office is conveniently situated in the
                        heart of Christchurch city, making it easy for you
                        to pick up and drop off your rental vehicle without
                        hassle.
                      </p>
                    </div>

                    <div>
                      <p className="about-us-why-choose-point">
                        <AiOutlineCheckCircle
                          color="#000"
                          style={{ marginRight: "0.5rem" }}
                        />
                        Exceptional Customer Service:
                      </p>
                      <p style={{ color: "#868686", marginLeft: "0.5rem" }}>
                        Our dedicated team is committed to providing you with a
                        seamless experience. From the moment you book your car
                        to the moment you return it, we're here to assist you
                        every step of the way.
                      </p>
                    </div>

                    <div>
                      <p className="about-us-why-choose-point">
                        <AiOutlineCheckCircle
                          color="#000"
                          style={{ marginRight: "0.5rem" }}
                        />
                        Flexible Rental Options:
                      </p>
                      <p style={{ color: "#868686", marginLeft: "0.5rem" }}>
                        We offer flexible rental plans to suit your schedule.
                        Whether you need a short-term rental or an extended one,
                        we have options that can accommodate your needs.
                      </p>
                    </div>

                    <div>
                      <p className="about-us-why-choose-point">
                        <AiOutlineCheckCircle
                          color="#000"
                          style={{ marginRight: "0.5rem" }}
                        />
                        Local Insights:
                      </p>
                      <p style={{ color: "#868686", marginLeft: "0.5rem" }}>
                        As a part of the Christchurch community, we're happy
                        to share our local insights and recommendations to
                        enhance your travel experience. Ask us about the best
                        places to visit, dine, and explore in the city.
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* <section>
            <Container>
              <div className=" d-flex align-items-center gap-3 mt-4">
                <div>
                  <h1 className="section__subtitle">
                    <BsTelephone /> Contact Us
                  </h1>
                  <p>
                    Ready to hit the road? Book your rental car online or get in
                    touch with our team at 0220211997 to find the perfect
                    vehicle for your needs. Experience Christchurch with
                    comfort, style, and convenience – choose Christchurch
                    Car Rental today!
                  </p>
                </div>
              </div>
            </Container>
          </section> */}

        <section>
          <Container>
            <Row>
              <Col lg="12" className="mb-5 text-center about-us-contact">
                {/* <h6 className="section__subtitle">  </h6> */}
                <h2
                  className="section__title"
                  style={{ color: "#000", fontWeight: 700 }}
                >
                  <TbTargetArrow /> Our Mission
                </h2>
                <p style={{ color: "#868686" }}>
                  Our mission is to provide visitors and locals in Christchurch
                  North with a hassle-free and enjoyable car rental experience.
                  We aim to be the go-to choice for reliable, affordable, and
                  high-quality car rentals in the city. With a commitment to
                  exceptional service and customer satisfaction, we strive to
                  exceed your expectations and make your journey memorable.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section>
          <Container>
            <Row>
              <Col lg="12" className="mb-5 text-center about-us-contact">
                {/* <h6 className="section__subtitle">  </h6> */}
                <h2
                  className="section__title"
                  style={{ color: "#000", fontWeight: 700 }}
                >
                  <BsTelephone /> Contact Us
                </h2>
                <p style={{ color: "#868686" }}>
                  Ready to hit the road? Book your rental car online or get in
                  touch with our team at 0220211997 to find the perfect vehicle
                  for your needs. Experience Christchurch with comfort,
                  style, and convenience – choose Christchurch Car Rental
                  today!
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section>
          <Container>
            <Row>
              <Col lg="12" className="mb-5 text-center about-us-contact">
                {/* <h6 className="section__subtitle">  </h6> */}
                <h2
                  className="section__title"
                  style={{ color: "#000", fontWeight: 700 }}
                >
                  <FiPhoneCall /> Emergency Contact
                </h2>
                <p style={{ color: "#868686" }}>
                  In case of emergency or roadside assistance, please call our
                  24/7 emergency line at +64220211997 <br />
                  At Christchurch Car Rental, we're committed to providing
                  you with excellent service and support. Your satisfaction is
                  our priority, and we look forward to serving you on your next
                  journey.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        {/* <BecomeDriverSection /> */}

        {/* <section>
            <Container>
              <Row>
                <Col lg="12" className="mb-5 text-center">
                  <h6 className="section__subtitle">Experts</h6>
                  <h2 className="section__title">Our Members</h2>
                </Col>
                <OurMembers />
              </Row>
            </Container>
          </section> */}
      </motion.div>
    </>
  );
};

export default About;
