import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Spinner,
} from "reactstrap";
import Helmet from "../components/Helmet/HelmetComponent";
import CommonSection from "../components/UI/CommonSection";

import "../styles/contact.css";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import axiosInstance from "../utils/axiosUtil";
import {
  commonFailure,
  commonStart,
  commonSuccess,
} from "../components/features/commonSlice";
import { setAvailableCars } from "../components/features/availableCarsSlice";
import HelmetComponent from "../components/Helmet/HelmetComponent";

const socialLinks = [
  {
    url: "https://www.facebook.com/profile.php?id=61552062989011",
    icon: "ri-facebook-line",
  },
  {
    url: "https://www.instagram.com/christchurchcarrental?igsh=cnB5aXdhNDdlcGZh&utm_source=qr",
    icon: "ri-instagram-line",
  },
  // {
  //   url: "#",
  //   icon: "ri-linkedin-line",
  // },
  // {
  //   url: "#",
  //   icon: "ri-twitter-line",
  // },
];

const Contact = () => {
  const { token, user } = useSelector((state) => state.auth);
  const { loading, err } = useSelector((state) => state.common);
  const [values, setValues] = useState({
    name: token ? user?.name : "",
    email: token ? user?.email : "",
    message: "",
    mobile: token ? user?.mobile : "",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAvailableCars());
  }, []);

  const toastOptions = {
    position: "bottom-center",
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const { email, message, name, mobile } = values;

    try {
      dispatch(commonStart());
      const { data } = await axiosInstance.post("/api/query/post-query", {
        name,
        email,
        message,
        mobile,
      });

      if (data.savedQuery) {
        toast.success(
          "Thank you! We have received your query and will respond soon.",
          toastOptions
        );
        dispatch(commonSuccess(data.savedQuery));
        setValues({
          name: values?.name,
          email: values?.email,
          message: "",
          mobile: values?.mobile,
        });
      }
    } catch (error) {
      dispatch(commonFailure(error));
      toast.success("Please try again later.", toastOptions);
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <HelmetComponent
          title={"Contact Us | Christchurch Car Rental | Reach Out Today"}
          metaDesc={
            "Reach out to us easily. Contact Christchurch Car Rental for all your car rental inquiries."
          }
        />
        <CommonSection title="Contact Us" />
        <section style={{ backgroundColor: "#F1F4F8" }}>
          <Container>
            <h4 className="contact-us-title">We're Here to Help</h4>
            <p>
              Have questions, need assistance with your booking, or want local
              recommendations? Our friendly customer service team is here to
              assist you. Feel free to reach out to us via phone or email during
              our working hours. Your journey is important to us, and we're
              dedicated to ensuring you have a fantastic car rental experience
              in Christchurch City.
            </p>
            <Row className="justify-content-between">
              <Col lg="5" md="7">
                <h6 className="contact-us-title mb-4">Get In Touch</h6>

                <Form onSubmit={submitHandler}>
                  <FormGroup className="contact__form">
                    <Input
                      name="name"
                      value={values?.name}
                      onChange={handleChange}
                      required
                      placeholder="Your Name"
                      type="text"
                      readOnly={token ? true : false}
                    />
                  </FormGroup>
                  <FormGroup className="contact__form">
                    <Input
                      name="email"
                      value={values?.email}
                      onChange={handleChange}
                      required
                      placeholder="Email"
                      type="email"
                      readOnly={token ? true : false}
                    />
                  </FormGroup>
                  <FormGroup className="contact__form">
                    <Input
                      name="mobile"
                      value={values?.mobile}
                      onChange={handleChange}
                      required
                      placeholder="Mobile"
                      type="number"
                      readOnly={token ? true : false}
                    />
                  </FormGroup>
                  <FormGroup className="contact__form">
                    <textarea
                      rows="5"
                      name="message"
                      value={values?.message}
                      onChange={handleChange}
                      required
                      placeholder="Message"
                      className="textarea"
                    ></textarea>
                  </FormGroup>

                  <Button
                    disabled={loading}
                    className=" contact__btn"
                    type="submit"
                  >
                    {loading ? <Spinner color="light" /> : "Send Message"}
                  </Button>
                </Form>
              </Col>

              <Col lg="4" md="5">
                <div className="contact__info">
                  <h4 className="contact-us-title">Contact Information</h4>
                  <h6 className="contact-us-title">
                    <strong>Address</strong>
                  </h6>
                  <p className="section__description mb-0">
                    346 Manchester Street, Christchurch Central City,
                  </p>
                  <p className="section__description mb-0">Christchurch 8013</p>
                  <div className="mt-4 gap-2">
                    <h6 className="contact-us-title mb-0">
                      <strong>Phone</strong>
                    </h6>
                    <p className="section__description mb-0">
                      <a
                        href="tel:+64-220211997"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        +64220211997
                      </a>
                      <br />
                      <a
                        href="tel:(0064) 0220211997"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        (0064) 0220211997
                      </a>
                    </p>
                  </div>

                  <div className="mt-4 gap-2">
                    <h6 className="contact-us-title mb-0">
                      <strong>Email</strong>
                    </h6>
                    <p className="section__description mb-0">
                      General Inquiries:{" "}
                      <Link
                        to="#"
                        className="contact-mail-link"
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href =
                            "mailTo:christchurchcarrentals@gmail.com";
                        }}
                      >
                        christchurchcarrentals@gmail.com
                      </Link>
                    </p>
                    <p className="section__description mb-0">
                      Reservations:{" "}
                      <Link
                        to="#"
                        className="contact-mail-link"
                        onClick={(e) => {
                          e.preventDefault();
                          window.location.href =
                            "mailTo:christchurchcarrentals@gmail.com";
                        }}
                      >
                        christchurchcarrentals@gmail.com
                      </Link>
                    </p>
                  </div>

                  <h5 className="contact-us-title mt-4">Connect With Us</h5>
                  <h6 className="contact-us-title">
                    Follow us on social media for updates, travel tips, and
                    special offers
                  </h6>

                  <div className=" d-flex align-items-center gap-4 mt-3">
                    {socialLinks.map((item, index) => (
                      <a
                        rel="noopener noreferrer"
                        href={item.url}
                        key={index}
                        target="_blank"
                        className="social__link-icon"
                      >
                        <i class={item.icon}></i>
                      </a>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </motion.div>

      <ToastContainer />
    </>
  );
};

export default Contact;
