import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import ReactPlaceholder from "react-placeholder";
import { TbTargetArrow } from "react-icons/tb";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import {
  MdCarRental,
  MdFlightTakeoff,
  MdMoreTime,
  MdOutlineComputer,
  MdOutlineLocalLibrary,
} from "react-icons/md";
import { RiCustomerService2Line } from "react-icons/ri";

// import blog3 from "../assets/all-images/blog-img/blog-3.jpg";
import CommonSection from "../components/UI/CommonSection";
// import driveImg from "../assets/all-images/drive.jpg";
import wedding_rental1 from "../assets/all-images/wedding_rental1.jpg";
import wedding_rental2 from "../assets/all-images/wedding_rental2.jpg";
import wedding_rental3 from "../assets/all-images/wedding_rental3.jpg";
import "../styles/about.css";
import HelmetComponent from "../components/Helmet/HelmetComponent";
import { GiGlassCelebration } from "react-icons/gi";
import { FaBusinessTime } from "react-icons/fa";
import FaqWedding from "../components/UI/FaqWeddingCarRental";
import { useDispatch, useSelector } from "react-redux";
import {
  allCarsFailure,
  allCarsStart,
  allCarsSuccess,
} from "../components/features/allCarsSlice";
import axiosInstance from "../utils/axiosUtil";
import CarItem from "../components/UI/CarItem";

const WeddingCarRental = () => {
  const { allCars, loadingAllCars, allCarsErr } = useSelector(
    (state) => state.allCars
  );
  const [width, setWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();

  const fetchAllCars = async () => {
    dispatch(allCarsStart());
    try {
      const { data } = await axiosInstance.get(`/api/car/get-car?sort=low`);

      dispatch(allCarsSuccess(data.cars));
    } catch (error) {
      dispatch(allCarsFailure(error?.response?.data?.message));
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    fetchAllCars();
  }, []);

  return (
    <>
      <HelmetComponent
        title={"Best Wedding Car Hire in Christchurch | Book Now"}
        metaDesc={
          "Make your special day Unique with our wedding car rentals. Hire the perfect car for You. | Contact Us Now | Christchurch Car Rental"
        }
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <CommonSection title="Wedding Car Rental Christchurch" />

        <section className="" style={{ backgroundColor: "transparent" }}>
          <Container>
            <Row className="justify-content-between">
              <Col lg="6" md="6">
                <div>
                  {/* <h4 className="section__subtitle">About Us</h4> */}
                  <h2 className="section__title">
                    Wedding Car Hire in Christchurch
                    <br />
                  </h2>
                  <p className="section__description">
                    Christchurch, situated in the heart of New Zealand's
                    North Island, is a city brimming with culture, history, and
                    natural beauty. Whether you're a visitor looking to explore
                    the region's wonders or a local in need of reliable
                    transportation, Christchurch Car Rental is your go-to
                    choice. Discover how we can elevate your special day with
                    our exquisite{" "}
                    <strong>
                      wedding car hire services in Christchurch
                    </strong>
                    . Whether you desire a classic, vintage car for a timeless
                    look, a luxurious limousine for ultimate comfort, or a sleek
                    and modern vehicle for a contemporary feel, Christchurch
                    Car Rental has the perfect solution.
                  </p>
                </div>
              </Col>

              <Col lg="4" md="6">
                <div className=" aiport_rental_image">
                  <img src={wedding_rental3} alt="" className="" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section style={{ padding: "25px 0px", backgroundColor: "#fff" }}>
          <Container>
            <Row className="mt-lg-5 justify-content-between align-items-center">
              <h2
                className="section__title"
                style={{ color: "#000", fontWeight: 700 }}
              >
                What are the Services Offered by Christchurch Car Rental?
              </h2>

              <div className="" style={{ color: "#868686" }}>
                <p>
                  Whether you're visiting or need a{" "}
                  <strong>wedding car hire in Christchurch</strong>, our
                  services are designed to ensure a comfortable and convenient
                  journey. Here's an overview of the services we provide:
                </p>
              </div>

              {width <= 576 && (
                <Col lg="4" md="6">
                  <div className="about__img aiport_rental_image">
                    <img src={wedding_rental1} alt="" className="w-100" />
                  </div>
                </Col>
              )}

              <h6
                className="section__subtitle"
                style={{
                  color: "#868686",
                  fontWeight: 800,
                  marginTop: "2rem",
                }}
              >
                <MdOutlineLocalLibrary /> City Transfers - Pick Up and Drop Off
              </h6>
              <p style={{ color: "#868686" }}>
                Navigating the city is a breeze with our city transfer services.
                Whether you're attending a business meeting or exploring local
                attractions, our comfortable vehicles and knowledgeable drivers
                will ensure you get to your destination efficiently.
              </p>

              <h6
                className="section__subtitle"
                style={{
                  color: "#868686",
                  fontWeight: 800,
                  marginTop: "2rem",
                }}
              >
                <MdCarRental /> Daily Rentals
              </h6>
              <p style={{ color: "#868686" }}>
                Our daily rental service is perfect for those who need a car for
                a day or more. Whether you're touring the city or running
                errands, we offer several vehicles to meet your preferences and
                requirements.
              </p>

              {width > 576 && (
                <Col lg="4" md="6">
                  <div className="about__img aiport_rental_image">
                    <img src={wedding_rental1} alt="" className="w-100" />
                  </div>
                </Col>
              )}

              <Col lg="7" md="6" className="">
                {/* <h6 className="section__subtitle">  </h6> */}

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 800,
                    marginTop: "2rem",
                  }}
                >
                  <MdMoreTime /> Long-Term Rentals
                </h6>
                <p style={{ color: "#868686" }}>
                  If you require a vehicle for an extended period, our long-term
                  rental options provide flexibility and cost savings. Enjoy the
                  convenience of having a car without the commitment of
                  ownership.
                </p>

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 800,
                    marginTop: "2rem",
                  }}
                >
                  <MdFlightTakeoff /> Airport Pickup and Drop Off Your Rental
                  Car
                </h6>
                <p style={{ color: "#868686" }}>
                  We offer convenient airport transfer services to and from
                  Christchurch Airport.
                </p>

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 800,
                    marginTop: "2rem",
                  }}
                >
                  <RiCustomerService2Line /> 24/7 Customer Support
                </h6>
                <p style={{ color: "#868686" }}>
                  Our support team is available to assist you with any questions
                  or concerns you may have about your rental 24/7.
                </p>

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 800,
                    marginTop: "2rem",
                  }}
                >
                  <MdOutlineComputer /> Online Booking
                </h6>
                <p style={{ color: "#868686" }}>
                  We provide a user-friendly online booking platform, allowing
                  you to reserve your rental vehicle with ease. You have the
                  flexibility to modify or cancel your reservation online.
                </p>
              </Col>

              <h6
                className="section__subtitle"
                style={{
                  color: "#868686",
                  fontWeight: 800,
                  marginTop: "2rem",
                }}
              >
                <GiGlassCelebration /> Special Occasions
              </h6>
              <p style={{ color: "#868686" }}>
                Make your special moments even more extraordinary with our
                Special Occasions rental service. We offer a truly exceptional
                <strong>wedding car hire</strong> experience in{" "}
                <strong>Christchurch</strong> that adds a touch of elegance
                and sophistication to your special day.
              </p>

              <h6
                className="section__subtitle"
                style={{
                  color: "#868686",
                  fontWeight: 800,
                  marginTop: "2rem",
                }}
              >
                <FaBusinessTime /> Business Rentals
              </h6>
              <p style={{ color: "#868686" }}>
                We understand the unique needs of business travellers. Our
                business rental service offers tailored solutions, including
                corporate rates and customised billing options, to meet your
                specific business requirements.
              </p>

              <div className="" style={{ color: "#868686" }}>
                <p>
                  With our comprehensive range of services, Christchurch Car
                  Rental is your trusted partner for all your transportation
                  needs in Christchurch and beyond. Whether it's for
                  business, leisure, or special occasions, we're here to ensure
                  your journey is comfortable, convenient, and memorable.
                </p>
              </div>
            </Row>
          </Container>
        </section>

        <section style={{ padding: "25px 0px", backgroundColor: "#fff" }}>
          <Container>
            <Row className="mt-lg-5 justify-content-between align-items-center">
              <h2
                className="section__title"
                style={{ color: "#000", fontWeight: 700 }}
              >
                Why Choose Us?
              </h2>

              <div className="" style={{ color: "#868686" }}>
                <p>
                  At Christchurch Car Rental, we understand that
                  convenience, reliability, and exceptional service are the
                  cornerstones of a great car rental experience. Here's what
                  sets us apart:
                </p>
              </div>

              {width <= 576 && (
                <Col lg="4" md="6">
                  <div className="about__img aiport_rental_image">
                    <img src={wedding_rental2} alt="" className="w-100" />
                  </div>
                </Col>
              )}

              <h6
                className="section__subtitle"
                style={{
                  color: "#868686",
                  fontWeight: 800,
                  marginTop: "2rem",
                }}
              >
                <TbTargetArrow /> Various Vehicles
              </h6>
              <p style={{ color: "#868686" }}>
                We offer a wide selection of well-maintained vehicles, ranging
                from compact cars perfect for city adventures to spacious SUVs
                ideal for family getaways. If it's a special occasion like a
                wedding, there is no need to worry, as we have the perfect
                <strong>wedding car</strong> that you can{" "}
                <strong>hire in Christchurch</strong>.
              </p>

              <h6
                className="section__subtitle"
                style={{
                  color: "#868686",
                  fontWeight: 800,
                  marginTop: "2rem",
                }}
              >
                <TbTargetArrow /> Easy Booking
              </h6>
              <p style={{ color: "#868686" }}>
                Our user-friendly online booking system allows you to reserve
                your desired vehicle with just a few clicks.
              </p>

              <Col lg="8" md="6" className="">
                {/* <h6 className="section__subtitle">  </h6> */}
                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 800,
                    marginTop: "2rem",
                  }}
                >
                  <TbTargetArrow /> Competitive Rates
                </h6>
                <p style={{ color: "#868686" }}>
                  We firmly believe that quality car rental shouldn't break the
                  bank. Our competitive rates ensure that you get the best value
                  for your money, whether you need a vehicle for a day, a week,
                  or longer.
                </p>

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 800,
                    marginTop: "2rem",
                  }}
                >
                  <TbTargetArrow /> Convenient Location
                </h6>
                <p style={{ color: "#868686" }}>
                  Our car rental office is strategically situated in the heart
                  of Christchurch city, making it a breeze for you to pick
                  up and drop off your rental vehicle without any hassle.
                </p>

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 800,
                    marginTop: "2rem",
                  }}
                >
                  <TbTargetArrow /> Exceptional Customer Service
                </h6>
                <p style={{ color: "#868686" }}>
                  We are devoted to delivering a smooth and hassle-free
                  experience to our valued customers through our committed team.
                  Let us assist you in any way we can. From the moment you book
                  your car to the moment you return it, we're here to help you
                  every step of the way.
                </p>
              </Col>

              {width > 576 && (
                <Col lg="4" md="6">
                  <div className="about__img aiport_rental_image">
                    <img src={wedding_rental2} alt="" className="w-100" />
                  </div>
                </Col>
              )}

              <h6
                className="section__subtitle"
                style={{
                  color: "#868686",
                  fontWeight: 800,
                  marginTop: "2rem",
                }}
              >
                <TbTargetArrow /> Flexible Rental Options
              </h6>
              <p style={{ color: "#868686" }}>
                We offer flexible rental plans to accommodate your schedule.
                Whether you need a short-term rental for a weekend getaway or an
                extended rental for a longer adventure, we have options that
                suit your needs.
              </p>

              <h6
                className="section__subtitle"
                style={{
                  color: "#868686",
                  fontWeight: 800,
                  marginTop: "2rem",
                }}
              >
                <TbTargetArrow /> Local Insights
              </h6>
              <p style={{ color: "#868686" }}>
                As an integral part of the Christchurch community, we're
                delighted to share our local insights and recommendations to
                enhance your travel experience. Don't hesitate to ask us about
                the best places to visit, dine, and explore in the city.
              </p>
            </Row>
          </Container>
        </section>

        <section style={{ padding: "25px 0px", backgroundColor: "#fff" }}>
          <Container>
            <Row className="justify-content-between align-items-center mt-lg-5">
              <h2
                className="section__title"
                style={{ color: "#000", fontWeight: 700 }}
              >
                Are there any Benefits to Choosing Christchurch Car Rental?
              </h2>

              <div className="" style={{ color: "#868686" }}>
                <p>
                  Now that you know what we offer, let's explore the benefits
                  that Christchurch Car Rental provides:
                </p>
              </div>

              <Col lg="12" className="mb-3">
                {/* <h6 className="section__subtitle">  </h6> */}

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 800,
                    marginTop: "2rem",
                  }}
                >
                  <TbTargetArrow /> Reliability
                </h6>
                <div className="">
                  <p className="m-0" style={{ color: "#868686" }}>
                    Our well-maintained vehicles undergo regular inspections and
                    maintenance to ensure they are in top-notch condition. Your
                    rental car will be reliable, allowing you to focus on your
                    journey without any worries.
                  </p>
                  <p style={{ color: "#868686" }}>
                    Our dedication to customer service extends to vehicle
                    reliability. We go the extra mile to ensure that you receive
                    a vehicle that meets your needs and exceeds your
                    expectations.
                  </p>
                </div>

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 800,
                    marginTop: "2rem",
                  }}
                >
                  <TbTargetArrow /> Affordability
                </h6>
                <div className="">
                  <p className="m-0" style={{ color: "#868686" }}>
                    We understand that budget matters, and we pride ourselves on
                    offering competitive rates that won't put a dent in your
                    wallet. Explore Christchurch and its surroundings
                    without breaking the bank.
                  </p>
                  <p style={{ color: "#868686" }}>
                    Our commitment to affordability doesn't mean compromising on
                    quality. You can expect a high-quality{" "}
                    <strong>wedding car hire in Christchurch</strong> at a
                    price that suits your budget.
                  </p>
                </div>

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 800,
                    marginTop: "2rem",
                  }}
                >
                  <TbTargetArrow /> Convenience
                </h6>
                <div className="">
                  <p className="m-0" style={{ color: "#868686" }}>
                    Our central location in the heart of Christchurch City
                    makes picking up and dropping off your rental vehicle a
                    breeze—no need to spend extra time commuting to an
                    out-of-the-way rental office.
                  </p>
                  <p style={{ color: "#868686" }}>
                    The convenience of our user-friendly online booking process
                    means you can reserve your vehicle from the comfort of your
                    own home, saving you time and effort during your trip.
                  </p>
                </div>

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 800,
                    marginTop: "2rem",
                  }}
                >
                  <TbTargetArrow /> Local Expertise
                </h6>
                <div className="">
                  <p className="m-0" style={{ color: "#868686" }}>
                    As locals, we have a deep connection to Christchurch and
                    the surrounding areas. Our team is passionate about sharing
                    our insider knowledge to enhance your travel experience.
                  </p>
                  <p style={{ color: "#868686" }}>
                    We can recommend hidden gems, lesser-known attractions, and
                    local dining spots that will help you discover the authentic
                    essence of Christchurch.
                  </p>
                </div>

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 800,
                    marginTop: "2rem",
                  }}
                >
                  <TbTargetArrow /> Flexibility
                </h6>
                <div className="">
                  <p className="m-0" style={{ color: "#868686" }}>
                    We're here to help make adjustments to your unpredictable
                    travel plans. Whether you need a car for a single day, an
                    entire week, or even longer, we have flexible schedules to
                    accommodate your needs.
                  </p>
                  <p style={{ color: "#868686" }}>
                    If your plans change, our team is here to assist you in
                    modifying your rental duration or vehicle type to ensure
                    your trip remains stress-free and convenient.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section style={{ padding: "25px 0px" }}>
          <Container>
            <Row className="justify-content-center">
              <Col lg="12" className="text-center mb-3">
                <h6
                  className="section__subtitle"
                  style={{ color: "#868686", fontWeight: 700 }}
                >
                  Come with
                </h6>
                <h2 className="section__title">Hot Offers</h2>
              </Col>

              {/* <Col  className="text-center mb-5"> */}
              {/* <div className="align-items-end justify-content-end"> */}
              <Link to="/cars" className="home-view-more mb-3">
                View more
              </Link>
              {/* </div> */}
              {/* </Col> */}

              <ReactPlaceholder
                type="text"
                color="#F0F0F0"
                showLoadingAnimation
                rows={10}
                delay={200}
                ready={!loadingAllCars}
              >
                <Row className="row-car-list">
                  {allCars?.slice(0, 6)?.map((item) => (
                    <CarItem item={item} key={item._id} />
                  ))}
                </Row>
              </ReactPlaceholder>
            </Row>
          </Container>
        </section>

        <section>
          <Container>
            <Row className="justify-content-center">
              <Col lg="12" className="text-center mb-3">
                <h2 className="section__title">
                  Frequently Asked Questions (FAQ)
                </h2>
              </Col>

              {/* <Col  className="text-center mb-5"> */}
              {/* <div className="align-items-end justify-content-end"> */}
              <Link to="/faq" className="home-view-more mb-3">
                View more
              </Link>
              {/* </div> */}
              {/* </Col> */}

              <FaqWedding />
            </Row>
          </Container>
        </section>

        <section style={{ padding: "25px 0px" }}>
          <Container>
            <Row className="">
              <Col lg="12" className="mb-5">
                {/* <h6 className="section__subtitle">  </h6> */}

                <p style={{ color: "#868686" }}>
                  Unlock the endless possibilities of Christchurch with
                  Christchurch Car Rental. We're here to make your journey
                  as smooth as possible so you can focus on creating lasting
                  memories in this charming city. Contact us today, and let's
                  get you on the road to adventure!
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </motion.div>
    </>
  );
};

export default WeddingCarRental;
