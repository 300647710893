import { createSlice } from "@reduxjs/toolkit";

const initialState = { 
  carBookingHistory: {},
  loadingCarBookingHistory: false,
  carBookingHistoryErr: "",
};

const bookedCarHistorySlice = createSlice({
  name: "carBookingHistory",
  initialState,
  reducers: {
    carBookingHistoryStart: (state, action) => {
      state.loadingCarBookingHistory = true;
      state.carBookingHistoryErr = "";
    },
    carBookingHistorySuccess: (state, action) => {
      state.carBookingHistoryErr = "";
      state.loadingCarBookingHistory = false;
      state.carBookingHistory = action.payload;
    },
    carBookingHistoryFailure: (state, action) => {
      state.loadingCarBookingHistory = false;
      state.carBookingHistoryErr = action.payload;
    },
    setCarBookingHistory: (state, action) => {
      state.carBookingHistory = {};
      state.loadingCarBookingHistory = false;
      state.carBookingHistoryErr = "";
    },
  },
});

export const {
  carBookingHistoryStart,
  carBookingHistoryFailure,
  carBookingHistorySuccess,
  setCarBookingHistory,
} = bookedCarHistorySlice.actions;
export default bookedCarHistorySlice.reducer;
