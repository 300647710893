import React, { useEffect, useState } from "react";
import { Container, Form, Button, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import axiosInstance from "../../utils/axiosUtil";
import { motion } from "framer-motion";
import "../../styles/add.css";
import { useDispatch, useSelector } from "react-redux";
import {
  registerFailure,
  registerStart,
  registerSuccess,
} from "../features/authSlice";

const Register = () => {
  const navigate = useNavigate();
  const { isFetching, error, errMsg } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('redirectTo');
  let querys=""
  if(paramValue)
  querys="?redirectTo="+paramValue;
  const toastOptions = {
    position: "bottom-center",
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  useEffect(() => {
    if (localStorage.getItem("userToken")) {
      navigate("/");
    }
  }, []);

  const [values, setValues] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(registerStart());

    const { email, password, phone, name } = values;

    try {
      const { data } = await axiosInstance.post("/api/user/register", {
        email,
        password,
        phone,
        name,
      });

      if (data.token) {
        dispatch(registerSuccess(data));
        if(paramValue){
          navigate(`/${paramValue}`);
          return ;
        }
        navigate("/", { replace: true });
      } else {
        // show error
        toast.error("Something went wrong. Please try again!", toastOptions);
        dispatch(registerFailure("Something went wrong!"));
      }
    } catch (error) {
      // console.log(error.response.data.message);
      toast.error(error?.response?.data?.message, toastOptions);
      dispatch(registerFailure(error.response.data.message));
    }

    // try{
    //   dispatch({ type: "FETCH_REQUEST" });
    //   const {data} = await  axiosInstance.post('/api/user/register',values);

    //   if (data.token) {
    //     ctxdispatch({ type: "USER_SIGNIN", payload: data });
    //    localStorage.setItem("userInfo", JSON.stringify(data.user));
    //    localStorage.setItem("token", JSON.stringify(data.token));
    //    dispatch({ type: "FETCH_SUCCESS" });
    //  }
    //  else
    //  {
    //    // show error
    //  }

    // }
    // catch(error)
    // {
    //   dispatch({
    //     type: "FETCH_FAIL",
    //     payload: error,
    //   });
    // }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <Container className="pt-5 px-3 px-md-0 f-center flex-column wt-500">
          <div className="f-center mb-4">
            {/* <Link to="/sign-in" className="toggle-link-item">
            Login
          </Link>
          <Link to="/sign-up" className="toggle-link-item active-link">
            Register
          </Link> */}
            <p>Create your Account</p>
            <span>Turn Moments into Memories with Our Rental Fleet</span>
          </div>
          <div className="form-box">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Name"
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Control
                  type="number"
                  name="phone"
                  placeholder="Mobile No."
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <div className="no-acc-link">
                <Link to={`/sign-in${querys}`} className="">
                  Already have an account?
                </Link>
              </div>
              {isFetching ? (
                <Button className="login-regis-btn" size="lg" disabled>
                  <Spinner animation="border" variant="light" />
                </Button>
              ) : (
                <Button type="submit" className="login-regis-btn">
                  Submit
                </Button>
              )}
            </Form>
          </div>
        </Container>
      </motion.div>

      <ToastContainer />
    </>
  );
};

export default Register;
