import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import ReactPlaceholder from "react-placeholder";
import "../styles/profile.css";
import axiosInstance from "../utils/axiosUtil";
import { Alert, Button, Col, Container, Row } from "reactstrap";
import Helmet from "../components/Helmet/HelmetComponent";
import CarItem from "../components/UI/CarItem";
import carData from "../assets/data/carData";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  bookingHistoryFailure,
  bookingHistoryStart,
  bookingHistorySuccess,
} from "../components/features/bookingHistorySlice";
import { AiFillCar, AiFillInfoCircle, AiFillSetting } from "react-icons/ai";
import { IoSpeedometerSharp } from "react-icons/io5";
import { FaLocationDot } from "react-icons/fa6";
import { format } from "date-fns";
import ModalLayout from "../components/UI/ModalLayout";
import AlertLayout from "../components/UI/AlertLayout";
import { motion } from "framer-motion";
import CommonSection from "../components/UI/CommonSection";

const BookingHistory = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [bookingId, setBookingId] = useState("");
  const { bookingHistory, loadingBookingHistory, bookingHistoryErr } =
    useSelector((state) => state.bookingHistory);
  const { token, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const toastOptions = {
    position: "bottom-center",
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  const toggle = () => setModal(!modal);

  const fetchBookingHistory = async () => {
    dispatch(bookingHistoryStart());

    try {
      const { data } = await axiosInstance.get("/api/booking/getAll", {
        headers: { Authorization: token },
      });

      dispatch(bookingHistorySuccess(data.bookings));
    } catch (error) {
      console.log(error);
      dispatch(bookingHistoryFailure(error?.response?.data?.error));
    }
  };

  useEffect(() => {
    fetchBookingHistory();
  }, []);

  const acceptHireAgree = async () => {
    try {
      const { data } = await axiosInstance.post(
        `/api/booking/accept-hireAgree/${bookingId}`,
        {
          hireAgreement: true,
        },
        { headers: { Authorization: token } }
      );

      if (data.booking) {
        toggle();
        await fetchBookingHistory();
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!", toastOptions);
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <Helmet title="Christchurch Car Rental - Booking History" />
        <CommonSection title={`Booking History`} />
        <Container className="my-3">
          <div className="">
            {/* <div className="booking-history-title">
                <h1>Booking history</h1>
              </div> */}
            <Helmet title="Booking History">
              <div>
                <ReactPlaceholder
                  type="text"
                  color="#F0F0F0"
                  showLoadingAnimation
                  rows={10}
                  delay={200}
                  ready={!loadingBookingHistory}
                >
                  <div>
                    <Row className="justify-content-center mt-3">
                      {bookingHistory?.map((bookingHistory) => (
                        <Row
                          className="booking-history-root"
                          key={bookingHistory?._id}
                        >
                          <div className="booking-history-upperCont">
                            {/* <p>
                            Booking number:
                            <span>{bookingHistory?.paypalOrderId}</span>
                          </p> */}
                            <p>
                              <span style={{ color: "#868686" }}>
                                Booked on:
                              </span>{" "}
                              <span style={{ fontWeight: 600 }}>
                                {format(
                                  new Date(bookingHistory?.createdAt),
                                  "MMMM dd, yyyy"
                                )}
                              </span>
                            </p>
                            <p
                              className={
                                bookingHistory?.hireAgreement === "Pending"
                                  ? "booking-history-hire-pending"
                                  : "booking-history-hire"
                              }
                            >
                              <span style={{ color: "#868686" }}>
                                Hire Agreement:
                              </span>{" "}
                              <span>{bookingHistory?.hireAgreement}</span>
                            </p>
                            <p
                              className={
                                bookingHistory?.status === "PENDING"
                                  ? "booking-history-hire-pending"
                                  : "booking-history-hire"
                              }
                            >
                              <span style={{ color: "#868686" }}>
                                Payment status:
                              </span>{" "}
                              <span>{bookingHistory?.status}</span>
                            </p>
                          </div>
                          <Col md="3">
                            <img
                              src={bookingHistory?.car?.image}
                              alt={bookingHistory?.car?.name}
                              className="bookingHistory-carimg"
                            />
                          </Col>
                          <Col md="2">
                            <div className="bookingHistory-carDetails">
                              <p className="align-items-center">
                                <AiFillCar
                                  style={{
                                    color: "#7a7a87",
                                    marginRight: "0.5rem",
                                  }}
                                />
                                <strong style={{ color: "#7a7a87" }}>
                                  Model
                                </strong>{" "}
                                <p>
                                  <strong
                                    style={{
                                      color: "#000",
                                      marginLeft: "0.5rem",
                                    }}
                                  >
                                    {bookingHistory?.car?.model}
                                  </strong>
                                </p>
                              </p>
                              <p>
                                <AiFillInfoCircle
                                  style={{
                                    color: "#7a7a87",
                                    marginRight: "0.5rem",
                                  }}
                                />
                                <strong style={{ color: "#7a7a87" }}>
                                  Brand
                                </strong>{" "}
                                <p>
                                  <strong
                                    style={{
                                      color: "#000",
                                      marginLeft: "0.5rem",
                                    }}
                                  >
                                    {bookingHistory?.car?.brand}
                                  </strong>
                                </p>
                              </p>
                              <p>
                                <IoSpeedometerSharp
                                  style={{
                                    color: "#7a7a87",
                                    marginRight: "0.5rem",
                                  }}
                                />
                                <strong style={{ color: "#7a7a87" }}>
                                  Speed
                                </strong>{" "}
                                <p>
                                  <strong
                                    style={{
                                      color: "#000",
                                      marginLeft: "0.5rem",
                                    }}
                                  >
                                    {bookingHistory?.car?.speed}
                                  </strong>
                                </p>
                              </p>
                            </div>
                          </Col>
                          <Col md="3">
                            <div className="bookingHistory-carDetails">
                              <p>
                                <FaLocationDot
                                  style={{
                                    color: "#7a7a87",
                                    marginRight: "0.5rem",
                                  }}
                                />
                                <strong style={{ color: "#7a7a87" }}>
                                  GPS
                                </strong>{" "}
                                <p>
                                  <strong
                                    style={{
                                      color: "#000",
                                      marginLeft: "0.5rem",
                                    }}
                                  >
                                    {bookingHistory?.car?.gps}
                                  </strong>
                                </p>
                              </p>
                              <p>
                                <AiFillSetting
                                  style={{
                                    color: "#7a7a87",
                                    marginRight: "0.5rem",
                                  }}
                                />
                                <strong style={{ color: "#7a7a87" }}>
                                  Automatic
                                </strong>{" "}
                                <p>
                                  <strong
                                    style={{
                                      color: "#000",
                                      marginLeft: "0.5rem",
                                    }}
                                  >
                                    {bookingHistory?.car?.automatic}
                                  </strong>
                                </p>
                              </p>
                            </div>
                          </Col>

                          <Col>
                            <div className="booking-history-btnCont">
                              {bookingHistory?.hireAgreement === "Pending" ? (
                                <Button
                                  onClick={() => {
                                    toggle();
                                    setBookingId(bookingHistory?._id);
                                  }}
                                  className="mt-2"
                                >
                                  Click here to accept hire agreement
                                </Button>
                              ) : (
                                <Button className="mt-2" disabled>
                                  The agreement will be given to you at the
                                  pickup time!
                                </Button>
                              )}
                              <Button
                                onClick={() =>
                                  navigate(
                                    `/booking-history/${bookingHistory?._id}`
                                  )
                                }
                              >
                                View details
                              </Button>
                              <Button
                                onClick={() =>
                                  navigate(`/cars/${bookingHistory?.car?._id}`)
                                }
                              >
                                View car details
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      ))}

                      {bookingHistory?.length <= 0 && (
                        <AlertLayout
                          color={"primary"}
                          btnTitle={"All Cars"}
                          title={`Hello, ${user?.name}`}
                          body={
                            <>
                              <p>
                                We hope you're doing well. It appears that you
                                currently don't have any car rental bookings in
                                your account. Whether you're still in the
                                planning stages or simply enjoying other means
                                of transportation, know that we're here to
                                assist whenever you're ready to hit the road.
                              </p>

                              <p>
                                Feel free to explore our range of vehicles and
                                rental options at your convenience. If you have
                                any questions or need assistance, please don't
                                hesitate to reach out. We're dedicated to
                                helping you find the perfect car for your next
                                adventure.
                              </p>
                            </>
                          }
                          navLink={"/cars"}
                        />
                      )}
                    </Row>
                  </div>
                </ReactPlaceholder>
              </div>
            </Helmet>
          </div>
        </Container>
      </motion.div>

      <ToastContainer />

      <ModalLayout
        modal={modal}
        toggle={toggle}
        successTitle={"Accept Hire Agreement"}
        success={acceptHireAgree}
        title={"Accept the hire agreement"}
        body={
          <>
            <div>
              <p>
                You are now just one step away from grabing your key to your
                next adventure
              </p>

              {/* <p>A copy of the agreement will be mailed to you.</p> */}
            </div>
          </>
        }
      />
    </>
  );
};

export default BookingHistory;
