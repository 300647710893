import React, { useEffect, useState } from "react";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import ReactImageMagnify from "react-image-magnify";
import ThreeSixty from "react-360-view";
// import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";
import { Container, Row, Col, Button } from "reactstrap";
import Helmet from "../components/Helmet/HelmetComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  carDetailsFailure,
  carDetailsStart,
  carDetailsSuccess,
} from "../components/features/getCarSlice";
import axiosInstance from "../utils/axiosUtil";
import {
  AiFillCar,
  AiFillInfoCircle,
  AiFillSetting,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import { IoArrowForwardSharp, IoSpeedometerSharp } from "react-icons/io5";
import { TbGps } from "react-icons/tb";
import { MdAirlineSeatReclineNormal } from "react-icons/md";
import { motion } from "framer-motion";
import { setAvailableCars } from "../components/features/availableCarsSlice";
import CommonSection from "../components/UI/CommonSection";

const CarDetails = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [selectedImg, setSelectedImg] = useState("");
  const { token } = useSelector((state) => state.auth);
  const { carDetails, loadingCarDetails, carDetailsErr } = useSelector(
    (state) => state.carDetails
  );

  useEffect(() => {
    dispatch(setAvailableCars());
  }, []);

  // const singleCarItem = carData.find((item) => item.carName === slug);

  useEffect(() => {
    const fetchDetails = async () => {
      dispatch(carDetailsStart());
      try {
        const { data } = await axiosInstance.get(
          `/api/car/find-car/${params.id}`,
          { headers: { Authorization: token } }
        );

        dispatch(carDetailsSuccess(data.car));
        setSelectedImg(data.car?.images[0]);
      } catch (error) {
        dispatch(carDetailsFailure(error?.response?.data?.message));
      }
    };

    fetchDetails();
  }, []);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, [singleCarItem]);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <Helmet title={"Christchurch Car Rental - " + carDetails?.name} />
        <ReactPlaceholder
          type="text"
          color="#F0F0F0"
          showLoadingAnimation
          rows={1}
          // delay={200}
          ready={!loadingCarDetails}
        >
          <CommonSection title={`Car details - ${carDetails?.name}`} />
        </ReactPlaceholder>
        <section>
          <Container>
            <ReactPlaceholder
              type="text"
              color="#F0F0F0"
              showLoadingAnimation
              rows={10}
              // delay={200}
              ready={!loadingCarDetails}
            >
              <Row className="justify-content-between mt-4">
                <Col lg="5">
                  <ReactImageMagnify
                    {...{
                      smallImage: {
                        alt: "image",
                        isFluidWidth: true,
                        src: selectedImg,
                      },
                      largeImage: {
                        src: selectedImg,
                        width: 1000,
                        height: 1000,
                      },
                    }}
                    enlargedImagePosition="over"
                    isHintEnabled
                  />
                  {/* <ThreeSixty
                      amount={1}
                      imagePath={carDetails?.image}
                      fileName="chair_{index}.jpg?v1"
                    /> */}
                  {/* <ReactPhotoSphereViewer
                      src={carDetails?.image}
                      height={"100vh"}
                      littlePlanet={false}
                      width={"100%"}
                    ></ReactPhotoSphereViewer> */}
                  <div
                    style={{
                      textAlign: "center",
                      marginTop: "0.5rem",
                      color: "#868686",
                    }}
                  >
                    Click any of the images to view!
                  </div>
                  <Row className="m-0 mt-2">
                    {carDetails?.images?.length > 1 ? (
                      carDetails?.images?.map((i) => (
                        <Col
                          xs={6}
                          sm={3}
                          lg={4}
                          md={5}
                          // xl={2}
                          className="px-0 justify-content-center align-items-center"
                          // style={{ backgroundColor: "#00000026" }}
                          key={i}
                        >
                          <>
                            {/* <CardImg imgPath={featured1} /> */}
                            <img
                              src={i}
                              className="car-details-images"
                              height={"100px"}
                              alt={carDetails?.name}
                              onClick={() => setSelectedImg(i)}
                            />
                          </>
                        </Col>
                      ))
                    ) : (
                      <div style={{ padding: "1rem" }}>
                        <p>No image</p>
                      </div>
                    )}
                  </Row>
                </Col>

                <Col lg="6">
                  <Row>
                    <div className="car__info">
                      <Col xs={"auto"}>
                        <h2 className="section__title">{carDetails?.name}</h2>
                      </Col>

                      <Col xs={8}>
                        <div className="gap-5 mb-4 mt-3 car-details-priceRating">
                        
                        <div style={{ display:"flex", justifyContent:"space-between", alignContent:"center", alignItems:"center", width:"100%"}}>
                          <h6 className="rent__price fw-bold fs-4">
                            <span className="car-details-price">
                              ${carDetails?.price?.toFixed(2)}
                            </span>
                            <span>/day</span>
                          </h6>
                          
                <Button
                  onClick={() => {
                    if (location.state?.fromHome) {
                      navigate(`/book-car/${carDetails?._id}`, {
                        state: { fromHome: true },
                      });
                    } else {
                      navigate(`/book-car/${carDetails?._id}`);
                    }
                  }}
                >
                  Book Now <IoArrowForwardSharp className="book-car-icon" />
                </Button>
            
                          </div>
                          {carDetails?.rating && (
                            <div className="align-items-center">
                              <span
                                style={{
                                  color: "#000",
                                  marginRight: "0.5rem",
                                }}
                              >
                                <i class="ri-star-s-fill"></i>
                                <i class="ri-star-s-fill"></i>
                                <i class="ri-star-s-fill"></i>
                                <i class="ri-star-s-fill"></i>
                                <i class="ri-star-s-fill"></i>
                              </span>
                              ({carDetails?.rating} ratings)
                            </div>
                          )}
                        </div>
                      </Col>

                      <div className="mt-4">
                        <p>
                          <h5>
                            <strong>Description</strong>
                          </h5>
                        </p>
                        <p className="section__description">
                          {carDetails?.details}
                        </p>
                      </div>

                      <div
                        className=" d-flex align-items-center mt-3"
                        // style={{ columnGap: "4rem" }}
                      >
                        <Col>
                          <span className=" gap-1 section__description car-details-span">
                            <div className="d-flex align-items-center">
                              <AiFillCar
                                style={{
                                  color: "#000",
                                  marginRight: "0.5rem",
                                }}
                              />
                              <strong style={{ color: "#000" }}>Model</strong>{" "}
                            </div>

                            <strong
                              style={{
                                color: "#7a7a87",
                                marginLeft: "0.5rem",
                              }}
                            >
                              {carDetails?.model}
                            </strong>
                          </span>
                        </Col>

                        <Col>
                          <span className="  gap-1 section__description car-details-span">
                            <div className="d-flex align-items-center">
                              <AiFillSetting
                                style={{
                                  color: "#000",
                                  marginRight: "0.5rem",
                                }}
                              />
                              <strong style={{ color: "#000" }}>
                                Automatic
                              </strong>{" "}
                            </div>

                            <strong
                              style={{
                                color: "#7a7a87",
                                marginLeft: "0.5rem",
                              }}
                            >
                              {carDetails?.automatic}
                            </strong>
                          </span>
                        </Col>

                        <Col>
                          <span className=" gap-1 section__description car-details-span">
                            <div className="d-flex align-items-center">
                              <IoSpeedometerSharp
                                style={{
                                  color: "#000",
                                  marginRight: "0.5rem",
                                }}
                              />
                              <strong style={{ color: "#000" }}>Speed</strong>{" "}
                            </div>
                            <strong
                              style={{
                                color: "#7a7a87",
                                marginLeft: "0.5rem",
                              }}
                            >
                              {carDetails?.speed}
                            </strong>
                          </span>
                        </Col>
                      </div>

                      <div
                        className=" d-flex align-items-center mt-3"
                        // style={{ columnGap: "2.8rem" }}
                      >
                        <Col>
                          <span className=" gap-1 section__description car-details-span">
                            <div className="d-flex align-items-center">
                              <TbGps
                                style={{
                                  color: "#000",
                                  marginRight: "0.5rem",
                                }}
                              />
                              <strong
                                style={{
                                  color: "#000",
                                }}
                              >
                                GPS
                              </strong>
                            </div>
                            <strong style={{ color: "#7a7a87" }}>
                              {carDetails?.gps}
                            </strong>
                          </span>
                        </Col>

                        <Col>
                          <span className=" gap-1 section__description car-details-span">
                            <div className="d-flex align-items-center">
                              <MdAirlineSeatReclineNormal
                                style={{
                                  color: "#000",
                                  marginRight: "0.5rem",
                                }}
                              />
                              <strong style={{ color: "#000" }}>
                                No. of seat(s)
                              </strong>
                            </div>
                            <strong
                              style={{
                                color: "#7a7a87",
                                marginLeft: "0.5rem",
                              }}
                            >
                              {carDetails?.noOfSeat}
                            </strong>
                          </span>
                        </Col>

                        <Col>
                          <span className="  gap-1 section__description car-details-span">
                            <div className="d-flex align-items-center">
                              <AiFillInfoCircle
                                style={{
                                  color: "#000",
                                  marginRight: "0.5rem",
                                }}
                              />
                              <strong style={{ color: "#000" }}>Brand</strong>
                            </div>
                            <strong
                              style={{
                                color: "#7a7a87",
                                marginLeft: "0.5rem",
                              }}
                            >
                              {carDetails?.brand}
                            </strong>
                          </span>
                        </Col>
                      </div>
                    </div>
                  </Row>
                </Col>

                {/* <Col lg="7" className="mt-5">
                <div className="booking-info mt-5">
                  <h5 className="mb-4 fw-bold ">Booking Information</h5>
                  <BookingForm />
                </div>
              </Col> */}

                {/* <Col lg="5" className="mt-5">
              <div className="payment__info mt-5">
                <h5 className="mb-4 fw-bold ">Payment Information</h5>
                <PaymentMethod />
              </div>
            </Col> */}

                <div className="mt-4">
                  <p>
                    <h3>
                      <strong>Features</strong>
                    </h3>
                  </p>
                  {carDetails?.features?.map((feature) => (
                    <>
                      <p className="">
                        <AiOutlineCheckCircle
                          color="#000"
                          style={{ marginRight: "0.5rem" }}
                        />
                        {feature?.name}
                      </p>
                      <p style={{ color: "#868686", marginLeft: "0.5rem" }}>
                        {feature?.description}
                      </p>
                    </>
                  ))}
                </div>

                <div className="mt-4">
                  <p>
                    <h3>
                      <strong>Benefits</strong>
                    </h3>
                  </p>
                  {carDetails?.benefits?.map((benefit) => (
                    <>
                      <p className="">
                        <AiOutlineCheckCircle
                          color="#000"
                          style={{ marginRight: "0.5rem" }}
                        />
                        {benefit?.name}
                      </p>
                      <p style={{ color: "#868686", marginLeft: "0.5rem" }}>
                        {benefit?.description}
                      </p>
                    </>
                  ))}
                </div>
              </Row>
              <div className="car-details-bookbtn">
                <Button
                  onClick={() => {
                    if (location.state?.fromHome) {
                      navigate(`/book-car/${carDetails?._id}`, {
                        state: { fromHome: true },
                      });
                    } else {
                      navigate(`/book-car/${carDetails?._id}`);
                    }
                  }}
                >
                  Book car <IoArrowForwardSharp className="book-car-icon" />
                </Button>
              </div>
            </ReactPlaceholder>
          </Container>
        </section>
      </motion.div>
    </>
  );
};

export default CarDetails;
