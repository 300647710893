import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  fetching: false,
  errorFetching: "",
  carDetails: {
    _id: localStorage.getItem("carId"),
    carName: localStorage.getItem("carName"),
    brand:
      localStorage.getItem("brand") === "null"
        ? null
        : localStorage.getItem("brand"),
    details: localStorage.getItem("details"),
    price: parseInt(localStorage.getItem("price")),
    discount: parseFloat(localStorage.getItem("discount")),
    image: localStorage.getItem("image"),
    model: localStorage.getItem("model"),
    noOfSeat: localStorage.getItem("noOfSeat"),
    rating: localStorage.getItem("rating"),
    speed: localStorage.getItem("speed"),
    gps: localStorage.getItem("gps"),
    seatType: localStorage.getItem("seatType"),
    automatic: localStorage.getItem("automatic"),
  },
  acceptedTermsCondi:
    localStorage.getItem("acceptedTermsCondi") === "true" ? true : false,
  insuranceSelected:
    localStorage.getItem("insuranceSelected") === "true" ? true : false,
  userDetails: {
    // firstname: localStorage.getItem(),
    // lastname: localStorage.getItem(),
    // email: localStorage.getItem(),
    // mobile_no: localStorage.getItem(),
    userAge: parseInt(localStorage.getItem("userAge")),
    fromAddress: localStorage.getItem("fromAddress"),
    toAddress: localStorage.getItem("toAddress"),
    person: localStorage.getItem("person"),
    luggage: localStorage.getItem("luggage"),
    journeyDate: localStorage.getItem("journeyDate"),
    journeyEndDate: localStorage.getItem("journeyEndDate"),
    journeyTime: localStorage.getItem("journeyTime"),
    journeyEndTime: localStorage.getItem("journeyEndTime"),
    insurance: localStorage.getItem("insurance"),
    insuranceType: localStorage.getItem("insuranceType"),
  },
  total: parseFloat(localStorage.getItem("total")) || 0,
};

const bookingCarSlice = createSlice({
  name: "bookingCar",
  initialState,
  reducers: {
    bookingCarStart: (state, action) => {
      state.fetching = true;
      state.errorFetching = "";
    },
    bookingCarFetched: (state, action) => {
      state.fetching = false;
      state.errorFetching = "";

      state.total = parseFloat(action.payload);
      localStorage.setItem("total", parseFloat(state.total));
    },
    bookingCarFail: (state, action) => {
      state.fetching = false;
      state.errorFetching = action.payload;
    },

    bookingCarSuccess: (state, action) => {
      state.carDetails._id = action.payload.carId || state.carDetails.carId;
      localStorage.setItem("carId", state.carDetails._id);

      state.carDetails.carName =
        action.payload.carName || state.carDetails.carName;
      localStorage.setItem(
        "carName",
        action.payload.carName || state.carDetails.carName
      );

      state.carDetails.brand = action.payload.brand || state.carDetails.brand;
      localStorage.setItem(
        "brand",
        action.payload.brand || state.carDetails.brand
      );

      state.carDetails.automatic =
        action.payload.automatic || state.carDetails.automatic;
      localStorage.setItem(
        "automatic",
        action.payload.automatic || state.carDetails.automatic
      );

      state.carDetails.details =
        action.payload.details || state.carDetails.details;
      localStorage.setItem(
        "details",
        action.payload.details || state.carDetails.details
      );

      state.carDetails.gps = action.payload.gps || state.carDetails.gps;
      localStorage.setItem("gps", action.payload.gps || state.carDetails.gps);

      state.carDetails.image = action.payload.image || state.carDetails.image;
      localStorage.setItem(
        "image",
        action.payload.image || state.carDetails.image
      );

      state.carDetails.model = action.payload.model || state.carDetails.model;
      localStorage.setItem(
        "model",
        action.payload.model || state.carDetails.model
      );

      state.carDetails.price = action.payload.price || state.carDetails.price;
      localStorage.setItem(
        "price",
        action.payload.price || state.carDetails.price
      );

      state.carDetails.discount =
        action.payload.discount || state.carDetails.discount;
      localStorage.setItem(
        "discount",
        action.payload.discount || state.carDetails.discount
      );

      state.carDetails.rating =
        action.payload.rating || state.carDetails.rating;
      localStorage.setItem(
        "rating",
        action.payload.rating || state.carDetails.rating
      );

      state.carDetails.seatType =
        action.payload.seatType || state.carDetails.seatType;
      localStorage.setItem(
        "seatType",
        action.payload.seatType || state.carDetails.seatType
      );

      state.carDetails.speed = action.payload.speed || state.carDetails.speed;
      localStorage.setItem(
        "speed",
        action.payload.speed || state.carDetails.speed
      );

      state.carDetails.noOfSeat =
        action.payload.noOfSeat || state.carDetails.noOfSeat;
      localStorage.setItem(
        "noOfSeat",
        action.payload.noOfSeat || state.carDetails.noOfSeat
      );

      // state.userDetails.firstname =
      //   action.payload.firstname || state.userDetails.firstname;
      // state.userDetails.lastname =
      //   action.payload.lastname || state.userDetails.lastname;
      // state.userDetails.mobile_no =
      //   action.payload.mobile_no || state.userDetails.mobile_no;
      // state.userDetails.email = action.payload.email || state.userDetails.email;
      state.userDetails.userAge =
        action.payload.userAge || state.userDetails.userAge;
      localStorage.setItem(
        "userAge",
        action.payload.userAge || state.carDetails.userAge
      );
      state.userDetails.person =
        action.payload.person || state.userDetails.person;
      localStorage.setItem(
        "person",
        action.payload.person || state.carDetails.person
      );

      state.userDetails.luggage =
        action.payload.luggage || state.userDetails.luggage;
      localStorage.setItem(
        "luggage",
        action.payload.luggage || state.carDetails.luggage
      );

      state.userDetails.fromAddress =
        action.payload.fromAddress || state.userDetails.fromAddress;
      localStorage.setItem(
        "fromAddress",
        action.payload.fromAddress || state.carDetails.fromAddress
      );

      state.userDetails.toAddress =
        action.payload.toAddress || state.userDetails.toAddress;
      localStorage.setItem(
        "toAddress",
        action.payload.toAddress || state.carDetails.toAddress
      );
      state.userDetails.insurance =
        action.payload.insurance === 0 || action.payload.insurance
          ? action.payload.insurance
          : state.userDetails.insurance;
      localStorage.setItem(
        "insurance",
        action.payload.insurance === 0 || action.payload.insurance
          ? action.payload.insurance
          : state.carDetails.insurance
      );
      state.userDetails.insuranceType =
        action.payload.insuranceType || state.userDetails.insuranceType;
      localStorage.setItem(
        "insuranceType",
        action.payload.insuranceType || state.carDetails.insuranceType
      );
      state.userDetails.journeyDate =
        action.payload.journeyDate || state.userDetails.journeyDate;
      localStorage.setItem(
        "journeyDate",
        action.payload.journeyDate || state.carDetails.journeyDate
      );

      state.userDetails.journeyEndDate =
        action.payload.journeyEndDate || state.userDetails.journeyEndDate;
      localStorage.setItem(
        "journeyEndDate",
        action.payload.journeyEndDate || state.carDetails.journeyEndDate
      );

      state.userDetails.journeyTime =
        action.payload.journeyTime || state.userDetails.journeyTime;
      localStorage.setItem(
        "journeyTime",
        action.payload.journeyTime || state.carDetails.journeyTime
      );

      state.userDetails.journeyEndTime =
        action.payload.journeyEndTime || state.userDetails.journeyEndTime;
      localStorage.setItem(
        "journeyEndTime",
        action.payload.journeyEndTime || state.carDetails.journeyEndTime
      );
    },

    termsCondi: (state, action) => {
      state.acceptedTermsCondi = action.payload || state.acceptedTermsCondi;
      localStorage.setItem(
        "acceptedTermsCondi",
        Boolean(state.acceptedTermsCondi)
      );
    },

    insuranceSelect: (state, action) => {
      state.insuranceSelected = action.payload;
      localStorage.setItem(
        "insuranceSelected",
        Boolean(state.insuranceSelected)
      );
    },

    orderTotal: (state, action) => {
      state.total = action.payload || state.total;
      localStorage.setItem("total", state.total);
    },

    setBookingCar: (state, action) => {
      state.userDetails.fromAddress = null;
      state.userDetails.toAddress = null;
      state.userDetails.journeyDate = null;
      state.userDetails.journeyEndDate = null;
      state.userDetails.journeyTime = null;
      state.userDetails.journeyEndTime = null;
      state.userDetails.luggage = null;
      state.userDetails.person = null;
      state.userDetails.toAddress = null;
      state.userDetails.userAge = null;

      state.carDetails._id = null;
      state.carDetails.automatic = null;
      state.carDetails.brand = null;
      state.carDetails.carName = null;
      state.carDetails.details = null;
      state.carDetails.gps = null;
      state.carDetails.image = null;
      state.carDetails.model = null;
      state.carDetails.noOfSeat = null;
      state.carDetails.price = null;
      state.carDetails.discount = null;
      state.carDetails.rating = null;
      state.carDetails.seatType = null;
      state.carDetails.speed = null;
      state.insuranceSelected = false;
      state.acceptedTermsCondi = false;
      state.total = 0;

      localStorage.removeItem("carId");
      localStorage.removeItem("carName");
      localStorage.removeItem("brand");
      localStorage.removeItem("details");
      localStorage.removeItem("price");
      localStorage.removeItem("discount");
      localStorage.removeItem("image");
      localStorage.removeItem("model");
      localStorage.removeItem("noOfSeat");
      localStorage.removeItem("rating");
      localStorage.removeItem("speed");
      localStorage.removeItem("gps");
      localStorage.removeItem("seatType");
      localStorage.removeItem("automatic");
      localStorage.removeItem("acceptedTermsCondi");
      localStorage.removeItem("insuranceSelected");
      localStorage.removeItem("userAge");
      localStorage.removeItem("fromAddress");
      localStorage.removeItem("toAddress");
      localStorage.removeItem("person");
      localStorage.removeItem("luggage");
      localStorage.removeItem("journeyDate");
      localStorage.removeItem("journeyEndDate");
      localStorage.removeItem("journeyTime");
      localStorage.removeItem("journeyEndTime");
      localStorage.removeItem("total");
      localStorage.removeItem("insurance");
      localStorage.removeItem("insuranceType");
      localStorage.removeItem("journeyDays");
    },
  },
});

export const {
  bookingCarSuccess,
  setBookingCar,
  termsCondi,
  insuranceSelect,
  orderTotal,
  bookingCarFail,
  bookingCarFetched,
  bookingCarStart,
} = bookingCarSlice.actions;
export default bookingCarSlice.reducer;
