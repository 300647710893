import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

const faqQuestions = [
  {
    id: 1,
    question:
      "What is the minimum age to rent a luxury car from Christchurch Car Rental?",
    answer:
      "The minimum age for a luxury car hire in Christchurch is typically 25 years old. However, some vehicles may have different age requirements.",
  },
  {
    id: 2,
    question: "Do I need special insurance to rent a luxury car?",
    answer:
      "Christchurch Car Rental provides comprehensive insurance, but additional coverage options are available for added peace of mind.",
  },
  {
    id: 3,
    question: "Can I rent a luxury car for a one-way trip?",
    answer:
      "Yes, Christchurch Car Rental offers one-way rentals, which can be convenient for those planning trips with different pick-up and drop-off locations.",
  },
  {
    id: 4,
    question: "Are there any mileage restrictions on rentals?",
    answer:
      "Most rentals come with unlimited mileage, allowing you to explore to your heart's content without worrying about extra charges.",
  },
  {
    id: 5,
    question: "What payment methods are accepted for luxury car rentals?",
    answer:
      "Christchurch Car Rental usually accepts major credit cards and debit cards for payments.",
  },
  {
    id: 6,
    question:
      "Do I need an international driver's licence to rent a luxury car as a foreign visitor?",
    answer:
      "We generally accept foreign driver's licences for tourists. It is essential to have your driver's license in English or an official translation/International Driving Permit (IDP).",
  },
  {
    id: 7,
    question: "Can I rent a luxury car for special events or occasions?",
    answer:
      "Yes, many customers choose luxury car hire in Christchurch for special events, such as weddings, anniversaries, or birthdays, to make the occasion even more memorable. Christchurch Car Rental can often accommodate such requests.",
  },
  {
    id: 8,
    question:
      "What should I do if I have an accident or damage the rental car?",
    answer:
      "In case of an accident or damage, it's crucial to contact us immediately to report the incident and follow our expert instructions on how to proceed. We will guide insurance coverage and repairs.",
  },
];

const formatText = (text) => {
  // Format text starting with a number as bold
  const formattedText = text.replace(/newLine/g, "<br /> <br />");

  return formattedText;
};

const FaqLuxury = () => {
  const [open, setOpen] = useState("1");

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  return (
    <>
      <Accordion open={open} toggle={toggle}>
        {faqQuestions?.map((faq) => (
          <AccordionItem key={faq?.id}>
            <AccordionHeader targetId={faq?.id}>
              <strong>
                <div className="d-flex align-items-center mt-2 faq-question">
                  <p style={{ marginRight: "1rem" }}>Q</p>
                  <p>{faq?.question}</p>
                </div>
              </strong>
            </AccordionHeader>
            <AccordionBody accordionId={faq?.id}>
              <div className="d-flex mt-2 faq-answer">
                <p style={{ marginRight: "1rem", fontWeight: "bold" }}>A</p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: formatText(faq?.answer),
                  }}
                ></p>
              </div>
            </AccordionBody>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};

export default FaqLuxury;
