import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

const faqQuestions = [
  {
    id: 1,
    question:
      "What documents do I need to rent a car at Christchurch Airport?",
    answer:
      "To rent a car, you'll need a valid driver's license and a credit card in your name. International travelers may also need an International Driving Permit (IDP) and their passport.",
  },
  {
    id: 2,
    question: "Can I return the rental car to a different location?",
    answer:
      "Yes, we offer one-way rentals, allowing you to pick up your car at Christchurch Airport and return it to a different location within New Zealand. Additional fees may apply, so please check with us for details.",
  },
  {
    id: 3,
    question: "Is insurance included in the rental price?",
    answer:
      "Basic insurance coverage is included in our rental rates, but you can choose to upgrade to additional coverage for added peace of mind. Our friendly staff will be happy to explain your options.",
  },
  {
    id: 4,
    question: "Do you offer child seats and other accessories?",
    answer:
      "Yes, we provide a range of accessories, including child seats, GPS navigation, and snow chains, to enhance your journey. Be sure to reserve these items when you book your car.",
  },
  {
    id: 5,
    question: "What happens in case of a breakdown or emergency?",
    answer:
      "Rest assured, our 24/7 roadside assistance team is always ready to help. We'll get you back on the road quickly, so you can continue your adventure with confidence.",
  },
];

const formatText = (text) => {
  // Format text starting with a number as bold
  const formattedText = text.replace(/newLine/g, "<br /> <br />");

  return formattedText;
};

const FaqAirport = () => {
  const [open, setOpen] = useState("1");

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  return (
    <>
      <Accordion open={open} toggle={toggle}>
        {faqQuestions?.map((faq) => (
          <AccordionItem key={faq?.id}>
            <AccordionHeader targetId={faq?.id}>
              <strong>
                <div className="d-flex align-items-center mt-2 faq-question">
                  <p style={{ marginRight: "1rem" }}>Q</p>
                  <p>{faq?.question}</p>
                </div>
              </strong>
            </AccordionHeader>
            <AccordionBody accordionId={faq?.id}>
              <div className="d-flex mt-2 faq-answer">
                <p style={{ marginRight: "1rem", fontWeight: "bold" }}>A</p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: formatText(faq?.answer),
                  }}
                ></p>
              </div>
            </AccordionBody>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};

export default FaqAirport;
