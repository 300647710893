import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: {},
  loadingGetProfile: false,
  getProfileErr: "",
};

const getProfileSlice = createSlice({
  name: "getProfile",
  initialState,
  reducers: {
    getProfileStart: (state, action) => {
      state.loadingGetProfile = true;
      state.getProfileErr = false;
    },
    getProfileSuccess: (state, action) => {
      state.getProfileErr = false;
      state.loadingGetProfile = false;
      state.userInfo = action.payload.user;
    },
    getProfileFailure: (state, action) => {
      state.loadingGetProfile = false;
      state.getProfileErr = true;
    },
  },
});

export const { getProfileFailure, getProfileStart, getProfileSuccess } =
  getProfileSlice.actions;
export default getProfileSlice.reducer;
