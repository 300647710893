import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

const faqQuestions = [
  {
    id: 6,
    question: "How do I book a car rental in Christchurch?",
    answer:
      "You can easily book your rental car through our website or by contacting our customer service team. Choose your vehicle, select your pick-up and drop-off dates, and complete your reservation.",
  },
  {
    id: 7,
    question: "What is the minimum age for renting a car?",
    answer:
      "The minimum age for renting a car is typically 21 years, but this may vary by vehicle type. Drivers under 25 may incur a young driver surcharge.",
  },
  {
    id: 8,
    question: "Do I need insurance to rent a car?",
    answer:
      "While insurance is not mandatory, we highly recommend it for your protection. We offer various insurance options that you can add to your rental agreement.",
  },
  {
    id: 9,
    question: " Can I return my rental car after hours?",
    answer:
      "Yes, we offer after-hours drop-off services at Christchurch airport for your convenience. Just let us know in advance.",
  },
  {
    id: 10,
    question:
      " Are there any additional fees for returning my car to a different location?",
    answer:
      "Yes, there may be additional fees for one-way rentals. Please check our website or contact us for specific details.",
  },
  {
    id: 1,
    question: "Do I need a credit card to book a rental car?",
    answer:
      "No, we offer flexible payment options, including debit cards and cash payments for your convenience. Please check our website for specific payment details.",
  },
  {
    id: 2,
    question: "What is the minimum age for renting a car?",
    answer:
      "The minimum age for renting a car with Car Rental Christchurch is 21 years old. Additional age-related fees may apply for drivers under 25. Be sure to check our terms and conditions for more information.",
  },
  {
    id: 3,
    question: "Can I make changes to my reservation?",
    answer:
      "Yes, you can make changes to your reservation by contacting our customer service team. We'll do our best to accommodate your needs, whether it's adjusting your rental period or upgrading your vehicle.",
  },
  {
    id: 4,
    question: "Do you offer insurance options?",
    answer:
      "Yes, we offer a range of insurance options to provide you with peace of mind during your rental period. Our team can help you choose the coverage that best suits your needs.",
  },
  {
    id: 5,
    question: "What happens if my rental car breaks down?",
    answer:
      "In the rare event of a breakdown, our 24/7 roadside assistance team is here to help. We'll get you back on the road as quickly as possible to minimize any disruptions to your plans.",
  },
];

const formatText = (text) => {
  // Format text starting with a number as bold
  const formattedText = text.replace(/newLine/g, "<br /> <br />");

  return formattedText;
};

const FaqHome = () => {
  const [open, setOpen] = useState("1");

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  return (
    <>
      <Accordion open={open} toggle={toggle}>
        {faqQuestions?.map((faq) => (
          <AccordionItem key={faq?.id}>
            <AccordionHeader targetId={faq?.id}>
              <strong>
                <div className="d-flex align-items-center mt-2 faq-question">
                  <p style={{ marginRight: "1rem" }}>Q</p>
                  <p>{faq?.question}</p>
                </div>
              </strong>
            </AccordionHeader>
            <AccordionBody accordionId={faq?.id}>
              <div className="d-flex mt-2 faq-answer">
                <p style={{ marginRight: "1rem", fontWeight: "bold" }}>A</p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: formatText(faq?.answer),
                  }}
                ></p>
              </div>
            </AccordionBody>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};

export default FaqHome;
