import React, { useState } from "react";
import { motion } from "framer-motion";
import Helmet from "../components/Helmet/HelmetComponent";
import CommonSection from "../components/UI/CommonSection";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Container,
} from "reactstrap";
import { Link } from "react-router-dom";
import HelmetComponent from "../components/Helmet/HelmetComponent";

const faqQuestions = [
  {
    id: 1,
    question: "What is the minimum age requirement for hiring a vehicle?",
    answer:
      "The minimum drivers age is 21 years and must have a full license with the correct class for the vehicle they wish to hire.",
  },
  {
    id: 2,
    question: "Do you require a bond to hire the vehicle?",
    answer:
      "Yes. We take either a signed credit card impression And  $100 cash.",
  },
  {
    id: 3,
    question: "Is there a charge for additional drivers?",
    answer:
      "No. So long as relevant details are added to the rental agreement form on pick up. You may add as many drivers as you wish for no extra charge. We prefer to give our customers the option of sharing the driving.",
  },
  {
    id: 4,
    question:
      "Do you provide a pickup and drop-off service from the airport or local accommodation?",
    answer:
      "Yes. We can arrange pickup and drop-off to or from Christchurch airport or local accommodation, so long as its within regular business hours. We would appreciate it if you gave us plenty of notice to prevent any delays in your travel plans.",
  },
  {
    id: 5,
    question: "Can I pay for the hire of the vehicle by credit card?",
    answer:
      "Yes. Car Rental Christchurch  - Accept both Visa and Mastercard. We no longer accept payment by cheque.",
  },
  {
    id: 6,
    question: "Do you offer after-hour car rental pickups and drop-offs?",
    answer:
      "Yes, subject to prior arrangement. For after-hour pickups, we require the rental agreement to be signed and prepaid in full at least 24 hours beforehand. We do not charge an additional administration fee for this.",
  },
  {
    id: 7,
    question:
      "Can we take our rental vehicle on the Inter-Island Ferry service?",
    answer:
      "Yes. Vehicles can be taken on the ferry but must be returned to our Christchurch branch.",
  },
  {
    id: 8,
    question: "Do you offer car seats?",
    answer: "No. Car seats will have to be provided by the hirer.",
  },
  {
    id: 9,
    question: "What is the procedure for extending the rental period?",
    answer:
      "Subject to vehicle availability, you can call us on 0220211997 Or Go online and Book And Process Payment  according to dates  to arrange an extension. ",
  },
  {
    id: 10,
    question: "What type of mechanical breakdown assistance is provided?",
    answer:
      "The phones switch to an after-hours number for emergency breakdowns. If there is no answer, leave a contact name and phone number. newLineRoadside assistance is available between 7am – 9pm.",
  },
  {
    id: 11,
    question: "Can I rent a vehicle on a Sunday?",
    answer:
      "We are open on Sunday. However, Please Give us Prior notice of booking or booking changes.",
  },
  {
    id: 12,
    question: "Can I return a vehicle after hours?",
    answer:
      "Yes. We have an after-hours drop off service. Please call on 0220211997 us if this applies to you and we can provide the necessary information.",
  },
  {
    id: 13,
    question:
      "Do you charge a cleaning fee if the vehicle is not returned clean?",
    answer:
      "Yes. A cleaning fee is applied if the vehicle is returned filthy and/or if rubbish is left behind.",
  },
  {
    id: 14,
    question: "Can I take vehicles on the beach?",
    answer:
      "No. Our vehicles are not insured for driving or parking on any beaches whatsoever.",
  },
  {
    id: 15,
    question: "Is fuel included with my rental?",
    answer:
      "Each vehicle goes out on hire with a full tank and must be returned with a full tank. If the vehicle is not refuelled, we charge you for what you have used, plus a $20 refuelling fee.",
  },
  {
    id: 16,
    question:
      "What happens if I need to cancel the booking or return my rental vehicle early?",
    answer:
      "Unfortunately, there are no refunds for early vehicle returns. If you wish to cancel your booking we require at least 48 hours notice. If you do not advise us 48 hours prior to your booking a $50 cancellation fee will apply.",
  },
  {
    id: 17,
    question: "What do I need to bring to the office when I collect a vehicle?",
    answer:
      "The driver and any additional drivers of the vehicle will need to provide their current full Drivers Licence relevant to the class of vehicle being hired. You will also need to provide a form of BOND. newLineThis is usually in the form of: newLineA credit card (which will need to be signed for by the holder) newLine$100 cash",
  },
  {
    id: 18,
    question:
      "What happens if I have an accident, break down or need assistance?",
    answer:
      "Please contact us straight away, so we can advise how we can assist you. We are available by phone in an emergency 24 hours a day.",
  },
  {
    id: 19,
    question: "What is the difference between daily, weekly and weekend rates?",
    answer:
      "Daily rates are based on 24 hours. Unlimited mileage rates are available for some vehicle types.",
  },
  {
    id: 20,
    question: "What is your insurance excess?",
    answer:
      "Our insurance excess is $1500 plus GST, Incase On And Damages and Accident , Will Charge to Full payment of access amount. newLineHowever, if you have addons insuarance if buy $35 is and addon days $10 will Reduce to aceess fees to $750  plus GST.",
  },
  {
    id: 21,
    question: "Can I use my overseas Drivers Licence?",
    answer:
      "Yes. However if your Driver’s Licence is not printed in English, you will need an International Drivers License.  newLineYou must be over 21 years of age to hire a rental car in New Zealand.",
  },
];

const formatText = (text) => {
  // Format text starting with a number as bold
  const formattedText = text.replace(/newLine/g, "<br /> <br />");

  return formattedText;
};

const FaqPage = () => {
  const [open, setOpen] = useState("1");

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <HelmetComponent
          title={"Get Informed with Our FAQs | Christchurch Car Rental"}
          metaDesc={
            "Get the information you need about car rentals in Christchurch. Check out our FAQs."
          }
        />
        <CommonSection title="FAQ - Frequently Asked Questions" />

        <Container className="my-3">
          <div className="faq-heading">
            <h4>
              Check this page to find the answers to your questions regarding
              our car rental services.
            </h4>
          </div>

          <Accordion open={open} toggle={toggle}>
            {faqQuestions?.map((faq) => (
              <AccordionItem key={faq?.id}>
                <AccordionHeader targetId={faq?.id}>
                  <strong>
                    <div className="d-flex align-items-center mt-2 faq-question">
                      <p style={{ marginRight: "1rem" }}>Q</p>
                      <p>{faq?.question}</p>
                    </div>
                  </strong>
                </AccordionHeader>
                <AccordionBody accordionId={faq?.id}>
                  <div className="d-flex mt-2 faq-answer">
                    <p style={{ marginRight: "1rem", fontWeight: "bold" }}>A</p>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: formatText(faq?.answer),
                      }}
                    ></p>
                  </div>
                </AccordionBody>
              </AccordionItem>
            ))}
          </Accordion>

          <div className="faq-heading my-4">
            <h4>
              <strong>Any Further Questions?</strong>
            </h4>

            <p>
              <>
                Please feel free to{" "}
                <Link to={"/contact"} className="faq-contact-link">
                  contact us
                </Link>{" "}
                if you have any more questions or enquiries about our rental
                service and will do our best to answer them.
              </>
            </p>
          </div>
        </Container>
      </motion.div>
    </>
  );
};

export default FaqPage;
