import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import { FaStar, FaStarHalfAlt } from "react-icons/fa";

const ReviewCard = ({ review }) => {
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;

    return (
      <>
        {Array(fullStars)
          .fill(0)
          .map((_, idx) => (
            <FaStar key={idx} style={{ color: "#ffc107" }} />
          ))}
        {halfStar && <FaStarHalfAlt style={{ color: "#ffc107" }} />}
      </>
    );
  };
  return (
    <Card style={{ width: "18rem" }} className="gap-2">
      <Card.Body>
        <div className="d-flex align-items-center mb-2">
          <div
            style={{
              backgroundColor: "orange",
              borderRadius: "50%",
              width: "40px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "white",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            {review.avatar}
          </div>
          <div className="ms-3">
            <Card.Title className="mb-0">{review.name}</Card.Title>
            <Card.Text className="text-muted">
              {review.reviewsCount} review{review.reviewsCount > 1 ? "s" : ""}
            </Card.Text>
          </div>
        </div>

        <div className="d-flex align-items-center mb-3">
          {/* Star Rating */}
          {renderStars(review.rating)}
          <span className="ms-2 text-muted">{review.timeAgo}</span>
        </div>

        <Card.Text>{review.reviewText}</Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ReviewCard;
