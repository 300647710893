import React from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button } from "reactstrap";

const AlertLayout = ({ title, body, btnTitle, navLink, color }) => {
  const navigate = useNavigate();

  return (
    <>
      <Alert color={color}>
        {/* <Alert color="primary"> */}
        <h4 className="alert-heading">{title}</h4>

        {body}
        {/* <p>
                          We hope you're doing well. It appears that you
                          currently don't have any car rental bookings in your
                          account. Whether you're still in the planning stages
                          or simply enjoying other means of transportation, know
                          that we're here to assist whenever you're ready to hit
                          the road.
                        </p>

                        <p>
                          Feel free to explore our range of vehicles and rental
                          options at your convenience. If you have any questions
                          or need assistance, please don't hesitate to reach
                          out. We're dedicated to helping you find the perfect
                          car for your next adventure.
                        </p> */}

        <hr />
        <Button
          className="booking-history-emtybtn"
          onClick={() => navigate(navLink)}
          //   onClick={() => navigate("/cars")}
        >
          {btnTitle}
          {/* Reserve now */}
        </Button>
      </Alert>
    </>
  );
};

export default AlertLayout;
