import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./authSlice";
import getProfileReducer from "./getProfileSlice";
// import orderReducer from "./orderSlice";
import bookingHistoryReducer from "./bookingHistorySlice";
import carBookingHistoryReducer from "./bookedCarHistorySlice";
import availableCarsReducer from "./availableCarsSlice";
import carDetailsReducer from "./getCarSlice";
import allCarsReducer from "./allCarsSlice";
import bookingCarReducer from "./carBookingSlice";
import findCarReducer from "./findCarSlice";
import getLocationReducer from "./getLocationSlice";
import commonReducer from "./commonSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    getProfile: getProfileReducer,
    bookingHistory: bookingHistoryReducer,
    findCar: findCarReducer,
    carBookingHistory: carBookingHistoryReducer,
    availableCars: availableCarsReducer,
    carDetails: carDetailsReducer,
    allCars: allCarsReducer,
    bookingCar: bookingCarReducer,
    getLocation: getLocationReducer,
    common: commonReducer,
  },
});
