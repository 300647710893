/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { FaStripe } from "react-icons/fa";
import { loadStripe } from "@stripe/stripe-js";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axiosInstance from "../../utils/axiosUtil";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
function getBaseAddress() {
  return window.location.protocol + "//" + window.location.host;
}
const PaymentStripe = ({
  amt,
  loading,
  setLoading,
  insuranceSelected,
  createOrder,
  userDetails,
  carDetails,
}) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [id, setId] = useState(localStorage.getItem("orderID"));
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loader, setloader] = useState(false);
  const [formdata, setFormdata] = useState({
    email: "",
    phone: 0,
    name: "",
  });
  console.log( amt,
    loading,
    setLoading,
    insuranceSelected,
    createOrder,
    userDetails,
    carDetails);
  const toastOptions = {
    position: "bottom-center",
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };
  const handlePayment = async () => {
    setLoading(true);
    await createOrder();
    const stripe = await loadStripe(process.env.REACT_APP_PUB_KEY);
    const body = {
      products: [
        {
          dish: "Service Charge",
          price: amt,
          qnty: "1",
        },
      ],
      customerEmail: localStorage.getItem("email") || formdata.email,
      customerName: localStorage.getItem("name") || formdata.name,
      customerPhone: localStorage.getItem("phone") || formdata.number,
      base: getBaseAddress(),
    };
    try {
      const response = await axiosInstance.post("/api/booking/checkout", body);
      const session = await response.data;
  
      setId(session.id);
      localStorage.setItem("orderID", session.id);
      const result = stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.log(result.error);
      }
    } catch (e) {
      toast.error("Something went wrong. Please try again!", toastOptions);
      setLoading(false);
      setloader(false);
    }
  };
  const onApprove = async (data) => {
    console.log(data);
    try {
      setLoading(true);
      const { data: onApproveData } = await axiosInstance.post(
        `/api/booking/capture-payment-stripe/${data}`,
        {
          startDate: userDetails?.journeyDate,
          endDate: userDetails?.journeyEndDate,
          startTime: userDetails?.journeyTime,
          endTime: userDetails?.journeyEndTime,
          carId: carDetails?._id,
          insurance: insuranceSelected,
          person: userDetails?.person,
          luggage: userDetails?.luggage,
          fromAddress: userDetails?.fromAddress,
          toAddress: userDetails?.toAddress,
          discount: carDetails?.discount,
          name: localStorage.getItem("name"),
          email: localStorage.getItem("email"),
          phone: localStorage.getItem("phone"),
        }
      );

      if (onApproveData.paymentCaptured.status === "COMPLETED") {
        window.scroll(0, 0);
        setLoading(false);
        setId(null);
        localStorage.removeItem("orderID");
        navigate("/", { replace: true });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong. Please try again!", toastOptions);
    }
  };
  useEffect(() => {
    if (id != null) {
      onApprove(id);
    } else {
      if (
        !localStorage.getItem("email") ||
        !localStorage.getItem("name") ||
        !localStorage.getItem("phone")
      )
        handleShow();
    }
  }, []);

  return (
    <>
      {" "}
      {window.location.pathname !== "/success" && (
        <>
          {" "}
          <button
            disabled={loader}
            onClick={() => {
              setLoading(true);
              setloader(true);
              handlePayment();
            }}
            className="text-white w-100 gap-2 rounded border-0 d-flex justify-content-center align-items-center stripe-button"
            style={{ height: "45px" }}
          >
            <FaStripe style={{ fontSize: "40px", color: "white" }} />
          </button>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Personal Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    autoFocus
                    value={formdata?.email}
                    onChange={(e) => {
                      setFormdata({ ...formdata, email: e.target.value });
                    }}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Your Name</Form.Label>
                  <Form.Control
                    type="text"
                    autoFocus
                    value={formdata?.name}
                    onChange={(e) => {
                      setFormdata({ ...formdata, name: e.target.value });
                    }}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Your Mobile Number</Form.Label>
                  <Form.Control
                    type="number"
                    autoFocus
                    value={formdata?.number}
                    onChange={(e) => {
                      setFormdata({ ...formdata, number: e.target.value });
                    }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
          <ToastContainer />
        </>
      )}
    </>
  );
};

export default PaymentStripe;
