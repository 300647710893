import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pickupLocations: [],
  dropOffLocations: [],
  loadingLocation: false,
  locationErr: "",
};

const getLocationSlice = createSlice({
  name: "getLocation",
  initialState,
  reducers: {
    getLocationStart: (state, action) => {
      state.loadingLocation = true;
      state.locationErr = "";
    },
    getLocationSuccess: (state, action) => {
      state.locationErr = "";
      state.loadingLocation = false;
      state.pickupLocations = action.payload.pickupLocations;
      state.dropOffLocations = action.payload.dropOffLocations;
    },
    getLocationFailure: (state, action) => {
      state.loadingLocation = false;
      state.locationErr = action.payload;
    },
  },
});

export const { getLocationStart, getLocationSuccess, getLocationFailure } =
  getLocationSlice.actions;
export default getLocationSlice.reducer;
