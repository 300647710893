import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  findCar: JSON.parse(localStorage.getItem("findCar")) || {},
};

const findCarSlice = createSlice({
  name: "findCar",
  initialState,
  reducers: {
    findCarSuccess: (state, action) => {
      state.findCar = action.payload;

      localStorage.setItem("findCar", JSON.stringify(action.payload));
    },
    setFindCar: (state, action) => {
      state.findCar = {};

      localStorage.removeItem("findCar");
    },
  },
});

export const { findCarSuccess, setFindCar } = findCarSlice.actions;
export default findCarSlice.reducer;
