import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import PaymentMethod from "../components/UI/PaymentMethod";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { BiCalendar, BiTimeFive } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
import {
  MdAirlineSeatReclineNormal,
  MdCancel,
  MdOutlineLuggage,
} from "react-icons/md";
import { GrUserManager } from "react-icons/gr";
import { FaLocationDot } from "react-icons/fa6";
import { orderTotal } from "../components/features/carBookingSlice";
import {
  AiFillCar,
  AiFillCheckCircle,
  AiFillInfoCircle,
  AiFillSetting,
} from "react-icons/ai";
import { IoSpeedometerSharp } from "react-icons/io5";
import { motion } from "framer-motion";
import { TbGps } from "react-icons/tb";
import { setAvailableCars } from "../components/features/availableCarsSlice";
import Helmet from "../components/Helmet/HelmetComponent";
import CommonSection from "../components/UI/CommonSection";
import { useNavigate } from "react-router-dom";

const Order = () => {
  const { carDetails, userDetails, insuranceSelected } = useSelector(
    (state) => state.bookingCar
  );
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if(!localStorage.getItem("carId"))
    navigate("/luxury-car");
    dispatch(setAvailableCars());
  }, []);

  return (
    <>
      {localStorage.getItem("carId")?<motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <Helmet title={"Christchurch Car Rental - Booking details"} />
        <CommonSection title={`Booking Details`} />
        <Container className="mb-4">
          <Row className="justify-content-between">
            <Col lg="7" className="mt-4">
              <div className="">
                <h3 className="mb-4" style={{ fontWeight: 600 }}>
                  Car details
                </h3>

                <Col lg="6">
                  <img src={carDetails?.image[0]} alt="" className="w-100" />
                </Col>

                <Col xs={8}>
                  <div className="gap-5 mb-4 mt-3 car-details-priceRating">
                    <h6 className="rent__price fw-bold fs-4">
                      <span className="car-details-price">
                        ${carDetails?.price?.toFixed(2)}
                      </span>
                      <span>/day</span>
                    </h6>

                    {/* {carDetails?.rating && (
                      <div className="align-items-center">
                        <span style={{ color: "#000", marginRight: "0.5rem" }}>
                          <i class="ri-star-s-fill"></i>
                          <i class="ri-star-s-fill"></i>
                          <i class="ri-star-s-fill"></i>
                          <i class="ri-star-s-fill"></i>
                          <i class="ri-star-s-fill"></i>
                        </span>
                        ({carDetails?.rating} ratings)
                      </div>
                    )} */}
                  </div>
                </Col>

                <strong>Description</strong>
                <p className="section__description">{carDetails?.details}</p>

                <div
                  className=" d-flex align-items-center mt-3"
                  // style={{ columnGap: "4rem" }}
                >
                  <Col>
                    <span className=" gap-1 section__description car-details-span">
                      <div className="d-flex align-items-center">
                        <AiFillCar
                          style={{
                            color: "#000",
                            marginRight: "0.5rem",
                          }}
                        />
                        <strong style={{ color: "#000" }}>Model</strong>{" "}
                      </div>

                      <strong
                        style={{ color: "#7a7a87", marginLeft: "0.5rem" }}
                      >
                        {carDetails?.model}
                      </strong>
                    </span>
                  </Col>

                  <Col>
                    <span className=" gap-1 section__description car-details-span">
                      <div className="d-flex align-items-center">
                        <AiFillSetting
                          style={{
                            color: "#000",
                            marginRight: "0.5rem",
                          }}
                        />
                        <strong style={{ color: "#000" }}>Automatic</strong>{" "}
                      </div>

                      <strong
                        style={{ color: "#7a7a87", marginLeft: "0.5rem" }}
                      >
                        {carDetails?.automatic}
                      </strong>
                    </span>
                  </Col>

                  <Col>
                    <span className=" gap-1 section__description car-details-span">
                      <div className="d-flex align-items-center">
                        <IoSpeedometerSharp
                          style={{
                            color: "#000",
                            marginRight: "0.5rem",
                          }}
                        />
                        <strong style={{ color: "#000" }}>Speed</strong>{" "}
                      </div>
                      <strong
                        style={{ color: "#7a7a87", marginLeft: "0.5rem" }}
                      >
                        {carDetails?.speed}
                      </strong>
                    </span>
                  </Col>
                </div>

                <div
                  className=" d-flex align-items-center mt-3"
                  // style={{ columnGap: "2.8rem" }}
                >
                  <Col>
                    <span className="  gap-1 section__description car-details-span">
                      <div className="d-flex align-items-center">
                        <TbGps
                          style={{
                            color: "#000",
                            marginRight: "0.5rem",
                          }}
                        />
                        <strong style={{ color: "#000" }}>GPS</strong>
                      </div>
                      <strong
                        style={{ color: "#7a7a87", marginLeft: "0.5rem" }}
                      >
                        {carDetails?.gps}
                      </strong>
                    </span>
                  </Col>

                  <Col>
                    <span className="  gap-1 section__description car-details-span">
                      <div className="d-flex align-items-center">
                        <MdAirlineSeatReclineNormal
                          style={{
                            color: "#000",
                            marginRight: "0.5rem",
                          }}
                        />
                        <strong style={{ color: "#000" }}>
                          No. of seat(s)
                        </strong>
                      </div>
                      <strong
                        style={{ color: "#7a7a87", marginLeft: "0.5rem" }}
                      >
                        {carDetails?.noOfSeat}
                      </strong>
                    </span>
                  </Col>

                  <Col>
                    <span className="  gap-1 section__description car-details-span">
                      <div className="d-flex align-items-center">
                        <AiFillInfoCircle
                          style={{
                            color: "#000",
                            marginRight: "0.5rem",
                          }}
                        />
                        <strong style={{ color: "#000" }}>Brand</strong>
                      </div>
                      <strong
                        style={{ color: "#7a7a87", marginLeft: "0.5rem" }}
                      >
                        {carDetails?.brand}
                      </strong>
                    </span>
                  </Col>
                </div>
              </div>

              <div>{/* // add user info here */}</div>
            </Col>

            <Col lg="4" className="mt-5">
              <div className=" mt-5">
                <h5 className="mb-4 fw-bold ">Payment Information</h5>
                <PaymentMethod />
              </div>
            </Col>
          </Row>

          <div className="mt-4 order-page-details">
            <div>
              <Row>
                <Col md="3">
                  <div className="order-page-pickup-cont">
                    <p className="order-page-title">Pick-up</p>
                    <div className="d-flex align-items-center">
                      {/* From - {userDetails?.journeyDate} */}
                      <p>
                        <FaLocationDot style={{ marginRight: "0.5rem" }} />
                      </p>
                      <p>{userDetails?.fromAddress}</p>
                    </div>
                    <div className="d-flex align-items-center">
                      {/* From - {userDetails?.journeyDate} */}
                      <p>
                        <BiCalendar style={{ marginRight: "0.5rem" }} />
                      </p>
                      <p>
                        {format(
                          new Date(userDetails?.journeyDate),
                          "MMMM dd, yyyy"
                        )}
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <p>
                        <BiTimeFive style={{ marginRight: "0.5rem" }} />
                      </p>
                      <p>{userDetails?.journeyTime}</p>
                    </div>
                  </div>
                </Col>

                <Col md="3">
                  <div className="order-page-pickup-cont">
                    <p className="order-page-title">Drop-of</p>
                    <div className="d-flex align-items-center">
                      {/* From - {userDetails?.journeyDate} */}
                      <p>
                        <FaLocationDot style={{ marginRight: "0.5rem" }} />
                      </p>
                      <p>{userDetails?.toAddress}</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <p>
                        <BiCalendar style={{ marginRight: "0.5rem" }} />
                      </p>
                      <p>
                        {format(
                          new Date(userDetails?.journeyEndDate),
                          "MMMM dd, yyyy"
                        )}
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <p>
                        <BiTimeFive style={{ marginRight: "0.5rem" }} />
                      </p>
                      <p>{userDetails?.journeyEndTime}</p>
                    </div>
                  </div>
                  {/* <p>To - {userDetails?.journeyEndDate}</p> */}
                </Col>

                <Col md="3">
                  <div className="order-page-pickup-cont">
                    <p className="order-page-title">Other details</p>
                    <div className="d-flex align-items-center">
                      <p>
                        <FiUsers style={{ marginRight: "0.5rem" }} />
                      </p>
                      <p>{userDetails?.person}</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <p>
                        <MdOutlineLuggage style={{ marginRight: "0.5rem" }} />
                      </p>
                      <p>{userDetails?.luggage}</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <p>
                        <GrUserManager style={{ marginRight: "0.5rem" }} />
                      </p>
                      <p>{userDetails?.userAge}</p>
                    </div>
                  </div>
                  {/* <p>To - {userDetails?.journeyEndDate}</p> */}
                </Col>

                <Col md="3">
                  <div className="">
                    <p className="order-page-title">Insurance</p>
                    <div className="d-flex align-items-center">
                      <p>
                        {/* {insuranceSelected ? (
                          <AiFillCheckCircle
                            color="green"
                            style={{ marginRight: "0.5rem" }}
                          />
                        ) : (
                          <MdCancel
                            color="red"
                            style={{ marginRight: "0.5rem" }}
                          />
                        )} */}
                        <AiFillCheckCircle
                            color="green"
                            style={{ marginRight: "0.5rem" }}
                          />
                      </p>
                      <p>
                        {insuranceSelected ? (
                          <span>
                            Insurance added{" "}
                            {/* <small>(mail will be sent you)</small> */}
                          </span>
                        ) : (
                          "Insurance included"
                        )}
                      </p>
                    </div>
                  </div>
                  {/* <p>To - {userDetails?.journeyEndDate}</p> */}
                </Col>
              </Row>
            </div>

            {/* <Row>
            <Col>
              <div className="d-flex align-items-center">
                <p>
                  <BiTimeFive style={{ marginRight: "0.5rem" }} />
                </p>
                <p>{userDetails?.journeyTime}</p>
              </div>
            </Col>
            <Col>
              <div className="d-flex align-items-center">
                <p>
                  <BiTimeFive style={{ marginRight: "0.5rem" }} />
                </p>
                <p>{userDetails?.journeyEndTime}</p>
              </div>
            </Col>
          </Row> */}
          </div>
        </Container>
      </motion.div>: <p>Redirecting to Car booking page...</p>}
    </>
  );
};

export default Order;
