import React, { useEffect, useState, useContext, useReducer } from "react";
import Button from "react-bootstrap/Button";
import ReactPlaceholder from "react-placeholder";
import { ToastContainer, toast } from "react-toastify";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "../styles/profile.css";
import axiosInstance from "../utils/axiosUtil";
import { Container } from "reactstrap";

import {
  updateStart,
  updateSuccess,
  updateFailure,
} from "../components/features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfileFailure,
  getProfileStart,
  getProfileSuccess,
} from "../components/features/getProfileSlice";
import { useNavigate } from "react-router-dom";
import Helmet from "../components/Helmet/HelmetComponent";
import { motion } from "framer-motion";
import { setAvailableCars } from "../components/features/availableCarsSlice";
import CommonSection from "../components/UI/CommonSection";
import { BiSolidCity, BiSolidUser } from "react-icons/bi";
import { HiMiniCake } from "react-icons/hi2";
import { BsFillTelephoneFill } from "react-icons/bs";
import { GrMail } from "react-icons/gr";
import { FaSearchLocation } from "react-icons/fa";
import { FaMapLocationDot } from "react-icons/fa6";
import { TbBuildingEstate } from "react-icons/tb";

const ContentContainer = ({ no, item }) => {
  return (
    <div className="content-box">
      <div className="content-header">
        <h5 style={{ color: "#868686", fontWeight: 600 }}>
          <span>{no}.</span>
          {item.name}
        </h5>
      </div>
      <div className="content-body">
        <div className="content-body-top" style={{ color: "#868686" }}>
          <h6>{item.det1}</h6>
          {item.det2 && <p className="mt-4">{item.det2}</p>}
        </div>
        <div className="content-body-bottom" style={{ color: "#868686" }}>
          {item.content}
        </div>
      </div>
    </div>
  );
};

const AccountForm = () => {
  const dispatch = useDispatch();
  const { userInfo, loadingGetProfile, getProfileErr } = useSelector(
    (state) => state.getProfile
  );
  const { token, isFetching, error, errMsg } = useSelector(
    (state) => state.auth
  );
  const toastOptions = {
    position: "bottom-center",
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  const [values, setValues] = useState({
    email: userInfo?.email,
    password: userInfo?.password,
    address: userInfo?.address,
    city: userInfo?.city,
    state: userInfo?.state,
    zip: userInfo?.zip,
    name: userInfo?.name,
    age: userInfo?.age,
    phone: userInfo?.phone,
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  // function to fetch the details of a user
  const fetchDetails = async () => {
    dispatch(getProfileStart());
    try {
      const { data } = await axiosInstance.get("/api/user/find-user", {
        headers: { Authorization: `${token}` },
      });

      setValues({
        email: data.user?.email,
        password: data.user?.password,
        address: data.user?.address,
        city: data.user?.city,
        state: data.user?.state,
        zip: data.user?.zip,
        name: data.user?.name,
        age: data.user?.age,
        phone: data.user?.phone,
      });
      dispatch(getProfileSuccess(data));
    } catch (error) {
      toast.error(error?.response?.data?.message, toastOptions);
      dispatch(getProfileFailure(error?.response?.data?.message));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(updateStart());

      const { data } = await axiosInstance.patch(
        "/api/user/update-user",
        values,
        {
          headers: { Authorization: `${token}` },
        }
      );

      toast.success("Details updated!", toastOptions);
      dispatch(updateSuccess(data));
    } catch (error) {
      toast.success(error?.response?.data?.message, toastOptions);
      dispatch(updateFailure(error?.response?.data?.message));
    }
  };
  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <ReactPlaceholder
          type="text"
          color="#F0F0F0"
          showLoadingAnimation
          rows={10}
          ready={!loadingGetProfile && !isFetching}
        >
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group className="mb-3" controlId="firstname">
                <BiSolidUser color="#7a7a87" />{" "}
                <Form.Label style={{ color: "#868686" }}>Name</Form.Label>
                <Form.Control
                  type="text"
                  required
                  value={values?.name}
                  name="name"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="lastname">
                <HiMiniCake style={{ color: "#868686" }} />
                <Form.Label style={{ color: "#868686" }}>Age</Form.Label>
                <Form.Control
                  type="number"
                  required
                  value={values?.age}
                  name="age"
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group className="mb-3" controlId="phone">
                <BsFillTelephoneFill style={{ color: "#7a7a87" }} />{" "}
                <Form.Label style={{ color: "#7a7a87" }}>Phone</Form.Label>
                <Form.Control
                  type="number"
                  required
                  name="phone"
                  value={values?.phone}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="phone">
                <GrMail color="#7a7a87" />{" "}
                <Form.Label style={{ color: "#7a7a87" }}>Email</Form.Label>
                <Form.Control
                  type="email"
                  required
                  name="email"
                  value={values?.email}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={4}>
              <Form.Group className="mb-3" controlId="phone">
                <BiSolidCity style={{ color: "#7a7a87" }} />{" "}
                <Form.Label style={{ color: "#7a7a87" }}>City</Form.Label>
                <Form.Control
                  type="text"
                  // required
                  name="city"
                  value={values?.city}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3" controlId="phone">
                <FaSearchLocation style={{ color: "#7a7a87" }} />{" "}
                <Form.Label style={{ color: "#7a7a87" }}>Zip</Form.Label>
                <Form.Control
                  type="number"
                  // required
                  name="zip"
                  value={values?.zip}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group controlId="formGridState">
                <TbBuildingEstate style={{ color: "#7a7a87" }} />{" "}
                <Form.Label style={{ color: "#7a7a87" }}>State</Form.Label>
                <Form.Control
                  type="text"
                  // required
                  name="state"
                  value={values?.state}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col>
              <Form.Group className="mb-3" controlId="phone">
                <FaMapLocationDot style={{ color: "#7a7a87" }} />{" "}
                <Form.Label style={{ color: "#7a7a87" }}>Address</Form.Label>
                <Form.Control
                  as="textarea"
                  // required
                  name="address"
                  value={values?.address}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="form-sub-sec">
            <Button type="submit" className="profile-accountFmbtn">
              Update
            </Button>
          </div>
        </ReactPlaceholder>
      </Form>
    </>
  );
};

const AllOrders = () => {
  const navigate = useNavigate();

  return (
    <>
      <Row className="m-0">
        <p
          style={{
            marginBottom: "1.5rem",
          }}
        >
          Click here to see all your previous bookings!
        </p>
        <div className="form-sub-sec">
          <Button
            className="profile-bookingsbtn"
            onClick={() => navigate("/booking-history")}
          >
            My Bookings
          </Button>
        </div>
      </Row>
    </>
  );
};

const contentList = [
  {
    name: "Edit your account information",
    det1: "My account information",
    det2: "Your Personal Details",
    content: <AccountForm />,
  },

  {
    name: "Your Bookings",
    det1: "",
    det2: "Your previous bookings will appear here",
    content: <AllOrders />,
  },
];

const Profile = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAvailableCars());
  }, []);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <Helmet title="Christchurch Car Rental - Profile" />
        <CommonSection title={`My Account`} />
        <Container className="pt-5 px-3">
          <div className="">
            {/* <h1 className="">My Account</h1> */}
            {contentList.map((item, i) => (
              <ContentContainer key={item.name} no={i + 1} item={item} />
            ))}
          </div>
        </Container>
      </motion.div>
      <ToastContainer />
    </>
  );
};

export default Profile;
