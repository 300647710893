import React, { useEffect } from "react";
import ReactPlaceholder from "react-placeholder";
import "react-placeholder/lib/reactPlaceholder.css";
import { Col, Container, Row } from "reactstrap";
import BookingForm from "../components/UI/BookingForm";
import {
  carDetailsFailure,
  carDetailsStart,
  carDetailsSuccess,
} from "../components/features/getCarSlice";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../utils/axiosUtil";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { setAvailableCars } from "../components/features/availableCarsSlice";
import Helmet from "../components/Helmet/HelmetComponent";
import CommonSection from "../components/UI/CommonSection";

const BookCar = () => {
  const params = useParams();
  const { token } = useSelector((state) => state.auth);
  const { loadingCarDetails } = useSelector((state) => state.carDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAvailableCars());
  }, []);

  useEffect(() => {
    const fetchDetails = async () => {
      dispatch(carDetailsStart());
      try {
        const { data } = await axiosInstance.get(
          `/api/car/find-car/${params.id}`,
          { headers: { Authorization: token } }
        );

        dispatch(carDetailsSuccess(data.car));
      } catch (error) {
        dispatch(carDetailsFailure(error?.response?.data?.message));
      }
    };

    fetchDetails();
  }, []);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <Helmet title={"Christchurch Car Rental - Book car"} />
        <CommonSection title={`Book your car`} />
        <Container>
          <ReactPlaceholder
            type="text"
            color="#F0F0F0"
            showLoadingAnimation
            rows={10}
            // delay={200}
            ready={!loadingCarDetails}
          >
            <div className="booking-info mt-5">
              <h5 className="mb-4 fw-bold ">Booking Information</h5>
              <BookingForm />
            </div>
          </ReactPlaceholder>
        </Container>
      </motion.div>
    </>
  );
};

export default BookCar;
