import React from 'react'
import { Container, Dropdown, Nav, Navbar } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FaUserCircle, FaBars, FaUser, FaSignOutAlt,FaSpinner } from "react-icons/fa";

function Temp() {
  return (
    <div style={{height : '200px', border : '5px solid red'}}>
    
    </div>
  )
}

export default Temp
