import React, { useState, useEffect } from "react";
import ReactPlaceholder from "react-placeholder";
import "../../styles/find-car-form.css";
import { Button, Col, Form, FormGroup, Input, Row, Spinner } from "reactstrap";
import axiosInstance from "../../utils/axiosUtil";
import { useDispatch, useSelector } from "react-redux";
// import { format } from "date-fns";
import {
  availableCarsFailure,
  availableCarsSearch,
  availableCarsStart,
  availableCarsSuccess,
} from "../features/availableCarsSlice";
import { findCarSuccess } from "../features/findCarSlice";
import {
  getLocationFailure,
  getLocationStart,
  getLocationSuccess,
} from "../features/getLocationSlice";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";
import { AiOutlineRight } from "react-icons/ai";
// import { availableCar } from "../../reducers/carReducer";

const FindCarForm = (props) => {
  const { setCars, setLoading } = props;
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { pickupLocations, dropOffLocations, loadingLocation } = useSelector(
    (state) => state.getLocation
  );
  const { loadingAvailableCars } = useSelector((state) => state.availableCars);

  const [values, setValues] = useState({
    fromAddress: "",
    toAddress: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
  });

  const toastOptions = {
    position: "bottom-center",
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  useEffect(() => {
    const fetchLocation = async () => {
      dispatch(getLocationStart());
      try {
        const { data } = await axiosInstance.get("/api/location/get-locations");

        dispatch(getLocationSuccess(data));
      } catch (error) {
        dispatch(getLocationFailure(error));
      }
    };

    fetchLocation();
  }, []);


  useEffect(() => {
    if (
      values.startDate &&
      values.endDate &&
      values.endTime &&
      values.startTime
    ) {

      const journeyStartDateTime = new Date(
        `${format(new Date(values.startDate), "MMMM dd, yyyy")}, ${values.startTime
        }`
      );
      const journeyEndDateTime = new Date(
        `${format(new Date(values.endDate), "MMMM dd, yyyy")}, ${values.endTime
        }`
      );
      if (journeyStartDateTime.getDate() === journeyEndDateTime.getDate()) {
        localStorage.setItem("journeyDays", "1");

      } else {
        const days = Math.ceil(
          (journeyEndDateTime - journeyStartDateTime) / (1000 * 60 * 60 * 24)
        );

        localStorage.setItem("journeyDays", JSON.stringify(days));
      }
    }
  }, [values]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      dispatch(availableCarsStart());
      const { data } = await axiosInstance.post(
        "/api/car/getAvail-car",
        values
      );

      // setCars(data.availableCars);

      dispatch(availableCarsSuccess(data));
      dispatch(findCarSuccess(values));
      dispatch(availableCarsSearch(true));

      // setLoading(false);
    } catch (err) {
      // console.log(err);
      toast.error(
        "Something went wrong! Please try again later.",
        toastOptions
      );
      // setLoading(false);
      dispatch(availableCarsFailure(err));

      // showing an error
    }
  };

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <>
      <ReactPlaceholder
        type="text"
        color="#F0F0F0"
        showLoadingAnimation
        rows={10}
        delay={200}
        ready={!loadingLocation}
      >
        <Form className="form">
          <div className="  flex-wrap">
            <Row>
              <>
                <FormGroup className="form__group gap-2">
                  <label>From address</label>
                  <Input
                    onChange={handleChange}
                    value={values.fromAddress}
                    type="select"
                    placeholder="From address"
                    required
                    name="fromAddress"
                  >
                    <option value="">Select</option>
                    {/* {!(values.toAddress === "custom") && <option value="custom">Custom Address</option>} */}
                    {pickupLocations?.map((pickUp) => (
                      <option key={pickUp} value={pickUp}>
                        {pickUp}
                      </option>
                    ))}

                    {pickupLocations?.length <= 0 && (
                      <option value="" disabled>
                        Locations will be added soon!
                      </option>
                    )}
                  </Input>
                  {/* {values.fromAddress === 'custom' && <div className="input-group mb-3">
                    <input onChange={handleChange} name="fromAddress" type="text" className="form-control 2" placeholder="Enter from address" aria-label="From address" />
                  </div>} */}

                </FormGroup>
              </>
              <Col>
                <FormGroup className="form__group">
                  <label>Start date</label>
                  <Input
                    onChange={handleChange}
                    value={values.startDate}
                    type="date"
                    min={new Date().toJSON().slice(0, 10)}
                    placeholder="Journey date"
                    required
                    name="startDate"
                  />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup className="form__group">
                  <label>Start Time</label>
                  <Input
                    type="time"
                    onChange={handleChange}
                    className="journey__time"
                    value={values.startTime}
                    placeholder="Journey time"
                    name="startTime"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <FormGroup className="form__group">
                <label>To address</label>
                <Input
                  onChange={handleChange}
                  value={values.toAddress}
                  type="select"
                  placeholder="To address"
                  required
                  name="toAddress"
                >
                  <option value="">Select</option>
                  {/* {!(values.fromAddress === "custom") && <option value="custom">Custom Address</option>} */}
                  {dropOffLocations?.map((dropOf) => (
                    <option key={dropOf} value={dropOf}>
                      {dropOf}
                    </option>
                  ))}
                  {dropOffLocations?.length <= 0 && (
                    <option value="" disabled>
                      Locations will be added soon!
                    </option>
                  )}
                </Input>
                {/* {values.toAddress === 'custom' && <div className="input-group mb-3">
                  <input name="toAddress" type="text" className="form-control 1" placeholder="Enter to address" aria-label="To address" />
                </div>} */}
              </FormGroup>
              <Col>
                <FormGroup className="form__group">
                  <label>End date</label>
                  <Input
                    onChange={handleChange}
                    disabled={values?.startDate === ""}
                    type="date"
                    min={new Date().toJSON().slice(0, 10)}
                    // min={
                    //   values?.startDate &&
                    //   format(new Date(values?.startDate), "MMMM dd, yyyy")
                    // }
                    value={values.endDate}
                    placeholder="Journey date"
                    required
                    name="endDate"
                  />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup className="form__group">
                  <label>End Time</label>
                  <Input
                    type="time"
                    onChange={handleChange}
                    className="journey__time"
                    placeholder="Journey time"
                    name="endTime"
                    value={values.endTime}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            {/* <FormGroup className="select__group">
          <select>
            <option value="ac">AC Car</option>
            <option value="non-ac">Non AC Car</option>
          </select>
  </FormGroup> */}

            <FormGroup className="form__group" id="car-form-sbt-div">
              <Button
                disabled={
                  values.fromAddress.length <= 0 ||
                  values.toAddress.length <= 0 ||
                  values.endDate.length <= 0 ||
                  values.startDate.length <= 0 ||
                  values.startTime.length <= 0 ||
                  values.endTime.length <= 0 ||
                  loadingAvailableCars
                }
                onClick={handleSubmit}
                className="btn find__car-btn"
                id="sbt-btn"
              >
                {loadingAvailableCars ? (
                  <Spinner color="light" />
                ) : (
                  <div className="align-items-center">
                    Search
                    <AiOutlineRight style={{ marginLeft: "0.5rem" }} />
                  </div>
                )}
              </Button>
            </FormGroup>
          </div>
        </Form>
      </ReactPlaceholder>

      <ToastContainer />
    </>
  );
};

export default FindCarForm;
