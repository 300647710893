import React, { useState, useEffect } from "react";
import ReactPlaceholder from "react-placeholder";
import { Container, Row, Col, Input, Label } from "reactstrap";
import Helmet from "../components/Helmet/HelmetComponent";
import CommonSection from "../components/UI/CommonSection";
import CarItem from "../components/UI/CarItem";
import carData from "../assets/data/carData";
import { useDispatch, useSelector } from "react-redux";
import {
  allCarsFailure,
  allCarsStart,
  allCarsSuccess,
} from "../components/features/allCarsSlice";
import axiosInstance from "../utils/axiosUtil";
import { motion } from "framer-motion";
import { setAvailableCars } from "../components/features/availableCarsSlice";
import HelmetComponent from "../components/Helmet/HelmetComponent";

const CarListing = () => {
  const dispatch = useDispatch();
  const { allCars, loadingAllCars, allCarsErr } = useSelector(
    (state) => state.allCars
  );
  const [sort, setSort] = useState("low");

  useEffect(() => {
    dispatch(setAvailableCars());
  }, []);

  useEffect(() => {
    const fetchAllCars = async () => {
      dispatch(allCarsStart());
      try {
        const { data } = await axiosInstance.get(
          `/api/car/get-car?sort=${sort}`
        );

        dispatch(allCarsSuccess(data.cars));
      } catch (error) {
        dispatch(allCarsFailure(error?.response?.data?.message));
      }
    };

    fetchAllCars();
  }, [sort]);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <HelmetComponent
          title={"Wide Selection of Cars | Christchurch Car Rental "}
          metaDesc={
            "Choose from our extensive collection of rental cars at Christchurch Car Rental. Start your journey."
          }
        />
        <CommonSection title="Car Rental Christchurch" />

        <section style={{ backgroundColor: "#F1F4F8" }}>
          <Container>
            <Row>
              <Col lg="6" md="6">
                <div>
                  {/* <h4 className="section__subtitle">About Us</h4> */}
                  <p className="section__description">
                    Discover the convenience and flexibility of car rental services in Christchurch with Car Rental Christchurch. Our website offers a comprehensive selection of vehicles to meet your travel needs, whether you're exploring the city or embarking on an adventure in the surrounding areas.
                    <br /> <br />
                    With competitive rates, easy online booking, and excellent customer support, we strive to make your rental experience seamless and stress-free. Explore Christchurch and beyond on your own terms with Car Rental Christchurch.
                  </p>
                </div>
              </Col>
              <Col lg="12">
                <div className=" gap-3 mb-5">
                  <Label style={{ color: "#868686" }}>
                    <span className=" d-flex align-items-center gap-2">
                      <i class="ri-sort-asc"></i> Sort By
                    </span>
                  </Label>

                  <Input
                    className="car-list-sort"
                    style={{ width: "30%" }}
                    type="select"
                    value={sort}
                    onChange={(e) => setSort(e.target.value)}
                  >
                    <option value="" hidden>
                      Select
                    </option>
                    <option value="low">Low to High</option>
                    <option value="high">High to Low</option>
                  </Input>
                </div>
              </Col>

              <ReactPlaceholder
                type="text"
                color="#F0F0F0"
                showLoadingAnimation
                rows={10}
                delay={200}
                ready={!loadingAllCars}
              >
                {allCars?.map((item) => (
                  <CarItem item={item} key={item._id} />
                ))}
              </ReactPlaceholder>
            </Row>
          </Container>
        </section>
      </motion.div>
    </>
  );
};

export default CarListing;
