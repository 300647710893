import { createSlice } from "@reduxjs/toolkit";

const token = localStorage.getItem("userToken");
const user = JSON.parse(localStorage.getItem("user"));

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user,
    token: token,
    isFetching: false,
    error: false,
    errMsg: "",
  },
  reducers: {
    loginStart: (state, action) => {
      state.isFetching = true;
      state.error = false;
      state.errMsg = "";
    },
    loginSuccess: (state, action) => {
      state.errMsg = "";
      state.error = false;

      state.isFetching = false;
      state.error = false;
      state.user = action.payload.user;
      state.token = action.payload.token;

      localStorage.setItem("userToken", state.token);
      localStorage.setItem("user", JSON.stringify(action.payload.user));
    },
    loginFailure: (state, action) => {
      state.errMsg = action.payload;
      state.isFetching = false;
      state.error = true;
    },

    registerStart: (state, action) => {
      state.isFetching = true;
      state.error = false;
      state.errMsg = "";
    },
    registerSuccess: (state, action) => {
      state.error = false;
      state.errMsg = "";
      state.isFetching = false;
      state.user = action.payload.user;
      state.token = action.payload.token;

      localStorage.setItem("userToken", state.token);
      localStorage.setItem("user", state.user);
    },
    registerFailure: (state, action) => {
      state.isFetching = false;
      state.error = true;
      state.errMsg = action.payload;
    },

    updateStart: (state, action) => {
      state.isFetching = true;
      state.error = false;
      state.errMsg = "";
    },
    updateSuccess: (state, action) => {
      state.error = false;
      state.errMsg = "";
      state.isFetching = false;
      state.user = action.payload.user;
      localStorage.removeItem("user");
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    updateFailure: (state, action) => {
      state.isFetching = false;
      state.error = true;
      state.errMsg = action.payload;
    },

    logOut: (state, action) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem("user");
      localStorage.removeItem("userToken");
      localStorage.removeItem("insurance");
      localStorage.removeItem("insuranceType");
      localStorage.removeItem("journeyDays");
    },
  },
});

export const {
  logOut,
  loginStart,
  loginSuccess,
  loginFailure,
  registerSuccess,
  registerStart,
  registerFailure,
  updateFailure,
  updateStart,
  updateSuccess,
} = authSlice.actions;
export default authSlice.reducer;
