import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  carDetails: [],
  loadingCarDetails: false,
  carDetailsErr: "",
};

const carDetailsSlice = createSlice({
  name: "carDetails",
  initialState,
  reducers: {
    carDetailsStart: (state, action) => {
      state.loadingCarDetails = true;
      state.carDetailsErr = "";
    },
    carDetailsSuccess: (state, action) => {
      state.loadingCarDetails = false;
      state.carDetailsErr = "";
      state.carDetails = action.payload;
    },
    carDetailsFailure: (state, action) => {
      state.loadingCarDetails = false;
      state.carDetailsErr = action.payload;
    },
    // setCarDetails: (state, action) => {
    //   state.carDetails = [];
    //   state.loadingCarDetails = false;
    //   state.carDetailsErr = "";
    // },
  },
});

export const { carDetailsStart, carDetailsFailure, carDetailsSuccess } =
  carDetailsSlice.actions;
export default carDetailsSlice.reducer;
