import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  bookingHistory: [],
  loadingBookingHistory: false,
  bookingHistoryErr: "",
};

const bookingHistorySlice = createSlice({
  name: "bookingHistory",
  initialState,
  reducers: {
    bookingHistoryStart: (state, action) => {
      state.loadingBookingHistory = true;
      state.bookingHistoryErr = "";
    },
    bookingHistorySuccess: (state, action) => {
      state.bookingHistoryErr = "";
      state.loadingBookingHistory = false;
      state.bookingHistory = action.payload;
    },
    bookingHistoryFailure: (state, action) => {
      state.loadingBookingHistory = false;
      state.bookingHistoryErr = action.payload;
    },
    setBookingHistory: (state, action) => {
      state.bookingHistory = [];
      state.loadingBookingHistory = false;
      state.bookingHistoryErr = "";
    },
  },
});

export const {
  bookingHistoryStart,
  bookingHistoryFailure,
  bookingHistorySuccess,
  setBookingHistory,
} = bookingHistorySlice.actions;
export default bookingHistorySlice.reducer;
