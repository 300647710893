import React from "react";
import CommonSection from "../components/UI/CommonSection";
import { Col, Container, Row } from "reactstrap";
import Helmet from "../components/Helmet/HelmetComponent";
import { motion } from "framer-motion";
import { MdOutlineLocationOn } from "react-icons/md";
import { Link } from "react-router-dom";
import { AiOutlineLink } from "react-icons/ai";
import HelmetComponent from "../components/Helmet/HelmetComponent";

const formatText = (text) => {
  // Format text starting with a number as bold
  const formattedText = text.replace(/\d+/g, "<b>$&</b> ");

  return formattedText;
};

const NZAttractions = [
  {
    id: "1",
    city: "Auckland",
    desc: `New Zealand's largest city, Auckland, is situated on the North Island. It's known as the "City of Sails" due to its harbors and a large number of yachts. Visitors can explore the Auckland War Memorial Museum, climb the Auckland Sky Tower for panoramic views, or relax on the many beaches like Piha and Takapuna.`,
  },
  {
    id: "2",
    city: "Wellington",
    desc: `The capital city, Wellington, is located on the southern tip of the North Island. It's known for its vibrant arts scene, historic cable car, and Te Papa Tongarewa National Museum. The city is surrounded by hills and offers stunning harbor views.`,
  },
  {
    id: "3",
    city: "Rotorua",
    desc: `Located in the heart of the North Island, Rotorua is famous for its geothermal activity. Visitors can witness geysers, bubbling mud pools, and hot springs in places like Wai-O-Tapu and Te Puia. Rotorua is also home to traditional Maori cultural experiences.`,
  },
  {
    id: "4",
    city: "Queenstown",
    desc: `Nestled on New Zealand's South Island, Queenstown is an adventure lover's paradise. It's known for its stunning alpine scenery, Lake Wakatipu, and a wide range of outdoor activities such as bungee jumping, skiing, and skydiving. The nearby Fiordland National Park is a must-visit for its dramatic fjords.`,
  },
  {
    id: "5",
    city: "Christchurch",
    desc: `Christchurch is the largest city on the South Island and is known for its English heritage and beautiful gardens. The city was severely affected by earthquakes in 2010 and 2011, but it has since been rebuilt and offers attractions like the Botanic Gardens and the Cardboard Cathedral.`,
  },
  {
    id: "6",
    city: "Milford Sound",
    desc: `Often referred to as the "Eighth Wonder of the World," Milford Sound is a stunning fjord located in Fiordland National Park. Visitors can take boat cruises through the sound to witness towering waterfalls, dramatic cliffs, and abundant wildlife.`,
  },
  {
    id: "7",
    city: "Abel Tasman National Park",
    desc: `Located at the northern tip of the South Island, Abel Tasman National Park is renowned for its golden beaches, crystal-clear waters, and lush coastal forests. It's a fantastic destination for hiking, kayaking, and camping.`,
  },
  {
    id: "8",
    city: "Fox and Franz Josef Glaciers",
    desc: `On the West Coast of the South Island, these glaciers are among the most accessible in the world. Visitors can take guided hikes or helicopter tours to explore these icy wonders.`,
  },
  {
    id: "9",
    city: "Tongariro National Park",
    desc: `This UNESCO World Heritage site on the North Island is famous for its three active volcanoes: Tongariro, Ngauruhoe, and Ruapehu. The Tongariro Alpine Crossing is a popular day hike that takes you through diverse landscapes, including volcanic craters and emerald lakes.`,
  },
  {
    id: "10",
    city: "Bay of Islands",
    desc: `Located in the northern part of the North Island, the Bay of Islands is known for its stunning coastlines, marine life, and historic sites. Visitors can go sailing, fishing, or explore the Waitangi Treaty Grounds, where New Zealand's founding document was signed.`,
  },
];

const NZFoods = [
  {
    id: "1",
    website: "https://www.tripadvisor.co.nz/",
    title: `Tripadvisor`,
    desc: `Tripadvisor, the world's largest travel platform. Whether planning or on a trip, travelers turn to Tripadvisor to compare low prices on hotels, flights and cruises, book popular tours and attractions, as well as reserve tables at great restaurants.`,
  },
  {
    id: "2",
    website: "https://www.cuisine.co.nz/",
    title: `Cuisine Good Food Guide`,
    desc: `Cuisine is a respected New Zealand food and wine magazine that publishes an annual Good Food Guide, showcasing the country's top restaurants, cafes, and eateries.`,
  },
  {
    id: "3",
    website: "https://www.lonelyplanet.com/new-zealand",
    title: `Lonely Planet New Zealand`,
    desc: `Lonely Planet provides travel guides that include recommendations for dining and local food experiences in New Zealand.`,
  },
  {
    id: "4",
    website: "https://nzherald.co.nz/viva-magazine/",
    title: `New Zealand Herald Viva`,
    desc: `Viva is a New Zealand Herald lifestyle and food section that features restaurant reviews, food trends, and dining recommendations.`,
  },
];

const NZTravelsTourism = [
  {
    id: "1",
    website: "https://www.newzealand.com/",
    title: `Tourism New Zealand`,
    desc: `The official tourism website for New Zealand offers information on attractions, activities, accommodations, and travel tips.`,
  },
  {
    id: "2",
    website: "https://www.immigration.govt.nz/",
    title: `New Zealand Immigration`,
    desc: `For visa and immigration information if you're planning an extended stay.`,
  },
];

const NZPlacesVisit = [
  {
    id: "1",
    website: "https://www.doc.govt.nz/",
    title: `Department of Conservation (DOC)`,
    desc: `Offers information on New Zealand's national parks, hiking trails, and conservation efforts.`,
  },
  {
    id: "2",
    website: "https://www.heritage.org.nz/",
    title: `Heritage New Zealand`,
    desc: `Explore New Zealand's cultural heritage and historic sites.`,
  },
  {
    id: "3",
    website: "https://maoritourism.co.nz/",
    title: `Māori Tourism New Zealand`,
    desc: `Learn about Māori culture and experiences.`,
  },
];

const NZEmergency = [
  {
    id: "1",
    website: "https://www.police.govt.nz/",
    title: `New Zealand Police`,
    desc: `For non-emergency situations and general inquiries.`,
  },
  {
    id: "2",
    website: "",
    title: `Emergency Services`,
    desc: `In case of an emergency, dial 111 for police, fire, or ambulance.`,
  },
  {
    id: "3",
    website: "",
    title: `Māori Tourism New Zealand`,
    desc: `For medical advice and assistance, call 0800 611 116.`,
  },
  {
    id: "4",
    website: "https://www.civildefence.govt.nz/",
    title: `Civil Defence`,
    desc: `Information on emergency preparedness and updates during natural disasters.`,
  },
];

const UserGuide = () => {
  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <HelmetComponent
          title={
            "User Guide | Christchurch Car Rental | Tips and Information"
          }
          metaDesc={
            "Explore our user guide to enhance your car rental experience. Christchurch Car Rental is your trusted source for information."
          }
        />
        <CommonSection title="User Guide" />
        <Container className="my-4">
          <div className="user-guide-root-one">
            <h3>
              <strong>New Zealand Attractions</strong>
            </h3>

            <p>
              New Zealand is a breathtakingly beautiful country located in the
              southwestern Pacific Ocean. It is known for its stunning
              landscapes, diverse natural beauty, and vibrant culture. Whether
              you're interested in exploring geothermal wonders, hiking through
              pristine wilderness, or simply enjoying the laid-back Kiwi
              lifestyle, New Zealand has something to offer every traveler.{" "}
              <br /> <br />
              Here are some of the top travel locations in New Zealand along
              with brief descriptions:
            </p>
          </div>
          <div className="nz-attractions">
            <Row className="mb-4">
              {NZAttractions?.map((attraction) => (
                <Col md="3" key={attraction?.id}>
                  <div className="nz-attractions-cont">
                    <div>
                      <p>
                        <strong>
                          {attraction?.id < 10
                            ? attraction?.id?.padStart(2, "0")
                            : attraction?.id}
                          .
                        </strong>
                      </p>
                      <p className="nz-attractions-city">
                        <MdOutlineLocationOn /> {attraction?.city}
                      </p>
                    </div>

                    <div className="nz-attractions-desc">
                      <p>{attraction?.desc}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>

          <div className="user-guide-root-one">
            <h3>
              <strong>Food & Culinary</strong>
            </h3>

            <p>
              New Zealand offers a diverse culinary scene, with influences from
              Māori, European, Asian, and Pacific Island cuisines. To discover
              the best food in New Zealand, you can refer to various food
              guides, websites, and apps that provide recommendations and
              reviews. <br /> <br /> When using these resources, keep in mind
              that personal tastes vary, so it's a good idea to read multiple
              reviews and recommendations to get a well-rounded view of the
              dining scene in New Zealand. Additionally, New Zealand offers a
              unique opportunity to explore traditional Māori cuisine, seafood,
              lamb dishes, and a growing artisanal food and wine culture, so be
              sure to try a variety of local specialties during your visit.{" "}
              <br /> <br /> Here are some popular resources:
            </p>
          </div>
          <div className="nz-attractions">
            <Row className="mb-4">
              {NZFoods?.map((food) => (
                <Col md="3" key={food?.id}>
                  <div className="nz-attractions-cont">
                    <div>
                      <p>
                        <strong>
                          {food?.id < 10
                            ? food?.id?.padStart(2, "0")
                            : food?.id}
                          .
                        </strong>
                      </p>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="nz-attractions-city">{food?.title}</p>
                        <p className="nz-food-link-cont">
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            className="nz-food-link"
                            href={food?.website}
                          >
                            <AiOutlineLink color="#2c5a69" /> Visit
                          </a>
                        </p>
                      </div>
                    </div>

                    <div className="nz-attractions-desc">
                      <p className="mt-2">{food?.desc}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>

          <div className="user-guide-root-one">
            <h3>
              <strong>Travel and Tourism</strong>
            </h3>

            <p>
              Here are some official websites and resources for travel and
              tourism in New Zealand:
            </p>
          </div>
          <div className="nz-attractions">
            <Row className="mb-4 justify-content-center">
              {NZTravelsTourism?.map((info) => (
                <Col md="3" lg="5" key={info?.id}>
                  <div className="nz-attractions-cont">
                    <div>
                      <p>
                        <strong>
                          {info?.id < 10
                            ? info?.id?.padStart(2, "0")
                            : info?.id}
                          .
                        </strong>
                      </p>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="nz-attractions-city">{info?.title}</p>
                        <p className="nz-food-link-cont">
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            className="nz-food-link"
                            href={info?.website}
                          >
                            <AiOutlineLink color="#2c5a69" /> Visit
                          </a>
                        </p>
                      </div>
                    </div>

                    <div className="nz-attractions-desc">
                      <p className="mt-2">{info?.desc}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>

          <div className="user-guide-root-one">
            <h3>
              <strong>Places to Visit</strong>
            </h3>

            <p>
              The most exciting destinations, experiences, hidden gems, and
              traveller faves to check out now. <br /> <br /> Here are some
              official websites and resources for places to visit in New
              Zealand:
            </p>
          </div>
          <div className="nz-attractions">
            <Row className="mb-4 justify-content-center">
              {NZPlacesVisit?.map((info) => (
                <Col md="3" lg="3" key={info?.id}>
                  <div className="nz-attractions-cont">
                    <div>
                      <p>
                        <strong>
                          {info?.id < 10
                            ? info?.id?.padStart(2, "0")
                            : info?.id}
                          .
                        </strong>
                      </p>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="nz-attractions-city">{info?.title}</p>
                        {info?.website !== "" && (
                          <p className="nz-food-link-cont">
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              className="nz-food-link"
                              href={info?.website}
                            >
                              <AiOutlineLink color="#2c5a69" /> Visit
                            </a>
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="nz-attractions-desc">
                      <p className="mt-2">{info?.desc}</p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>

          <div className="user-guide-root-one">
            <h3>
              <strong>Emergency Help</strong>
            </h3>

            <p>
              Here are some official websites and resources for emergency help
              in New Zealand:
            </p>
          </div>
          <div className="nz-attractions">
            <Row className="mb-4 justify-content-center">
              {NZEmergency?.map((info) => (
                <Col md="3" lg="3" key={info?.id}>
                  <div className="nz-attractions-cont">
                    <div>
                      <p>
                        <strong>
                          {info?.id < 10
                            ? info?.id?.padStart(2, "0")
                            : info?.id}
                          .
                        </strong>
                      </p>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="nz-attractions-city">{info?.title}</p>
                        {info?.website !== "" && (
                          <p className="nz-food-link-cont">
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              className="nz-food-link"
                              href={info?.website}
                            >
                              <AiOutlineLink color="#2c5a69" /> Visit
                            </a>
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="nz-attractions-desc">
                      <p
                        className="mt-2"
                        dangerouslySetInnerHTML={{
                          __html: formatText(info?.desc),
                        }}
                      ></p>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </Container>
      </motion.div>
    </>
  );
};

export default UserGuide;
