import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { setAvailableCars } from "../components/features/availableCarsSlice";
import Helmet from "../components/Helmet/HelmetComponent";
import CommonSection from "../components/UI/CommonSection";

const NotFound = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setAvailableCars());
  }, []);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <Helmet title="Christchurch Car Rental - Not Found" />
        <CommonSection title="Oops! Not found" />
      </motion.div>
    </>
  );
};

export default NotFound;
