import React, { useEffect, useState, useContext, useReducer } from "react";
import { Container, Row, Form, Col, Button, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "../../styles/add.css";
import axiosInstance from "../../utils/axiosUtil";

import { useDispatch, useSelector } from "react-redux";
import { loginFailure, loginStart, loginSuccess } from "../features/authSlice";
import { motion } from "framer-motion";

const SignIn = () => {
  const { isFetching, error, errMsg } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('redirectTo');
  let querys=""
  if(paramValue)
  querys="?redirectTo="+paramValue;
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const toastOptions = {
    position: "bottom-center",
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };
  useEffect(()=>{
     if(localStorage.getItem("userToken")){
      navigate("/");
     }
  },[])

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(loginStart());

    const { email, password } = values;

    try {
      const { data } = await axiosInstance.post("/api/user/login", {
        email,
        password,
      });

      if (data.token) {
        dispatch(loginSuccess(data));
        if(paramValue)
        {navigate(`/${paramValue}`);return}
        navigate("/", { replace: true });
      } else {
        toast.error("Something went wrong. Please try again!", toastOptions);
        dispatch(loginFailure("Something went wrong!"));
      }
    } catch (error) {
      // console.log(error?.response?.data?.message);
      toast.error(error?.response?.data?.message, toastOptions);
      dispatch(loginFailure(error?.response?.data?.message));
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <Container className="pt-5 px-3 px-md-0 f-center wt-500 flex-column">
          <div className="f-center">
            {/* <Button
            onClick={() => navigate("/sign-in")}
            className="toggle-link-item active-link"
          >
            Login
          </Button> */}
            <p>Login to your Account</p>
            <span>Go Beyond Limits, Rent a Car and Explore</span>
          </div>
          <div className="form-box">
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <div className="no-acc-link">
                <Link to={`/sign-up${querys}`} className="">
                  Don't have an account?
                </Link>
              </div>
              {isFetching ? (
                <Button
                  className="login-regis-btn"
                  variant="dark"
                  size="lg"
                  disabled
                >
                  <Spinner animation="border" />
                </Button>
              ) : (
                <Button className="login-regis-btn" type="submit">
                  Submit
                </Button>
              )}
            </Form>
          </div>
        </Container>
      </motion.div>

      <ToastContainer />
    </>
  );
};

export default SignIn;
