import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allCars: [],
  loadingAllCars: false,
  allCarsErr: "",
};

const allCarsSlice = createSlice({
  name: "allCars",
  initialState,
  reducers: {
    allCarsStart: (state, action) => {
      state.loadingAllCars = true;
      state.allCarsErr = "";
    },
    allCarsSuccess: (state, action) => {
      state.allCarsErr = "";
      state.loadingAllCars = false;
      state.allCars = action.payload;
    },
    allCarsFailure: (state, action) => {
      state.loadingAllCars = false;
      state.allCarsErr = action.payload;
    },
    // setOrders: (state, action) => {
    //   state.orders = [];
    //   state.loadingOrders = false;
    //   state.ordersErr = "";
    // },
  },
});

export const { allCarsStart, allCarsSuccess, allCarsFailure } =
  allCarsSlice.actions;
export default allCarsSlice.reducer;
