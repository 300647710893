import React from "react";

import { AiOutlineCopyrightCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";
import "../../styles/footer.css";
import app_logo from "./app_logo.png";

const quickLinks = [
  {
    path: "https://www.carrentalchristchurch.co.nz/airport",
    display: "Airport Car Rental",
  },
  {
    path: "https://www.carrentalchristchurch.co.nz/wedding",
    display: "Wedding Car Rental",
  },
  {
    path: "https://www.carrentalchristchurch.co.nz/luxury-car",
    display: "Luxury Car Rental",
  },
  {
    path: "/cars",
    display: "Cars",
  },

  {
    path: "/contact",
    display: "Contact",
  },
  {
    path: "/faq",
    display: "FAQ",
  },
  {
    path: "/user-guide",
    display: "User Guide",
  },
  {
    path: "/Blogs",
    display: "Blogs",
  },
];

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  const { pathname } = window.location
  const isCustom =
    pathname.includes("carrentalchristchurch") ||
    pathname.includes("contact_us");

  return (
    <footer className="footer">
      <Container>
        <Row className="justify-content-between">
          <Col lg="4" md="3" sm="12">
            <div className="footer__logo">
              <>
                <Link to="/" className=" d-flex align-items-center gap-2">
                  {/* <i class="ri-car-line"></i> */}
                  {/* <AiFillCar /> */}
                  <img
                    src={app_logo}
                    alt={"Christchurch Car Rentals"}
                    className="app-logo"
                  // width={150}
                  />
                  <span>
                    Christchurch <br /> Car Rentals
                  </span>
                </Link>
              </>
            </div>
            <p className="footer__logo-content">
              At Christchurch Car Rental, we understand that convenience,
              reliability, and exceptional service are crucial when it comes to
              renting a car. Whether you're a visitor exploring the vibrant city
              of Christchurch or a local in need of a temporary set of
              wheels, we've got you covered. Our car rental services are
              designed to meet your diverse needs and make your journey as
              smooth as possible.
            </p>
          </Col>

          {!isCustom && <Col lg="2" md="3" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title">Quick Links</h5>
              <ListGroup>
                {quickLinks.map((item, index) => (
                  <ListGroupItem key={index} className="p-0 mt-3 quick__link">
                    <Link to={item.path}>{item.display}</Link>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          </Col>}

          <Col lg="4" md="4" sm="6">
            <div className="mb-4">
              <h5 className="footer__link-title mb-4">Head Office</h5>
              <p className="office__info">

                {/* Address's : 4/162 Stanmore Road, Linwood, Christchurch 8011, */}
                Address: 346 Manchester Street, Christchurch Central City, Christchurch 8013
                {/* 5 churchill Avenue, <br />
                Hokowhitu <br />
                Palmersto north <br />
                New Zealand */}

              </p>
              <p className="office__info">
                {/* 30 Durey Road, Harewood, Christchurch 8053, New Zealand */}
              </p>
              {/* <p className="office__info">
                Phone: +64220211997 <br />
                (0064) 0220211997
                // 0800 555 988
              </p> */}
              {/* <a style={{textDecoration:"none"}} href="tel:0800 555 988"><span className="contact-number">Phone: (0800) 555 988</span></a> */}
              <p style={{ color: "#FFFFFFB6" }}>
                <span className="mb-3">phone</span>
                <a
                  style={{ textDecoration: "none", color: "#FFFFFFB6" }}
                  href="tel:+64-220211997"
                  className="header__top__help"
                >
                  +64-220211997
                </a>
                <a
                  style={{ textDecoration: "none", color: "#FFFFFFB6" }}
                  href="tel:0800 555 988"
                  className="header__top__help"
                >
                  0800 555 988
                </a>
              </p>
              <p className="office__info">
                Email{" "}
                <p>
                  {" "}
                  General Inquiries:{" "}
                  <Link
                    to="#"
                    className="footer-mail-link"
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href =
                        "mailTo: christchurchcarrentals@gmail.com";
                    }}
                  >
                    christchurchcarrentals@gmail.com
                  </Link>
                  <br />
                  Reservations:{" "}
                  <Link
                    to="#"
                    className="footer-mail-link"
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.href =
                        "mailTo: christchurchcarrentals@gmail.com";
                    }}
                  >
                    christchurchcarrentals@gmail.com
                  </Link>
                </p>
              </p>

              <p className="office__info">Office Time: 9am - 5:30pm</p>
            </div>
          </Col>

          {/* <Col lg="3" md="4" sm="12">
            <div className="mb-4">
              <h5 className="footer__link-title">Newsletter</h5>
              <p className="section__description">Subscribe our newsletter</p>
              <div className="newsletter">
                <input type="email" placeholder="Email" />
                <span>
                  <i class="ri-send-plane-line"></i>
                </span>
              </div>
            </div>
          </Col> */}

          <Col lg="12">
            <div className="footer__bottom">
              <p className="section__description d-flex align-items-center justify-content-center gap-1 pt-4">
                <AiOutlineCopyrightCircle /> Copyright {year},
                <a
                  rel="noopener noreferrer"
                  href="https://quantumitinnovation.com/"
                  className="footer-company-link"
                  target="_blank"
                >
                  Quantum IT Innovation.
                </a>
                All rights reserved.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
