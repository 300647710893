import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  availableCars: [],
  unAvailableCars: [],
  availableCarsSearch: false,
  loadingAvailableCars: false,
  availableCarsErr: "",
};

const availableCarsSlice = createSlice({
  name: "availableCars",
  initialState,
  reducers: {
    availableCarsStart: (state, action) => {
      state.loadingAvailableCars = true;
      state.availableCarsErr = "";
    },
    availableCarsSuccess: (state, action) => {
      state.availableCarsErr = "";
      state.loadingAvailableCars = false;
      state.availableCars = action.payload.availableCars;
      state.unAvailableCars = action.payload.unAvailableCar;
    },
    availableCarsSearch: (state, action) => {
      state.availableCarsSearch = action.payload;
    },
    availableCarsFailure: (state, action) => {
      state.loadingAvailableCars = false;
      state.availableCarsErr = action.payload;
    },
    setAvailableCars: (state, action) => {
      state.availableCars = [];
      state.unAvailableCars = [];
      state.availableCarsSearch = false;
      state.loadingAvailableCars = false;
      state.availableCarsErr = "";
    },
  },
});

export const {
  availableCarsStart,
  availableCarsFailure,
  availableCarsSuccess,
  availableCarsSearch,
  setAvailableCars,
} = availableCarsSlice.actions;
export default availableCarsSlice.reducer;
