import React from "react";
import { Container, Row, Col } from "reactstrap";
import "../../styles/about-section.css";
import aboutImg from "../../assets/all-images/cars-img/bmw-offer.png";

const AboutSection = ({ aboutClass, forHome }) => {
  return (
    <section
      className="about__section"
      style={
        aboutClass === "aboutPage" ? { marginTop: "0px" } : { marginTop: "0px" }
      }
    >
      <Container>
        <Row className="align-items-center">
          <Col lg="6" md="6">
            <div
              className={
                forHome
                  ? "about__section-content about__section-content-home"
                  : "about__section-content"
              }
            >
              {/* <h4 className="section__subtitle">About Us</h4> */}
              <h2 className="section__title">
                Welcome to Christchurch Car Rental
                <br />
                <span style={{ fontSize: "1.2rem" }}>
                  Your Premier Choice for Car Rentals in the Heart of Christchurch City!
                </span>
              </h2>
              <p className="section__description">
                At Christchurch Car Rental, we understand that convenience,
                reliability, and exceptional service are crucial when it comes
                to renting a car. Whether you're a visitor exploring the vibrant
                city of Christchurch or a local in need of a temporary set
                of wheels, we've got you covered. Our car rental services are
                designed to meet your diverse needs and make your journey as
                smooth as possible.
              </p>

              <p className="section__description">
                Are you planning a visit to Christchurch, New Zealand, and
                need reliable transportation to explore the city and its
                picturesque surroundings? Look no further! Car Rental Christchurch
                is your trusted partner for seamless and comfortable car
                hire services in this vibrant city. With a commitment to
                providing top-notch vehicles and exceptional customer service,
                we are your go-to choice for exploring the beautiful region of
                Christchurch.
              </p>

              {/* <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Lorem ipsum dolor sit
                  amet.
                </p>

                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Lorem ipsum dolor sit
                  amet.
                </p>
              </div>

              <div className="about__section-item d-flex align-items-center">
                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Lorem ipsum dolor sit
                  amet.
                </p>

                <p className="section__description d-flex align-items-center gap-2">
                  <i class="ri-checkbox-circle-line"></i> Lorem ipsum dolor sit
                  amet.
                </p>
              </div> */}
            </div>
          </Col>

          <Col lg="6" md="6">
            <div className="about__img">
              <img src="https://maulesh-car-rental.s3.amazonaws.com/uploads/1699940805480-5.jpeg" alt="" className="w-100" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutSection;
