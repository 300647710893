import React from "react";
import { Button, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import "../../styles/car-item.css";
import { AiFillCar, AiFillSetting } from "react-icons/ai";
import { IoSpeedometerSharp } from "react-icons/io5";
import { useSelector } from "react-redux";

const CarItem = (props) => {
  const { images, model, name, automatic, speed, price, _id } = props.item;
  const search = props.search;
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);

  return (
    <Col lg="6" md="6" sm="12" className="mb-5">
      <div className="car__item">
        <div className="car__img">
          <img src={images[0]} alt="" className="w-100" />
        </div>

        <div className="car__item-content mt-4">
          <h5 className="section__title text-center">{name}</h5>
          {/* <h6 className="rent__price text-center mt-2">
            <span className="car-price">${price?.toFixed(2)}</span>
            <span>/day</span>
          </h6> */}
          {search ? (
            <p style={{ textAlign: "center" }}>
              {localStorage.getItem("journeyDays") || 0} Days @ ${price} (per
              day){" "}
              <strong>
                ${parseInt(localStorage.getItem("journeyDays") || 0) * price}
              </strong>
              <br />
              excluding of insurance, additional-charges and discount.
            </p>
          ) : (
            <h6 className="rent__price text-center mt-2">
              <span className="car-price">${price?.toFixed(2)}</span>
              <span>/day</span>
            </h6>
          )}
          <div className="car__item-info d-flex align-items-center justify-content-between mt-3">
            <div className="car-item-btm">
              <p>
                <AiFillCar style={{ marginRight: "0.5rem" }} />
              </p>
              {/* <i class="ri-car-line" >
              </i> */}
              <p>{model}</p>
            </div>
            <div className="car-item-btm">
              {/* <i class="ri-settings-2-line" style={{ marginRight: "0.5rem" }}>
              </i> */}
              <p>
                <AiFillSetting style={{ marginRight: "0.5rem" }} />
              </p>
              <p>{automatic}</p>
            </div>
            <div className="car-item-btm">
              {/* <i class="ri-timer-flash-line" style={{ marginRight: "0.5rem" }}>
              </i> */}
              <p>
                <IoSpeedometerSharp style={{ marginRight: "0.5rem" }} />
              </p>
              <p>{speed}</p>
            </div>
          </div>
          <div className="car-btn-cont">
            <Button
              disabled={props?.unAvailable}
              onClick={() => {
                // if (token) {
                props?.fromHome
                  ? navigate(`/cars/${_id}`, { state: { fromHome: true } })
                  : navigate(`/cars/${_id}`);
                // } else {
                //   navigate(`/sign-in`);
                // }
              }}
              className=" w-50 car__item-btn-1 car__btn-rent"
            >
              <>Book Now</>
            </Button>

            <Button
              disabled={props?.unAvailable}
              onClick={() => {
                if (token) {
                  props?.fromHome
                    ? navigate(`/cars/${_id}`, { state: { fromHome: true } })
                    : navigate(`/cars/${_id}`);
                } else {
                  navigate(`/cars/${_id}`);
                  // navigate(`/sign-in`);
                }
              }}
              className=" w-50 car__item-btn-2"
            >
              <>Details</>
            </Button>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default CarItem;
