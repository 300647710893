import React, { useEffect, useState } from "react";
import ReactPlaceholder from "react-placeholder";

import CommonSection from "../components/UI/CommonSection";
// import driveImg from "../assets/all-images/drive.jpg";
import airport_rental1 from "../assets/all-images/airport_rental1.jpg";
// import Helmet from "../components/Helmet/Helmet";
import { motion } from "framer-motion";
import { LiaExternalLinkAltSolid } from "react-icons/lia";
import { TbTargetArrow } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import HelmetComponent from "../components/Helmet/HelmetComponent";
import CarItem from "../components/UI/CarItem";
import FaqAirport from "../components/UI/FaqAirportRental";
import {
  allCarsFailure,
  allCarsStart,
  allCarsSuccess,
} from "../components/features/allCarsSlice";
import "../styles/about.css";
import axiosInstance from "../utils/axiosUtil";

const AirportCarRental = () => {
  const { allCars, loadingAllCars, allCarsErr } = useSelector(
    (state) => state.allCars
  );
  const [width, setWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();

  const fetchAllCars = async () => {
    dispatch(allCarsStart());
    try {
      const { data } = await axiosInstance.get(`/api/car/get-car?sort=low`);

      dispatch(allCarsSuccess(data.cars));
    } catch (error) {
      dispatch(allCarsFailure(error?.response?.data?.message));
    }
  };

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

  useEffect(() => {
    fetchAllCars();
  }, []);

  return (
    <>
      <HelmetComponent
        title={"Christchurch Airport Car Rental | Hire Now"}
        metaDesc={
          "Hire car at Christchurch Airport & get Quick, Easy, and Affordable Rental Car. | Hire Now | Christchurch Car Rental"
        }
      />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <CommonSection title="Airport Car Rental Christchurch" />

        <section className="" style={{}}>
          <Container>
            <Row className="justify-content-between">
              <Col lg="6" md="6">
                <div>
                  {/* <h4 className="section__subtitle">About Us</h4> */}
                  <h2 className="section__title">
                    Christchurch Airport Car Rental
                    <br />
                  </h2>
                  <p className="section__description">
                    Are you landing at Christchurch Airport and in need of
                    reliable transportation for your New Zealand journey? Look
                    no further than Christchurch Airport Car Rental. With
                    our extensive range of rental cars and a commitment to
                    top-notch service, we're your trusted partner for a seamless
                    and comfortable travel experience in and around Christchurch
                    North.
                  </p>
                </div>
              </Col>

              <Col lg="4" md="6">
                <div className=" aiport_rental_image">
                  <img src={airport_rental1} alt="" className="" />
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section style={{ backgroundColor: "#fff" }}>
          <Container>
            <Row className="justify-content-between align-items-center">
              {/* {width <= 576 && (
                <Col lg="4" md="6">
                  <div className="about__img aiport_rental_image">
                    <img src={blog3} alt="" className="w-100" />
                  </div>
                </Col>
              )} */}

              <Col lg={"auto"} md="6" className="">
                {/* <h6 className="section__subtitle">  </h6> */}
                <h2
                  className="section__title"
                  style={{ color: "#000", fontWeight: 700 }}
                >
                  Convenient Airport Access
                </h2>

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 700,
                  }}
                >
                  <TbTargetArrow /> Your Journey Begins at the Airport
                </h6>

                <p style={{ color: "#868686" }}>
                  Christchurch Airport Car Rental is conveniently located at
                  Christchurch Airport, ensuring that your car rental
                  experience begins the moment you step off the plane. Say
                  goodbye to time-consuming transfers or waiting for shuttle
                  services – with us, your rental car is just steps away from
                  the terminal.
                </p>
              </Col>

              <Col lg={"auto"} md="6" className="mt-lg-5">
                <h2
                  className="section__title"
                  style={{ color: "#000", fontWeight: 700 }}
                >
                  Wide Variety of Rental Cars
                </h2>
                {/* <h6 className="section__subtitle">  </h6> */}

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 700,
                  }}
                >
                  <TbTargetArrow /> Hire Your Ideal Vehicle
                </h6>

                <p style={{ color: "#868686" }}>
                  We understand that each traveler has unique needs. That's why
                  we offer a diverse fleet of well-maintained vehicles to cater
                  to your preferences. Whether you're traveling solo, with
                  family, or on business, we have the perfect rental car for
                  you. From compact cars for city exploration to spacious SUVs
                  for off-road adventures, we've got it all.
                </p>
              </Col>
            </Row>

            <Row className="mt-lg-5 mt-md-3 justify-content-between align-items-center">
              <Col lg={"auto"} md="6">
                {/* <h6 className="section__subtitle">  </h6> */}
                <h2
                  className="section__title"
                  style={{ color: "#000", fontWeight: 700 }}
                >
                  Why Choose Us
                </h2>

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 700,
                  }}
                >
                  <TbTargetArrow /> Unparalleled Convenience
                </h6>
                <p style={{ color: "#868686" }}>
                  Our location at Christchurch Airport means you can pick up
                  your rental car as soon as you arrive, saving you valuable
                  time and ensuring a smooth start to your journey. No more
                  waiting for shuttles or navigating unfamiliar public
                  transportation – with us, your car is ready when you are.
                </p>
              </Col>

              <Col lg={"auto"} md="6">
                {/* <h6 className="section__subtitle">  </h6> */}

                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 700,
                    marginTop: "2rem",
                  }}
                >
                  <TbTargetArrow /> Competitive Pricing
                </h6>

                <p style={{ color: "#868686" }}>
                  We believe in providing value for your money. Our competitive
                  rates ensure that you can enjoy the convenience of airport car
                  rental without breaking the bank. We offer flexible rental
                  options to suit your budget, whether you need a vehicle for a
                  day, a week, or longer.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section style={{ padding: "25px 0px" }}>
          <Container>
            <Row className="justify-content-center">
              <Col lg="12" className="text-center mb-3">
                <h6
                  className="section__subtitle"
                  style={{ color: "#868686", fontWeight: 700 }}
                >
                  Come with
                </h6>
                <h2 className="section__title">Hot Offers</h2>
              </Col>

              {/* <Col  className="text-center mb-5"> */}
              {/* <div className="align-items-end justify-content-end"> */}
              <Link to="/cars" className="home-view-more mb-3">
                View more
              </Link>
              {/* </div> */}
              {/* </Col> */}

              <ReactPlaceholder
                type="text"
                color="#F0F0F0"
                showLoadingAnimation
                rows={10}
                delay={200}
                ready={!loadingAllCars}
              >
                <Row className="row-car-list">
                  {allCars?.slice(0, 6)?.map((item) => (
                    <CarItem item={item} key={item._id} />
                  ))}
                </Row>
              </ReactPlaceholder>
            </Row>
          </Container>
        </section>

        <section>
          <Container>
            <Row className="justify-content-center">
              <Col lg="12" className="text-center mb-3">
                <h2 className="section__title">
                  Frequently Asked Questions (FAQ)
                </h2>
              </Col>

              {/* <Col  className="text-center mb-5"> */}
              {/* <div className="align-items-end justify-content-end"> */}
              <Link to="/faq" className="home-view-more mb-3">
                View more
              </Link>
              {/* </div> */}
              {/* </Col> */}

              <FaqAirport />
            </Row>
          </Container>
        </section>

        <section>
          <Container>
            <Row className="">
              <Col lg="12" className="mb-5 ">
                {/* <h6 className="section__subtitle">  </h6> */}

                <h2
                  className="section__title"
                  style={{ color: "#000", fontWeight: 700 }}
                >
                  Your Travel Adventure Awaits
                </h2>

                <p style={{ color: "#868686" }}>
                  Experience the ease and convenience of Christchurch
                  Airport Car Rental. With our prime airport location, diverse
                  rental options, competitive pricing, and unwavering commitment
                  to customer satisfaction, we ensure that your journey in
                  Christchurch starts on the right foot. Begin your
                  adventure by booking your rental car today at{" "}
                  <a
                    href="https://www.carrentalchristchurch.co.nz"
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.carrentalchristchurch.co.nz{" "}
                    <LiaExternalLinkAltSolid
                      style={{ marginLeft: "0.2rem", marginBottom: "0.2rem" }}
                    />
                  </a>
                  . Discover Christchurch and beyond at your own pace.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </motion.div>
    </>
  );
};

export default AirportCarRental;
