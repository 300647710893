import React from "react";
import { Col } from "reactstrap";
import "../../styles/services-list.css";
import { GrMapLocation } from "react-icons/gr";
import {
  MdCarRental,
  MdFlightTakeoff,
  MdMoreTime,
  MdOutlineComputer,
} from "react-icons/md";
import { FaBusinessTime } from "react-icons/fa";
import { RiCustomerService2Line } from "react-icons/ri";
import { GiGlassCelebration } from "react-icons/gi";

const serviceData = [
  {
    id: 1,
    title: "City Transfers - Pick Up  and drop Off",
    icon: <GrMapLocation />,
    desc: "Navigating the city is easy with our city transfer services. Whether you're heading to a business meeting or exploring local attractions, our comfortable vehicles and knowledgeable drivers will get you there efficiently.",
  },
  {
    id: 2,
    title: "Daily Rentals",
    icon: <MdCarRental />,
    desc: "Our daily rental service is perfect for those who need a car for a day or more. Whether you're touring the city or running errands, we offer a variety of vehicles to suit your preferences and requirements.",
  },
  {
    id: 3,
    title: "Long-Term Rentals",
    icon: <MdMoreTime />,
    desc: "If you need a vehicle for an extended period, our long-term rental options provide flexibility and cost savings. Enjoy the convenience of having a car without the commitment of ownership.",
  },
  {
    id: 4,
    title: "Airport Pickup And Drop off Your Rental Car",
    icon: <MdFlightTakeoff />,
    desc: "We offer convenient airport transfer services to and from Christchurch Airport. Our professional drivers will ensure a smooth and punctual transition to or from your flight.",
  },
  {
    id: 5,
    title: "24/7 Customer Support",
    icon: <RiCustomerService2Line />,
    desc: "Our commitment to excellent customer service means you can reach our support team anytime you need assistance or have questions about your rental.",
  },
  {
    id: 6,
    title: "Online Booking",
    icon: <MdOutlineComputer />,
    desc: "We offer a user-friendly online booking platform, allowing you to reserve your rental vehicle with ease. You can also modify or cancel your reservation online.",
  },
  {
    id: 7,
    title: "Special Occasions",
    icon: <GiGlassCelebration />,
    desc: "Make your special occasions even more memorable with our special occasion rental service. Whether it's a wedding, anniversary, or any significant event, we have the perfect vehicle to complement your celebration.",
  },
  {
    id: 8,
    title: "Business Rentals",
    icon: <FaBusinessTime />,
    desc: "We understand the unique needs of business travelers. Our business rental service offers tailored solutions, including corporate rates and customized billing options.",
  },
];

const ServicesList = () => {
  return (
    <>
      {serviceData?.map((item) => (
        <ServiceItem item={item} key={item?.id} />
      ))}
    </>
  );
};

const ServiceItem = ({ item }) => (
  <Col lg="4" md="4" sm="6" className="mb-3">
    <div className="service__item">
      <div className="d-flex align-items-center">
        <span
          className="mb-3 d-inline-block"
          style={{ fontSize: "2rem", marginRight: "0.5rem" }}
        >
          {item?.icon}
        </span>

        <h6>{item?.title}</h6>
      </div>
      <p className="section__description">{item?.desc}</p>
    </div>
  </Col>
);

export default ServicesList;
