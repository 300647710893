import React from "react";

import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";

import { Container } from "reactstrap";
import "../../styles/hero-slider.css";

const HeroSlider = ({ className }) => {
  const navigate = useNavigate();

  const settings = {
    fade: true,
    speed: 2000,
    autoplaySpeed: 3000,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: false,
  };
  return (
    <Slider {...settings} className={`hero__slider ${className}`}>
      <Link to="/cars" className="slider__item slider__item-01 mt0">
        <Container className="w-100 h-100">
          {/* <div className="slider__content ">
            <h4 className="text-light mb-3">For Rent $70 Per Day</h4>
            <h1 className="text-light mb-4">Reserve Now</h1>

            <Button
              onClick={() => navigate("/cars")}
              className="btn reserve__btn mt-4"
            >
              Reserve Now
            </Button> */}
          {/* <Link to="/cars">Reserve Now</Link> */}
        </Container>
      </Link>

      <Link to="/cars" className="slider__item slider__item-02 mt0">
        <Container className="w-100 h-100">
          {/* <Container>
          <Link className="slider__content ">
            <h4 className="text-light mb-3">For Rent $50 Per Day</h4>
            <h1 className="text-light mb-4">Reserve Now </h1>

            {/* <button className="btn reserve__btn mt-4">
              <Link to="/cars">Reserve Now</Link>
            </button> */}
          {/* <Button
              onClick={() => navigate("/cars")}
              className="btn reserve__btn mt-4"
            >
              Reserve Now
            </Button>
          </div> */}
        </Container>
      </Link>

      <div className="slider__item slider__item-03 mt0">
        <Container>
          {/* <div className="slider__content ">
            <h4 className="text-light mb-3">For Rent $80 Per Day</h4>
            <h1 className="text-light mb-4">Reserve Now </h1>

            <Button
              onClick={() => navigate("/cars")}
              className="btn reserve__btn mt-4"
            >
              Reserve Now
            </Button>
          </div> */}
        </Container>
      </div>
    </Slider>
  );
};

export default HeroSlider;
