import React, { useEffect } from "react";
import ReactPlaceholder from "react-placeholder";

import HeroSlider from "../components/UI/HeroSlider";
import Helmet from "../components/Helmet/HelmetComponent";

import { Container, Row, Col } from "reactstrap";
import FindCarForm from "../components/UI/FindCarForm";
import AboutSection from "../components/UI/AboutSection";
import ServicesList from "../components/UI/ServicesList";
import CarItem from "../components/UI/CarItem";
import "../styles/add.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  allCarsFailure,
  allCarsStart,
  allCarsSuccess,
} from "../components/features/allCarsSlice";
import axiosInstance from "../utils/axiosUtil";
import AlertLayout from "../components/UI/AlertLayout";
import { motion } from "framer-motion";
import FaqHome from "../components/UI/FaqHome";

const Home = () => {
  const {
    availableCars,
    unAvailableCars,
    loadingAvailableCars,
    availableCarsErr,
    availableCarsSearch,
  } = useSelector((state) => state.availableCars);
  const { allCars, loadingAllCars, allCarsErr } = useSelector(
    (state) => state.allCars
  );
  const dispatch = useDispatch();

  const fetchAllCars = async () => {
    dispatch(allCarsStart());
    try {
      const { data } = await axiosInstance.get(`/api/car/get-car?sort=low`);

      dispatch(allCarsSuccess(data.cars));
    } catch (error) {
      dispatch(allCarsFailure(error?.response?.data?.message));
    }
  };

  useEffect(() => {
    fetchAllCars();
  }, []);

  // useEffect(() => {
  //   console.log("home", cars);
  //   console.log(loading);
  // }, [cars, loading]);

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <Helmet
          title="Christchurch Car Rental 🚗 | Hire | Easy Bookings 🚘"
          metaDesc={
            "Hire the Best Rental Car in Christchurch and get best deals! Book now for great prices and easy car hire. 🌟 | Christchurch Car Rental"
          }
        />
        {/* ============= hero section =========== */}
        <section className="p-0 hero__slider-section">
          <HeroSlider className="" />

          <div className="hero__form">
            <Container>
              <Row className="form__row">
                <Col lg="4" md="4">
                  <div className="find__cars-left">
                    <h2>Find your best car here</h2>
                  </div>
                </Col>

                <Col lg="8" md="8" sm="12">
                  {/* this is car form */}

                  <FindCarForm
                  // setCars={setCars}
                  // setLoading={setLoading}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        {/* available car section */}
        {availableCarsSearch && (
          <section>
            <Container>
              <ReactPlaceholder
                type="text"
                color="#F0F0F0"
                showLoadingAnimation
                rows={10}
                delay={200}
                ready={!loadingAvailableCars}
              >
                <div className="imp-prp">
                  <Row className="justify-content-center">
                    {availableCarsSearch && availableCars?.length === 0 ? (
                      <AlertLayout
                        title={"No cars available!"}
                        body={
                          <>
                            <p>
                              Please select different date and time to see
                              available cars.
                            </p>
                          </>
                        }
                        navLink={"/cars"}
                        btnTitle={"Cars"}
                      />
                    ) : (
                      <>
                        {availableCarsSearch && (
                          <Row className="row-car-list justify-content-center">
                            {availableCars?.length > 0 && (
                              <div className="availableCars-title">
                                <h1>Available cars</h1>
                              </div>
                            )}
                            {availableCars?.map((item) => {
                              return (
                                <CarItem
                                  fromHome={true}
                                  item={item}
                                  search={true}
                                  key={item._id}
                                />
                              );
                            })}
                          </Row>
                        )}
                        {availableCarsSearch && (
                          <Row className="row-car-list mt-2 mb-0">
                            {unAvailableCars?.length > 0 && (
                              <div className="availableCars-title">
                                <h1>Un-available cars</h1>
                              </div>
                            )}
                            {unAvailableCars?.map((item) => (
                              <CarItem
                                unAvailable={true}
                                item={item}
                                key={item._id}
                              />
                            ))}
                          </Row>
                        )}
                      </>
                    )}
                  </Row>
                </div>
              </ReactPlaceholder>
            </Container>
          </section>
        )}

        {/* =========== about section ================ */}
        <AboutSection forHome={true} />
        {/* =========== car offer section ============= */}
        <section>
          <Container>
            <Row className="justify-content-center">
              <Col lg="12" className="text-center mb-3">
                <h6
                  className="section__subtitle"
                  style={{ color: "#868686", fontWeight: 700 }}
                >
                  Come with
                </h6>
                <h2 className="section__title">Hot Offers</h2>
              </Col>

              {/* <Col  className="text-center mb-5"> */}
              {/* <div className="align-items-end justify-content-end"> */}
              <Link to="/cars" className="home-view-more mb-3">
                View more
              </Link>
              {/* </div> */}
              {/* </Col> */}

              <ReactPlaceholder
                type="text"
                color="#F0F0F0"
                showLoadingAnimation
                rows={10}
                delay={200}
                ready={!loadingAllCars}
              >
                <Row className="row-car-list">
                  {allCars?.slice(0, 6)?.map((item) => (
                    <CarItem item={item} key={item._id} />
                  ))}
                </Row>
              </ReactPlaceholder>
            </Row>
          </Container>
        </section>

        <section style={{ padding: "25px 0px" }}>
          <Container>
            <Row>
              <Col lg="12" className="">
                <h2 className="section__title">
                  Explore Christchurch with Ease
                </h2>
                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 700,
                    marginTop: "2rem",
                  }}
                >
                  Book a rental car easily
                </h6>
                <div className="popular-services-txt">
                  <p>
                    At Car Rental Christchurch, we understand the importance of
                    convenience when it comes to car hire. Our online booking
                    platform, available at www.carrentalchristchurch.co.nz,
                    allows you to book your preferred vehicle quickly and
                    hassle-free. Whether you need to hire a car for a solo
                    adventure or a spacious SUV for a family trip, our
                    user-friendly website ensures a seamless booking process.
                  </p>
                </div>
              </Col>

              <Col lg="12" className="my-2">
                <h6
                  className="section__subtitle"
                  style={{ color: "#868686", fontWeight: 700 }}
                >
                  Wide Range of Vehicles
                </h6>
                <div className="popular-services-txt">
                  <p>
                    We take pride in offering a diverse fleet of well-maintained
                    vehicles to cater to your specific needs. From
                    fuel-efficient economy cars to luxurious sedans and rugged
                    4x4s, our selection ensures that you'll find the perfect
                    vehicle to match your preferences and budget.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section style={{ padding: "25px 0px" }}>
          <Container>
            <Row>
              <Col lg="12" className="">
                <h2 className="section__title">Why Choose Us</h2>
                <h6
                  className="section__subtitle"
                  style={{
                    color: "#868686",
                    fontWeight: 700,
                    marginTop: "2rem",
                  }}
                >
                  Exceptional Customer Service
                </h6>
                <div className="popular-services-txt">
                  <p>
                    Our commitment to customer satisfaction sets us apart from
                    the competition. Our friendly and knowledgeable team is
                    dedicated to providing personalized assistance, from helping
                    you choose the right car to answering any questions you may
                    have. Your comfort and satisfaction are our top priorities.
                  </p>
                </div>
              </Col>

              <Col lg="12" className="my-2">
                <h6
                  className="section__subtitle"
                  style={{ color: "#868686", fontWeight: 700 }}
                >
                  Competitive Rates
                </h6>
                <div className="popular-services-txt">
                  <p>
                    We believe that quality car rental services shouldn't break
                    the bank. That's why we offer competitive rental rates
                    without compromising on the quality of our vehicles or
                    service. With Car Rental Christchurch, you can enjoy
                    affordability without sacrificing comfort and reliability.
                  </p>
                </div>
              </Col>

              <Col lg="12" className="my-2">
                <h6
                  className="section__subtitle"
                  style={{ color: "#868686", fontWeight: 700 }}
                >
                  Convenient Locations
                </h6>
                <div className="popular-services-txt">
                  <p>
                    Convenience is key, and we have strategically located
                    pick-up and drop-off points throughout Christchurch. Whether
                    you're arriving at the airport or need a car in the city
                    center, we have you covered. Our multiple locations make it
                    easy to access your rental vehicle, saving you time and
                    effort.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* ========== services section ============ */}
        <section style={{ padding: "25px 0px" }}>
          <Container>
            <Row>
              <Col lg="12" className="mb-5 text-center">
                <h6
                  className="section__subtitle"
                  style={{ color: "#868686", fontWeight: 700 }}
                >
                  See our
                </h6>
                <h2 className="section__title">Popular Services</h2>
              </Col>

              <div className="popular-services-txt">
                <p>
                  At Christchurch Car Rental, we take pride in offering a wide
                  range of services to meet your diverse transportation needs.
                  Whether you're a visitor exploring Christchurch or a local in
                  need of reliable transportation, our popular services are
                  designed to make your journey comfortable and convenient.
                  Here's an overview of our most sought-after services.
                </p>
              </div>

              <ServicesList />
            </Row>
          </Container>
        </section>

        <section>
          <Container>
            <p>
              At <b>Car Rental Christchurch</b>, we are committed to making your
              journey across New Zealand’s stunning South Island smooth and
              enjoyable. Whether you're landing at <b>Christchurch Airport</b>{" "}
              or planning a scenic drive to <b>Queenstown</b>, our extensive
              fleet offers a variety of options to suit your needs. From
              economical vehicles for budget-conscious travelers to{" "}
              <b>luxury car rentals</b> for those who prefer to travel in style,
              we have it all.
            </p>
            <p>
              Convenience is our priority at <b>Christchurch Airport</b>. We
              provide straightforward{" "}
              <b>Christchurch airport rental car returns</b>
              and a seamless pick-up experience, ensuring you can hit the road
              as soon as you arrive. If you need a vehicle on short notice, our
              <b>last minute car rental Christchurch</b> services are designed
              to get you moving without delay.
            </p>
            <p>
              Planning a trip to <b>Queenstown</b>? Our{" "}
              <b>car rental Christchurch to Queenstown</b> options allow you to
              explore breathtaking landscapes at your own pace. We also offer
              budget car rental Christchurch solutions, ensuring you can find an
              affordable vehicle that fits your itinerary.
            </p>
            <p>
              Flying into <b>Christchurch International Airport</b>? Our{" "}
              <b>budget car rental Christchurch airport NZ</b> service
              guarantees that you won’t break the bank while getting around. We
              pride ourselves on providing{" "}
              <b>cheap car rentals Christchurch airport NZ</b>, so you can focus
              on enjoying your trip.
            </p>
          </Container>
        </section>

        <section>
          <Container>
            <Row className="justify-content-center">
              <Col lg="12" className="text-center mb-3">
                <h2 className="section__title">
                  Frequently Asked Questions (FAQ)
                </h2>
              </Col>

              {/* <Col  className="text-center mb-5"> */}
              {/* <div className="align-items-end justify-content-end"> */}
              <Link to="/faq" className="home-view-more mb-3">
                View more
              </Link>
              {/* </div> */}
              {/* </Col> */}

              <FaqHome />
            </Row>
          </Container>
        </section>
        {/* =========== become a driver section ============ */}
        {/* <BecomeDriverSection /> */}

        {/* =========== testimonial section =========== */}
        {/* <section>
            <Container>
              <Row>
                <Col lg="12" className="mb-4 text-center">
                  <h6 className="section__subtitle">Our clients says</h6>
                  <h2 className="section__title">Testimonials</h2>
                </Col>

                <Testimonial />
              </Row>
            </Container>
          </section> */}

        {/* =============== blog section =========== */}
        {/* <section>
            <Container>
              <Row>
                <Col id="findCar" lg="12" className="mb-5 text-center">
                  <h6 className="section__subtitle">Explore our blogs</h6>
                  <h2 className="section__title">Latest Blogs</h2>
                </Col>

                <BlogList />
              </Row>
            </Container>
          </section> */}
      </motion.div>
    </>
  );
};

export default Home;
