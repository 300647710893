import React, { useEffect, useState } from "react";
import ReactPlaceholder from "react-placeholder";
import { ToastContainer, toast } from "react-toastify";
import Helmet from "../components/Helmet/HelmetComponent";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  carBookingHistoryStart,
  carBookingHistorySuccess,
} from "../components/features/bookedCarHistorySlice";
import axiosInstance from "../utils/axiosUtil";
import { Button, Col, Container, Row } from "reactstrap";
import { format } from "date-fns";
import { FaLocationDot } from "react-icons/fa6";
import { BiCalendar, BiTimeFive } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
import {
  MdAirlineSeatReclineNormal,
  MdCancel,
  MdOutlineLuggage,
} from "react-icons/md";
import { GrUserManager } from "react-icons/gr";
import {
  AiFillCar,
  AiFillCheckCircle,
  AiFillInfoCircle,
  AiFillSetting,
} from "react-icons/ai";
import { IoSpeedometerSharp } from "react-icons/io5";
import ModalLayout from "../components/UI/ModalLayout";
import { motion } from "framer-motion";
import CommonSection from "../components/UI/CommonSection";
import { TbGps } from "react-icons/tb";

const CarBookingDetails = () => {
  const [modal, setModal] = useState(false);
  const [bookingId, setBookingId] = useState("");
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { carBookingHistory, loadingCarBookingHistory, carBookingHistoryErr } =
    useSelector((state) => state.carBookingHistory);

  const toastOptions = {
    position: "bottom-center",
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  const toggle = () => setModal(!modal);

  const fetchDetails = async () => {
    dispatch(carBookingHistoryStart());
    try {
      const { data } = await axiosInstance.get(
        `/api/booking/get-booking/${params?.id}`,
        { headers: { Authorization: token } }
      );
      dispatch(carBookingHistorySuccess(data.booking));
    } catch (error) {
      dispatch(carBookingHistorySuccess(error?.response?.data?.error));
    }
  };
  useEffect(() => {
    fetchDetails();
  }, []);

  const acceptHireAgree = async () => {
    try {
      const { data } = await axiosInstance.post(
        `/api/booking/accept-hireAgree/${bookingId}`,
        {
          hireAgreement: true,
        },
        { headers: { Authorization: token } }
      );

      if (data.booking) {
        toggle();
        await fetchDetails();
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!", toastOptions);
    }
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <Helmet
          title={
            "Christchurch Car Rental - " + carBookingHistory?.car?.name
          }
        />
        <ReactPlaceholder
          type="text"
          color="#F0F0F0"
          showLoadingAnimation
          rows={1}
          delay={200}
          ready={!loadingCarBookingHistory}
        >
          <CommonSection
            title={`Booking Details - ${carBookingHistory?.car?.name}`}
          />
        </ReactPlaceholder>
        <Container className="my-3">
          <div className="booking-history-title mt-2">
            <h3 style={{ fontWeight: 600, color: "#868686" }}>
              Booking details
              {/* <span style={{ fontWeight: 600 }}>
                {format(
                  new Date(carBookingHistory?.createdAt),
                  "MMMM dd, yyyy"
                )}
              </span> */}
            </h3>
            <ReactPlaceholder
              type="text"
              color="#F0F0F0"
              showLoadingAnimation
              rows={1}
              style={{ width: "50%" }}
              delay={200}
              ready={!loadingCarBookingHistory}
            >
              <p>
                <h4 style={{ fontWeight: 600 }}>
                  <span style={{ color: "#868686", marginRight: "0.5rem" }}>
                    Total:
                  </span>
                  ${carBookingHistory?.totalPrice?.toFixed(2)}
                </h4>
              </p>
            </ReactPlaceholder>
          </div>
          <ReactPlaceholder
            type="text"
            color="#F0F0F0"
            showLoadingAnimation
            rows={10}
            delay={200}
            ready={!loadingCarBookingHistory}
          >
            <Row className="justify-content-between mt-3">
              <Col lg="4">
                <img
                  src={carBookingHistory?.car?.image}
                  alt={carBookingHistory?.car?.name}
                  className="w-100"
                />
              </Col>

              <Col lg="6">
                <Row>
                  <div className="car__info">
                    <Col xs={6}>
                      <h2 className="section__title">
                        {carBookingHistory?.car?.name}
                      </h2>
                    </Col>

                    <Col xs={8}>
                      <div className="gap-5 mb-4 mt-3 car-details-priceRating">
                        <h6 className="rent__price fw-bold fs-4">
                          <span className="car-details-price">
                            ${carBookingHistory?.car?.price?.toFixed(2)}
                          </span>
                          <span>/day</span>
                        </h6>

                        {carBookingHistory?.car?.rating && (
                          <div className="align-items-center">
                            <span
                              style={{
                                color: "#7a7a87",
                                marginRight: "0.5rem",
                              }}
                            >
                              <i class="ri-star-s-fill"></i>
                              <i class="ri-star-s-fill"></i>
                              <i class="ri-star-s-fill"></i>
                              <i class="ri-star-s-fill"></i>
                              <i class="ri-star-s-fill"></i>
                            </span>
                            ({carBookingHistory?.car?.rating} ratings)
                          </div>
                        )}
                      </div>
                    </Col>

                    <strong>Description</strong>
                    <p className="section__description">
                      {carBookingHistory?.car?.description}
                    </p>

                    <div
                      className=" d-flex align-items-center mt-3"
                      // style={{ columnGap: "4rem" }}
                    >
                      <Col>
                        <span className=" gap-1 section__description car-details-span">
                          <div className="d-flex align-items-center">
                            <AiFillCar
                              style={{
                                color: "#7a7a87",
                                marginRight: "0.5rem",
                              }}
                            />
                            <strong style={{ color: "#7a7a87" }}>Model</strong>{" "}
                          </div>

                          <strong style={{ color: "#000" }}>
                            {carBookingHistory?.car?.model}
                          </strong>
                        </span>
                      </Col>

                      <Col>
                        <span className="  gap-1 section__description car-details-span">
                          <div className="d-flex align-items-center">
                            <AiFillSetting
                              style={{
                                color: "#7a7a87",
                                marginRight: "0.5rem",
                              }}
                            />
                            <strong style={{ color: "#7a7a87" }}>
                              Automatic
                            </strong>{" "}
                          </div>

                          <strong style={{ color: "#000" }}>
                            {carBookingHistory?.car?.automatic}
                          </strong>
                        </span>
                      </Col>

                      <Col>
                        <span className=" gap-1 section__description car-details-span">
                          <div className="d-flex align-items-center">
                            <IoSpeedometerSharp
                              style={{
                                color: "#7a7a87",
                                marginRight: "0.5rem",
                              }}
                            />
                            <strong style={{ color: "#7a7a87" }}>Speed</strong>{" "}
                          </div>
                          <strong style={{ color: "#000" }}>
                            {carBookingHistory?.car?.speed}
                          </strong>
                        </span>
                      </Col>
                    </div>

                    <div
                      className=" d-flex align-items-center mt-3"
                      // style={{ columnGap: "2.8rem" }}
                    >
                      <Col>
                        <span className=" gap-1 section__description car-details-span">
                          <div className="d-flex align-items-center">
                            <TbGps
                              style={{
                                color: "#7a7a87",
                                marginRight: "0.5rem",
                              }}
                            />
                            <strong style={{ color: "#7a7a87" }}>GPS</strong>
                          </div>
                          <strong style={{ color: "#000" }}>
                            {carBookingHistory?.car?.gps}
                          </strong>
                        </span>
                      </Col>

                      <Col>
                        <span className=" gap-1 section__description car-details-span">
                          <div className="d-flex align-items-center">
                            <MdAirlineSeatReclineNormal
                              style={{
                                color: "#7a7a87",
                                marginRight: "0.5rem",
                              }}
                            />
                            <strong style={{ color: "#7a7a87" }}>
                              No. of seat(s)
                            </strong>
                          </div>
                          <strong style={{ color: "#000" }}>
                            {carBookingHistory?.car?.noOfSeat}
                          </strong>
                        </span>
                      </Col>

                      <Col>
                        <span className="  gap-1 section__description car-details-span">
                          <div className="d-flex align-items-center">
                            <AiFillInfoCircle
                              style={{
                                color: "#7a7a87",
                                marginRight: "0.5rem",
                              }}
                            />
                            <strong style={{ color: "#7a7a87" }}>Brand</strong>
                          </div>
                          <strong style={{ color: "#000" }}>
                            {carBookingHistory?.car?.brand}
                          </strong>
                        </span>
                      </Col>
                    </div>
                  </div>
                </Row>
              </Col>
            </Row>

            <div className="booking-details-root mt-5">
              <Row className="mt-3">
                <Col lg="3" md="6">
                  <div className="order-page-pickup-cont">
                    <p className="order-page-title">Pick-up</p>
                    <div className="d-flex align-items-center">
                      {/* From - {userDetails?.journeyDate} */}
                      <p>
                        <FaLocationDot style={{ marginRight: "0.5rem" }} />
                      </p>
                      <p>{carBookingHistory?.pickupLocation}</p>
                    </div>
                    <div className="d-flex align-items-center">
                      {/* From - {userDetails?.journeyDate} */}
                      <p>
                        <BiCalendar style={{ marginRight: "0.5rem" }} />
                      </p>
                      <p>
                        {carBookingHistory?.startDate &&
                          format(
                            new Date(carBookingHistory?.startDate),
                            "MMMM dd, yyyy"
                          )}
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <p>
                        <BiTimeFive style={{ marginRight: "0.5rem" }} />
                      </p>
                      <p>
                        {carBookingHistory?.startDate &&
                          format(
                            new Date(carBookingHistory?.startDate),
                            "HH:mm"
                          )}
                      </p>
                    </div>
                  </div>
                </Col>

                <Col lg="3" md="6">
                  <div className="order-page-pickup-cont">
                    <p className="order-page-title">Drop-of</p>
                    <div className="d-flex align-items-center">
                      {/* From - {userDetails?.journeyDate} */}
                      <p>
                        <FaLocationDot style={{ marginRight: "0.5rem" }} />
                      </p>
                      <p>{carBookingHistory?.dropofLocation}</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <p>
                        <BiCalendar style={{ marginRight: "0.5rem" }} />
                      </p>
                      <p>
                        {carBookingHistory?.endDate &&
                          format(
                            new Date(carBookingHistory?.endDate),
                            "MMMM dd, yyyy"
                          )}
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <p>
                        <BiTimeFive style={{ marginRight: "0.5rem" }} />
                      </p>
                      <p>
                        {carBookingHistory?.endDate &&
                          format(new Date(carBookingHistory?.endDate), "HH:mm")}
                      </p>
                    </div>
                  </div>
                  {/* <p>To - {userDetails?.journeyEndDate}</p> */}
                </Col>

                <Col lg="3" md="6">
                  <div className="order-page-pickup-cont">
                    <p className="order-page-title">Other details</p>
                    <div className="d-flex align-items-center">
                      <p>
                        <FiUsers style={{ marginRight: "0.5rem" }} />
                      </p>
                      <p>{carBookingHistory?.person}</p>
                    </div>
                    <div className="d-flex align-items-center">
                      <p>
                        <MdOutlineLuggage style={{ marginRight: "0.5rem" }} />
                      </p>
                      <p>{carBookingHistory?.luggage}</p>
                    </div>
                  </div>
                </Col>

                <Col lg="3" md="6">
                  <div className="">
                    <p className="order-page-title">Hire Agreement</p>
                    <div className="d-flex align-items-center">
                      <p>
                        {carBookingHistory?.hireAgreement === "Pending" ? (
                          <div
                            className="booking-history-btnCont"
                            style={{ borderLeft: "none" }}
                          >
                            <Button
                              onClick={() => {
                                toggle();
                                setBookingId(carBookingHistory?._id);
                              }}
                              className="mt-2"
                            >
                              Click here to accept hire agreement
                            </Button>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center">
                            <AiFillCheckCircle
                              color="green"
                              style={{ marginRight: "0.5rem" }}
                            />
                            <div className="">
                              Agreement accepted!
                              <p className="mb-0" disabled>
                                <small>
                                  The agreement has been mailed to you!
                                </small>
                              </p>
                            </div>
                          </div>
                        )}
                      </p>
                    </div>

                    <div className="d-flex align-items-center">
                      {carBookingHistory?.status === "COMPLETED" ? (
                        <div className="d-flex align-items-center">
                          <>
                            <p>
                              <AiFillCheckCircle
                                color="green"
                                style={{ marginRight: "0.5rem" }}
                              />
                              Payment status: {carBookingHistory?.status}
                            </p>
                          </>
                        </div>
                      ) : (
                        <div className="align-items-center">
                          <>
                            <p>
                              <MdCancel
                                color="red"
                                style={{ marginRight: "0.5rem" }}
                              />
                              Payment status: {carBookingHistory?.status}
                            </p>
                          </>
                        </div>
                      )}
                    </div>

                    <div className="d-flex align-items-center">
                      <p>
                        {/* {carBookingHistory?.insurance ? (
                          <AiFillCheckCircle
                            color="green"
                            style={{ marginRight: "0.5rem" }}
                          />
                        ) : (
                          <MdCancel
                            color="red"
                            style={{ marginRight: "0.5rem" }}
                          />
                        )} */}
                        <AiFillCheckCircle
                            color="green"
                            style={{ marginRight: "0.5rem" }}
                          />
                      </p>
                      <p>
                        {carBookingHistory?.insurance ? (
                          <span>
                            Insurance added{" "}
                            <small>(mail will be sent you)</small>
                          </span>
                        ) : (
                          "Insurance included" 
                        )}
                      </p>
                    </div>
                  </div>
                  {/* <p>To - {userDetails?.journeyEndDate}</p> */}
                </Col>
              </Row>
            </div>
          </ReactPlaceholder>
        </Container>
      </motion.div>

      <ToastContainer />
      <ModalLayout
        modal={modal}
        toggle={toggle}
        successTitle={"Accept Hire Agreement"}
        success={acceptHireAgree}
        title={"Accept the hire agreement"}
        body={
          <>
            <div>
              <p>
                You are now just one step away from grabing your key to your
                next adventure
              </p>

              {/* <p>A copy of the agreement will be mailed to you.</p> */}
            </div>
          </>
        }
      />
    </>
  );
};

export default CarBookingDetails;
