import React from "react";
import { Route, Routes } from "react-router-dom";
import Temp from "../components/Temp";
import Register from "../components/signinSingup/Register";
import SignIn from "../components/signinSingup/Signin";
import About from "../pages/About";
import AirportCarRental from "../pages/AirportCarRental";
import Blog from "../pages/Blog";
import BlogDetails from "../pages/BlogDetails";
import BookCar from "../pages/BookCar";
import BookingHistory from "../pages/BookingHistory";
import CarBookingDetails from "../pages/CarBookingDetails";
import CarDetails from "../pages/CarDetails";
import CarListing from "../pages/CarListing";
import Contact from "../pages/Contact";
import FaqPage from "../pages/FaqPage";
import Home from "../pages/Home";
import LuxuryCarRental from "../pages/LuxuryCarRental";
import NotFound from "../pages/NotFound";
import Order from "../pages/Order";
import Profile from "../pages/Profile";
import Road_assistance from "../pages/Road_assistance";
import SaveMoney from "../pages/SaveMoney ";
import UserGuide from "../pages/UserGuide";
import WeddingCarRental from "../pages/WeddingCarRental";
import ProtectedRoute from "../utils/ProtectedRoute";
import BestOptions from "../pages/BestOptions";
import ChooseCarRental from "../pages/ChooseCarRental";
import BestTravelChoice from "../pages/BestTravelChoice";
import ExploreChristchurch from "../pages/ExploreChristchurch";
import CarRentalChristChurch from "../pages/CarRentalChristChurch";
import EnhanceJourney from "../pages/EnhanceJourney";
import AffordableCar from "../pages/AffordableCar";
import TopPlacesInChristchurch from "../pages/TopPlacesInChristchurch";
import CarRentalChristchurchBlog from "../pages/CarRentalChristchurchBlog";

const Routers = () => {
  return (
    <Routes>
      <Route path="/register" element={<Register />} />
      <Route path="/temp" element={<Temp />} />
      <Route path="/AA_Road_Assistance" element={<Road_assistance />} />
      <Route
        path="/What_Are_the_Best_Options_for_Car_Rental_in_Christchurch"
        element={<BestOptions />}
      />
      <Route
        path="/Why_Choose_Car_Rental_in_Christchurch_for_Your_Next_Trip"
        element={<ChooseCarRental />}
      />
      <Route
        path="/What_Makes_Car_Rental_in_Christchurch_Your_Best_Travel_Choice"
        element={<BestTravelChoice />}
      />
      <Route
        path="/Exploring_Christchurch_and_Beyond_Your_Ultimate_Guide_to_Car_Rentals"
        element={<ExploreChristchurch />}
      />
      <Route
        path="/How_Can_Car_Rental_Enhance_Your_Journey"
        element={<EnhanceJourney />}
      />
      <Route
        path="/Looking_for_Affordable_Car_Rentals_at_Christchurch_Airport"
        element={<AffordableCar />}
      />
      <Route
        path="/Top_Places_to_Visit_in_Christchurch_Explore_the_Garden_City"
        element={<TopPlacesInChristchurch />}
      />
      <Route
        path="/Car_Rental_Christchurch"
        element={<CarRentalChristchurchBlog />}
      />
      <Route path="/sign-up" element={<Register />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/success" element={<SignIn />} />
      <Route path="/" element={<Home />} />
      <Route
        path="/carrentalchristchurch"
        element={<CarRentalChristChurch />}
      />
      <Route path="/about" element={<About />} />
      <Route path="/airport" element={<AirportCarRental />} />
      <Route path="/luxury-car" element={<LuxuryCarRental />} />
      <Route path="/wedding" element={<WeddingCarRental />} />
      <Route path="/cars" element={<CarListing />} />
      <Route
        path="/10_Ways_to_Save_Money_on_Car_Rental_in_Christchurch"
        element={<SaveMoney />}
      />
      <Route path="/Blogs" element={<Blog />} />
      <Route
        path="/cars/:id"
        element={
          <>
            <CarDetails />
          </>
        }
      />
      <Route path="/blogs" element={<Blog />} />
      <Route path="/blogs/:slug" element={<BlogDetails />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/contact_us" element={<Contact />} />
      <Route path="/faq" element={<FaqPage />} />
      <Route path="/user-guide" element={<UserGuide />} />
      <Route path="/book-car/:id" element={<BookCar />} />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/booking-history"
        element={
          <ProtectedRoute>
            <BookingHistory />
          </ProtectedRoute>
        }
      />
      <Route
        path="/booking-history/:id"
        element={
          <ProtectedRoute>
            <CarBookingDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/order"
        element={
          // <ProtectedRoute>
          <Order />
          /* </ProtectedRoute> */
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default Routers;
