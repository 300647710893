import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

const faqQuestions = [
  {
    id: 1,
    question: "How can I make a reservation?",
    answer:
      "You can easily reserve a vehicle through our user-friendly online booking system. Alternatively, our friendly customer service team is available to assist you in finding the perfect car for your needs.",
  },
  {
    id: 2,
    question: "What documentation do I need to rent a car?",
    answer:
      "To rent a car, you'll typically need a valid driver's licence and a credit card for payment and security purposes. International visitors may also require an International Driving Permit (IDP).",
  },
  {
    id: 3,
    question: "Can I pick up and drop off the car at different locations?",
    answer:
      "Yes, we offer one-way rentals, allowing you to pick up the car at one location and drop it off at another. Additional fees may apply, so please inquire when booking.",
  },
  {
    id: 4,
    question: "Are there age restrictions for renting a car?",
    answer:
      "Yes, there are age restrictions, and they vary by location. Generally, renters must be at least 21 years old. Young driver surcharges may apply to those under 25.",
  },
  {
    id: 5,
    question: "Do you offer insurance options?",
    answer:
      "Yes, we offer various insurance options to provide peace of mind during your rental. These include collision damage waiver (CDW), liability coverage, and more.",
  },
  {
    id: 6,
    question:
      "Do Christchurch Car Rental's wedding packages include a chauffeur service?",
    answer:
      "Yes, Christchurch Car Rental provides the option for a professional chauffeur service as part of our wedding packages, as we aim to provide the best wedding car hire services in Christchurch.",
  },
  {
    id: 7,
    question: "What is the fuel policy for your rental cars?",
    answer:
      "Our standard fuel policy requires you to return the rental car with the same level of fuel as when you received it. To avoid being charged for refuelling, we suggest that you fill-up the tank before returning.",
  },
  {
    id: 8,
    question: "Can I add additional drivers to my rental agreement?",
    answer:
      "You can include more drivers for your rental by paying an extra fee. All drivers must meet age and documentation requirements.",
  },
  {
    id: 9,
    question: "What happens if I return the rental car late?",
    answer:
      "Returning the rental car late may result in additional charges. We recommend contacting us if you anticipate being late so we can discuss options and any potential fees.",
  },
  {
    id: 10,
    question: "Can I decorate the wedding car to match my wedding theme?",
    answer:
      "Yes, we understand that personalising your wedding car is essential. We allow for some decorations to be added to the vehicle, such as ribbons, flowers, or other non-damaging embellishments.",
  },
];

const formatText = (text) => {
  // Format text starting with a number as bold
  const formattedText = text.replace(/newLine/g, "<br /> <br />");

  return formattedText;
};

const FaqWedding = () => {
  const [open, setOpen] = useState("1");

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };
  return (
    <>
      <Accordion open={open} toggle={toggle}>
        {faqQuestions?.map((faq) => (
          <AccordionItem key={faq?.id}>
            <AccordionHeader targetId={faq?.id}>
              <strong>
                <div className="d-flex align-items-center mt-2 faq-question">
                  <p style={{ marginRight: "1rem" }}>Q</p>
                  <p>{faq?.question}</p>
                </div>
              </strong>
            </AccordionHeader>
            <AccordionBody accordionId={faq?.id}>
              <div className="d-flex mt-2 faq-answer">
                <p style={{ marginRight: "1rem", fontWeight: "bold" }}>A</p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: formatText(faq?.answer),
                  }}
                ></p>
              </div>
            </AccordionBody>
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};

export default FaqWedding;
