import React, { useState } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { IoIosArrowDropdown } from "react-icons/io";
import { MdCancel } from "react-icons/md";
import { FormFeedback, Input, Label } from "reactstrap";

const BookingAgeCheck = ({
  setShowTermsCondi,
  setShowAge,
  setShowForm,
  showAge,
  setUserAge,
  setAgeErr,
  userAge,
  ageErr,
}) => {
  const [dob, setDOB] = useState(new Date());

  const calculateAge = (birthdate) => {
    if (!birthdate) return;

    const currentDate = new Date();
    if (new Date(birthdate) > currentDate) {
      setUserAge(0);
      setAgeErr(true);
      // alert("Invalid Date of Birth");
      return;
    }

    const diffTime = currentDate - new Date(birthdate);
    const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    const age = Math.floor(totalDays / 365.25);
    setUserAge(age);
    if (age < 21) {
      setAgeErr(true);
      return;
    } else {
      setAgeErr(false);
      return;
    }
  };

  return (
    <>
      <div className="terms-condi-booking-age">
        <div
          onClick={() => {
            setShowTermsCondi(false);
            setShowAge(!showAge);
            setShowForm(false);
          }}
          className="terms-condi-booking-upper"
        >
          <p>
            {userAge < 21 ? (
              <MdCancel color="red" style={{ marginRight: "0.5rem" }} />
            ) : (
              <AiFillCheckCircle
                color="green"
                style={{ marginRight: "0.5rem" }}
              />
            )}
            Step 1
          </p>

          <p>
            <IoIosArrowDropdown />
          </p>
        </div>
        {showAge && (
          <div className="terms-condi-booking-ageCont">
            <Label>Please enter your date of birth to confirm.</Label>
            <Input
              type="date"
              style={{ width: "50%" }}
              name="dob"
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              value={dob}
              onChange={(e) => {
                setDOB(e.target.value);
                calculateAge(e.target.value);
                // setUserAge(e.target.value);
              }}
              // valid={userAge >= 21}
              invalid={ageErr}
              // invalid={userAge < 21}
            />
            {userAge && <p className="mt-2">Your age is: {userAge}</p>}

            {userAge < 21 && ageErr && (
              <FormFeedback>
                You must atleast 21 years old to book a car.
              </FormFeedback>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default BookingAgeCheck;
